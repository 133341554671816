import { Box,Typography, useTheme,Button} from "@mui/material";
import { DataGrid,GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { AuthContext } from "../../context/AuthContext";
import React, { useEffect, useState, useContext} from "react";
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import useMediaQuery from "@mui/material/useMediaQuery";
import Tabs from "@mui/material/Tabs"; 
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx2={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const Accaunts = () => {
const theme = useTheme(); 
const colors = tokens(theme.palette.mode);
const navigate = useNavigate()
const [value, setValue] = React.useState(0);
const isNonMobile = useMediaQuery("(min-width:600px)");
const { currentUser,url,logout } = useContext(AuthContext);
  const [requestcharge, accountSet] = useState([]);
  useEffect(() => {
    const fetchaccount = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/accounts/all', { username: currentUser.username }, config);
        accountSet(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };
  fetchaccount();
}, [currentUser.username, currentUser.accessToken]);
 const columns = [
    {
      field: "accountnumber",
      headerName: "Phone Number",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "balance",
      headerName: "Balance ",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "servedkm",
      headerName: "Riding KM ",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "bonus",
      headerName: "Bonus",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
 
    {
      field: "status",
      headerName: "status", filter:true,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "createdAt",
      flex: 1,
    },
    
    {
      field: "updatedAt",
      headerName: "updatedAt",
      flex: 1,
    },
    {
      field: "Edit",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
                colors.greenAccent[600]
            }
            borderRadius="4px"
          >
            <Button type="submit" onClick={ () => {navigate(`/driverdetailtransaction/${id}`)} }>
              <AdminPanelSettingsOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              </Typography>
            </Button>
          </Box>
        );
      },
    },
     ];
     const handleChange = (event, newValue) => {
      setValue(newValue);
       };
  return (
    <Box m="20px">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		      backgroundColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
        >
      <Tab label="Drivers Account List" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
  <TabPanel value={value} index={0} dir={theme.direction}>
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    >
      <DataGrid style={{height: '90%', width: '100%'}} rows={requestcharge} columns={columns}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
    </Box>
    </TabPanel>
    
  </Box>
);

};

export default Accaunts;
