import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Tab from '@mui/material/Tab';
import {Link, useNavigate} from 'react-router-dom';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import { Box,Divider, FormControl, Select,Snackbar,Alert,MenuItem,InputLabel,List,ListItem, ListItemText,Typography, useTheme,Button,TextField, Checkbox, RadioGroup} from "@mui/material";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import { useFormik , Form, Field, Formik, ErrorMessage} from 'formik';
import CircularProgress from '@mui/material/CircularProgress';

import * as Yup from "yup";
import Dialog from '@mui/material/Dialog';
import DialogActions, { dialogActionsClasses } from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from "@mui/material/Modal"; 
import { AuthContext } from '../../context/AuthContext';
import { ArrowLeftIcon } from '@mui/x-date-pickers/icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
}; 
 function SYSTEMSETTING() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  // const { currentUser} = useContext(AuthContext);
  const [data, setData] = useState([]); 
  const [fetch, setDsetFetch] = useState([]);
  const [user, setUser] = useState();
// const url="http://128.140.76.56:3001";
//  const url="http://localhost:3001";
    // const url="http://localhost:3001";
    // const url="https://walifride.com";
    // const url = "https://128.140.76.56:3443";
    const { currentUser,url,logout} = useContext(AuthContext);

  const [openColor, setOpenColor] = React.useState(false); 
  const handleOpenColor = () => setOpenColor(true);
  const handleCloseColor = () => setOpenColor(false);

  const [openGoogle, setOpenGoogle] = React.useState(false); 
  const handleOpenGoogle = () => setOpenGoogle(true);
  const handleCloseGoogle = () => setOpenGoogle(false);

  
  const navigate = useNavigate()
//  //End to Handle system setting Registration
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    //axios.get(url+"/settings/act").then((response) => {


//Commission setting 

const [openCommission, setopenCommission] = React.useState(false); 
const handleOpenCommission = () => setopenCommission(true);
const handleCloseoCommission = () => setopenCommission(false);
const [notification, setNotification] = useState(null);
const [isRedirecting, setIsRedirecting] = useState(false);
const [activecom, setactieCom] = React.useState(false); 
const handleactivecom = () => setactieCom(true);
const handleCloseactivecom = () => setactieCom(false);
const formikCommission = useFormik({
  initialValues: {
    commission:"",
    commissionfor:"",
    amaunt:"",
    description:"",
    refno:"",
    username:currentUser.username,
  }, onSubmit: (values) => {
    // alert("I m commission")
     axios.post(url+'/api/tariffs/commissions', values).then((res) => {
      // setCom(com.concat(res.data));
      // alert(res.data)
      setopenCommission(false);
      setNotification({
        show: true,
        type: 'info',
        message: res.data 
      
    });
      // alert(res.data)
      setIsRedirecting(true);
      const timer = setTimeout(() => {
        window.location.reload();

         }, 2000);
    
      return () => clearTimeout(timer);
    });
  },
  });
  const [com, setCom2]=useState() 
  const [drive, setDriving]=useState() 
  const [tar, setTar]=useState() 


  useEffect(() => {
    const getcom = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/tariffs/com', { username: currentUser.username }, config);
        setCom2(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  getcom();
}, [currentUser.username, currentUser.accessToken]);


 
  useEffect(() => {
    const fetchingdriving = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/tariffs/driving', { username: currentUser.username }, config);
        setDriving(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchingdriving();
}, [currentUser.username, currentUser.accessToken]);

  

  useEffect(() => {
    const gettariff = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/tariffs/gettariff', { username: currentUser.username }, config);
        setTar(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  gettariff();
}, [currentUser.username, currentUser.accessToken]);


  const [set, setRadion] = useState({
    radio:"",
  })

//end Commission setting 


//Tariff setting 
const [tarif, setTariff]=useState()
const [openTarif, setopenTarif] = React.useState(false); 
const handleOpenTarif= () => setopenTarif(true);
const handleCloseTarif = () => setopenTarif(false);
const formikTarif = useFormik({
  initialValues: {
    vehicle_class:"",
    seatnumber:"",
    startfare:"",
    farekm:"",
    radius:"",
    elapsed_time:"",
    commission:"",
    refno:"",
    description:"",
    username:currentUser.username,
  }, onSubmit: (values) => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
  
     axios.post(url+'/api/tariffs/reg', values,config).then((res) => {
     // setTariff(tarif.concat(res.data));
      // alert(res.data)
      setopenTarif(false);
      setNotification({
        show: true,
        type: 'info',
        message: res.data 
      
    });
      // alert(res.data)
      setIsRedirecting(true);
      const timer = setTimeout(() => {
        window.location.reload();

         }, 2000);
    
      return () => clearTimeout(timer);
    });
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
  }
 
  },
  });
//end Commission setting 
// Vehicle setting start
const [vehic, setVehic]=useState()
const [openVehic, setopenVehic] = React.useState(false); 
const handleOpenVehic= () => setopenVehic(true);
const handleCloseVehic = () => setopenVehic(false);
const formikVehic = useFormik({
  initialValues: {
    minmodel:"",
    minbalance:"",
    maxbalance:"",
    maxbonus:"",
    maxdrive:"",
    maxnumberofreject:"",
    punishment:"",
    refno:"",
    description:"",
    username:currentUser.username,
  }, onSubmit: (values) => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
  
     axios.post(url+'/api/tariffs/drivings', values,config).then((res) => {
      // setVehic(vehic.concat(res.data));
      // alert(res.data)
      setopenVehic(false);
      setNotification({
        show: true,
        type: 'info',
        message: res.data 
      
    });
      // alert(res.data)
      setIsRedirecting(true);
      const timer = setTimeout(() => {
        window.location.reload();

         }, 2000);
    
      return () => clearTimeout(timer);
    });
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
  }
   
  
  },
  });


// end Vehicle setting
//start document 
const [docset, setdocset]=useState()
const [opendoc, setopendoc] = React.useState(false); 
const handleOpendoc= () => setopendoc(true);
const handleClosedoc = () => setopendoc(false);
const formikdoc = useFormik({
  initialValues: {
    documentname:"",
    docowner:"",
    refno:"",
    description:"",
    username:currentUser.username,
  }, onSubmit: (values) => {
    // alert("no server connaction avaliable")
    

    axios.post(url+'/api/tariffs/doc', values).then((res) => {
      // setdocset(docset.concat(res.data));
      // alert(res.data)
      setopendoc(false);
      setNotification({
        show: true,
        type: 'success',
        message: res.data 
      
    });
      // alert(res.data)
      setIsRedirecting(true);
      const timer = setTimeout(() => {
        window.location.reload();

         }, 2000);
    
      return () => clearTimeout(timer);
    })
  },
  });


//end document


  const handleChangeIndex = (index) => {
    setValue(index);
  };


  //start Vehicle color
  const [loading, setLoading] = React.useState(false);

  const [col, setcolor] = useState({
    username:currentUser.username,
    colorName:"",
    colorCode:""
  })
  function handlecolorset(e){
    e.preventDefault()
    setLoading(true);
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
  
      // axios.post(url+'/api/vehicles/colors',{psw},config).then((res) => {
        axios.post(url+'/api/tariffs/colors', col,config).then((res) => {

        setOpenColor(false)
      setNotification({
        show: true,
        type: 'info',
        message: res.data
      
    });
    setTimeout(() => {
      setLoading(false);
    });
          setIsRedirecting(true);
          const timer = setTimeout(() => {
            window.location.reload();
  
             }, 1000);
        
          return () => clearTimeout(timer);
    })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
  }
  }
  
  //End vehicle color setting

  //start to Enter google map key


  const [mapkey, setMpaKey] = useState({
    username:currentUser.username,
    googlemapkey:"",
  })
  function handlemapkeyset(e){
    e.preventDefault()
    setLoading(true);
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
     alert(mapkey.googlemapkey);
      // axios.post(url+'/api/vehicles/colors',{psw},config).then((res) => {
        axios.post(url+'/api/tariffs/mapkey', mapkey,config).then((res) => {

        setOpenColor(false)
      setNotification({
        show: true,
        type: 'info',
        message: res.data
      
    });
    setTimeout(() => {
      setLoading(false);
    });
          setIsRedirecting(true);
          const timer = setTimeout(() => {
            window.location.reload();
  
             }, 1000);
        
          return () => clearTimeout(timer);
    })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
  }
  }
  //End google map key

  // start commission column
  const commi = [
    // { field: "id", headerName: "ID" },
    {
      field: "commission",
      headerName: "commission",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "commissionfor",
      headerName: "commissionfor",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "amaunt",
      headerName: "amaunt",
      // type: "number",
      headerAlign: "left",
      align: "left",
    }
    ,
    {
      field: "createdby",
      headerName: "createdby",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "approvedby",
      headerName: "approvedby",
      // type: "number",
      headerAlign: "left",
      align: "left",
    }
    ,
    {
      field: "status",
      headerName: "status",
      // type: "number",
      headerAlign: "left",
      align: "left",
    }
    ,
    {
      field: "description",
      headerName: "description",
      // type: "number",
      headerAlign: "left",
      align: "left",
    }
    ,
    {
      field: "createdAt",
      headerName: "createdAt",
      // type: "number",
      headerAlign: "left",
      align: "left",
    }
    ,
    {
      field: "updatedAt",
      headerName: "updatedAt",
      // type: "number",
      headerAlign: "left",
      align: "left",
    }
  ];
 //Drivings
 const driving = [
  // { field: "id", headerName: "ID" },
  {
    field: "minmodel",
    headerName: "minmodel",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "minbalance",
    headerName: "minbalance",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "maxbalance",
    headerName: "maxbalance",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
  ,
  {
    field: "maxbonus",
    headerName: "maxbonus",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "maxdrive",
    headerName: "maxdrive perday",
    // type: "number",
    headerAlign: "left",
    align: "left",
  } ,
  {
    field: "maxnumberofreject",
    headerName: "maxnumberofreject",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
  ,
  {
    field: "punishment",
    headerName: "punishment",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
  ,
  {
    field: "refno",
    headerName: "refno",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
  ,
  {
    field: "createdby",
    headerName: "createdby",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "approvedby",
    headerName: "approvedby",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "status",
    headerName: "status",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "description",
    headerName: "description",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
  ,
  {
    field: "createdAt",
    headerName: "createdAt",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },{
    field: "updatedAt",
    headerName: "updatedAt",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
];

//Tarif colstarted
const tariffcol = [
  // { field: "id", headerName: "ID" },
  {
    field: "id",
    headerName: "id",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "vehicle_class",
    headerName: "class",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "seatnumber",
    headerName: "seatnumber",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  
  {
    field: "startfare",
    headerName: "startfare",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "farekm",
    headerName: "fare_km",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "elapsed_time",
    headerName: "elapsed",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "radius",
    headerName: "rad_km",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "commission",
    headerName: "commission",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "refno",
    headerName: "ReferanceNo",
    // type: "number",
    headerAlign: "left",
    align: "left",
  } ,
  {
    field: "createdby",
    headerName: "createdby",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
  ,
  {
    field: "approvedby",
    headerName: "approvedby",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
  ,
  {
    field: "status",
    headerName: "status",
    // type: "number",
    headerAlign: "left",
    align: "left",
  }
  ,
  {
    field: "description",
    headerName: "description",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "createdAt",
    headerName: "createdAt",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "updatedAt",
    headerName: "updatedAt",
    // type: "number",
    headerAlign: "left",
    align: "left",
  } 
];
//End Tarifcol



 //Drivings

  //end commission column
  


  return (
    <Box m="20px"
      style={{height: '85%'}}
        >
    <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		  backgroundColor="dark"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		  display="flex"
        >
          {/* <Tab label="Active Rule" {...a11yProps(0)} /> */}
          {/* <Tab label="Create Rule" {...a11yProps(1)} /> */}
          {/* <Tab label="Inactive Rule" {...a11yProps(2)} /> */}
		  <Tab label="Bussiness" {...a11yProps(0)} />
		  
        </Tabs>
      </AppBar>
     	<TabPanel value={value} index={0} dir={theme.direction}>
          {/* About commiesion */}
          {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
     


     <Dialog
        open={openColor}
        onClose={handleCloseColor}
       
      >

      <DialogTitle backgroundColor={colors.grey[400]}>Color Setting Dialog</DialogTitle>
        <DialogContent backgroundColor={colors.grey[400]}>
          <DialogContentText>

          <TextField  size="small"name="colorName"   required  label ="Color Name" 
           sx={{ width: 200, height: 50 ,m: 1}}
           onChange={(e)=> setcolor({...col, colorName: e.target.value})}
                        
    
  />       
          </DialogContentText>

        </DialogContent>
      <DialogActions>
          <Button onClick={handleCloseColor} color='error'variant="contained">Cancel</Button>
          <Button type="submit" color='secondary' onClick={handlecolorset}disabled={loading}
                        // onClick={handleClick}
                        endIcon={loading && <CircularProgress size={24} />}
                        variant="contained">
                          {loading ? 'Loading...' : 'Add Color'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openGoogle}
        onClose={handleCloseGoogle}
       
      >

      <DialogTitle backgroundColor={colors.grey[400]}>Make Key Setting Dialog</DialogTitle>
        <DialogContent backgroundColor={colors.grey[400]}>
          <DialogContentText>

          <TextField  size="small"name="googlemapkey"   required  label ="Google Map Key" 
           sx={{ width: 200, height: 50 ,m: 1}}
           onChange={(e)=> setMpaKey({...mapkey, googlemapkey: e.target.value})}
                        
    
  />       
          </DialogContentText>

        </DialogContent>
      <DialogActions>
          <Button onClick={handleCloseGoogle} color='error'variant="contained">Cancel</Button>
          <Button type="submit" color='secondary' onClick={handlemapkeyset}disabled={loading}
                        // onClick={handleClick}
                        endIcon={loading && <CircularProgress size={24} />}
                        variant="contained">
                          {loading ? 'Loading...' : 'Add Color'}</Button>
        </DialogActions>
      </Dialog>
      
    <Modal
      	open={openCommission} 
				onClose={handleCloseoCommission} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
       <div >
       <Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 2"
        marginRight={50}
        
        sx={style} 
        
      >
     <Formik
          initialValues={{objectname: "", approvedby: "",refno:"", description:""}}
          // validationSchema={validationSchemarole}
          onSubmit={formikCommission.onSubmit}
        >
         <form className='formContainer' onSubmit={formikCommission.handleSubmit} encType='multipart/form-data'>
                   <ListItem  >
                      <TextField  
                        fullWidth
                        label="Comission type:"
                          variant="filled"
                          select
                          style = {{width: 400}}
                          onChange={formikCommission.handleChange}
                          value={formikCommission.values.commission}
                          name="commission"
                          size="small"
                          required
                        >
                          <MenuItem value="Percentage"> Percentage commission</MenuItem>
                          <MenuItem value="Revenue"> Revenue commission</MenuItem>
                          <MenuItem value="Gross">Gross margin commission</MenuItem>
                          <MenuItem value="Tiered"> Tiered commission</MenuItem>
                          <MenuItem value="Flat"> Flat-rate commission</MenuItem>
                         </TextField>
                  </ListItem>
                    <ListItem  >
                      <TextField  
                        fullWidth
                        label="Commission For:"
                          variant="filled"
                          select
                          style = {{width: 400}}
                          onChange={formikCommission.handleChange}
                          value={formikCommission.values.commissionfor}
                          name="commissionfor"
                          size="small"
                          required
                        >
                          <MenuItem value="Agent"> Agent</MenuItem>
                          <MenuItem value="Driver"> Drivers</MenuItem>
                          <MenuItem value="Riders"> Rider</MenuItem>
                          <MenuItem value="Staff"> Staff</MenuItem>
                         </TextField>
              </ListItem><ListItem>
                      <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="number"
                          label="To be paid %"
                          size="small"
                          onChange={formikCommission.handleChange}
                          value={formikCommission.values.amaunt}
                          required
                          name="amaunt"
                          
                          />
                    </ListItem>
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="Referance Code"
                          onChange={formikCommission.handleChange}
                          value={formikCommission.values.refno}
                          required
                          name="refno"
                         
                          >
                      </TextField>
                      </ListItem>
                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Description"
                          onChange={formikCommission.handleChange}
                          value={formikCommission.values.description}
                          required
                          size="small"
                          name="description"
                          ></TextField>
                      </ListItem>
            
            
          
              <Button type='submit' color="secondary"  variant="contained"  onClick={formikCommission.onSubmit}>
                Apply</Button>
              &nbsp;&nbsp;&nbsp; 
                  
              <Button type="submit" color="error" onClick={handleCloseoCommission}variant="contained">
                            Close
              </Button> &nbsp;&nbsp;&nbsp;
          
            {/* <button color="warning"onClick={handleClosereg} >Close</button> */}
          </form>
        </Formik>
       
      </Box>
      
      
     
               </div> 
    </Modal>
    <Box display="flex" justifyContent="center" mt="20px" >
    <RadioGroup
    row
        aria-labelledby="demo-row-radio-buttons-group-label"
        // onChange={}
        defaultValue="commission"
        name="radio"
        onChange={(e)=> setRadion({...set, radio: e.target.value})}    
                 
        // onClick={ () => {handleradiobutton(radio-buttons-group)} }}
      >
        <FormControlLabel value="commission" control={<Radio />} label="commission" />
        <FormControlLabel value="driving" control={<Radio />} label="driving" />
        <FormControlLabel value="tariff" control={<Radio />} label="tariff" />
        {/* <FormControlLabel value="document" control={<Radio />} label="document" /> */}

      </RadioGroup>
      {set.radio=="commission"?
        <Button aria-describedby={openCommission} sx={{m:1}}onClick={handleOpenCommission} variant="contained" color="secondary"> 
					  commission
				</Button>:null }&nbsp;
        {set.radio=="driving"?
         <Button aria-describedby={openVehic}sx={{m:1}} onClick={handleOpenVehic} variant="contained" color="secondary"> 
			Driving
				</Button>:null}&nbsp;
        {set.radio=="tariff"?
         <Button aria-describedby={openTarif} sx={{m:1}}onClick={handleOpenTarif} variant="contained" color="secondary"> 
       		
           Tariff
				</Button>:null}&nbsp;
        {set.radio=="document"?
         <Button aria-describedby={opendoc} onClick={handleOpendoc} variant="contained" color="secondary"> 
       		
           Document
				</Button>:null}&nbsp;
    </Box>
    <Button aria-describedby={openColor} sx={{m:1}}onClick={handleOpenColor} variant="contained" color="secondary"> 
Set colors</Button>

<Button aria-describedby={openColor} sx={{m:1}}onClick={handleOpenGoogle} variant="contained" color="secondary"> 
Set Google Map Key</Button>
{/* End comission */}
{/* start Tariff setting */}
<Modal
      	open={openTarif} 
				onClose={handleCloseTarif} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
       <div >
       <Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 3"
        marginRight={50}
        width={20}
        sx={style} 
      >
       
     <Formik
          initialValues={{objectname: "", approvedby: "",refno:"", description:""}}
          // validationSchema={validationSchemarole}
          onSubmit={formikTarif.onSubmit}
        >
          
         <form className='formContainer' onSubmit={formikTarif.handleSubmit} encType='multipart/form-data'>
                   <ListItem  >
                   <TextField  
                        fullWidth
                        label="Vehicle Class"
                          variant="filled"
                          select
                          style = {{width: 400}}
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.vehicle_class}
                          name="vehicle_class"
                          size="small"
                          required
                        >
                       <MenuItem value="Sedan">Sedan</MenuItem>
                      <MenuItem value="SUV">SUV</MenuItem>
                      <MenuItem value="Luxury">Luxury</MenuItem>
                      <MenuItem value="Luxury SUV">Luxury SUV</MenuItem>
                      <MenuItem value="Van/Minibus">Van/Minibus</MenuItem>
                         </TextField>
                   </ListItem>
                   <ListItem>
                      <TextField  
                        fullWidth
                        label="Vehicle Capacity"
                          variant="filled"
                          select
                          style = {{width: 400}}
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.seatnumber}
                          name="seatnumber"
                          size="small"
                          required
                        >
                          <MenuItem value="4">up to 4 Passengers</MenuItem>
                          <MenuItem value="6"> up to 6 Passengers</MenuItem>
                          <MenuItem value="7"> up to 7 Passengers</MenuItem>
                          <MenuItem value="15"> up to 15 Passengers</MenuItem>
                         </TextField>
                   </ListItem>
                   <ListItem  >
                      <TextField  
                        fullWidth
                        label="Start fare"
                          variant="filled"
                          type="number"
                          style = {{width: 400}}
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.startfare}
                          name="startfare"
                          size="small"
                          required
                        >
                          
                         </TextField>
                   </ListItem>
                   <ListItem  >
                      <TextField  
                        fullWidth
                        label="Fare in 1KM"
                          variant="filled"
                          type='number'
                          style = {{width: 400}}
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.farekm}
                          name="farekm"
                          size="small"
                          required
                        >
                          
                         </TextField>
                   </ListItem>
                   <ListItem>
                   <TextField  
                        fullWidth
                        label="Elapsed time(Minute)"
                          variant="filled"
                          type='number'
                          style = {{width: 400}}
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.elapsed_time}
                          name="elapsed_time"
                          size="small"
                          required
                        >
                          
                         </TextField>
                   </ListItem>
                   <ListItem  >
                      <TextField  
                        fullWidth
                        label="Radius KM"
                          variant="filled"
                          type='number'
                          style = {{width: 400}}
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.radius}
                          name="radius"
                          size="small"
                          required
                        >
                          
                         </TextField>
                   </ListItem>
              
                   
                   <ListItem  >
                      <TextField  
                        fullWidth
                        label="Comission Agreement"
                          variant="filled"
                          type='number'
                          style = {{width: 400}}
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.commission}
                          name="commission"
                          size="small"
                          required
                        >
                          
                         </TextField>
                   </ListItem>
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="Referance Code"
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.refno}
                          required
                          name="refno"
                           >
                      </TextField>
                      </ListItem>
                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Description"
                          onChange={formikTarif.handleChange}
                          value={formikTarif.values.description}
                          required
                          size="small"
                          name="description"
                          ></TextField>
                      </ListItem>
            
            
            <Box display="flex"  mt="20px">
            <Button type='submit' color="secondary"  variant="contained"  onClick={formikTarif.onSubmit}> Apply</Button>
            &nbsp;&nbsp;&nbsp; 
                   <Button type="submit" color="error" onClick={handleCloseTarif} variant="contained">
                           Close
                        </Button> &nbsp;&nbsp;&nbsp;
                     </Box>
            {/* <button color="warning"onClick={handleClosereg} >Close</button> */}
          </form>
        </Formik>
      </Box>
    </div> 
    </Modal>


{/* End Tariff setting  */}
{/* Vehicle Setting */}

<Modal
      	open={openVehic} 
				onClose={handleCloseVehic} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
       <div >
       <Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 3"
        marginRight={50}
        width={20}
        sx={style} 
      >
     <Formik
          initialValues={{objectname: "", approvedby: "",refno:"", description:""}}
          // validationSchema={validationSchemarole}
          onSubmit={formikVehic.onSubmit}
        >
         <form className='formContainer' onSubmit={formikVehic.handleSubmit} encType='multipart/form-data'>
                    <ListItem  >
                      <TextField  
                        fullWidth
                        label="Min Model"
                          variant="filled"
                          type='number'
                          style = {{width: 400}}
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.minmodel}
                          name="minmodel"
                          size="small"
                          required
                        >
                         </TextField>
              </ListItem>
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="minimum balance"
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.minbalance}
                          required
                          name="minbalance"
                          >
                      </TextField>
                      </ListItem>
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="max balance"
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.maxbalance}
                          required
                          name="maxbalance"
                         
                          >
                      </TextField>
                      </ListItem>
                      
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="max Bonus"
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.maxbonus}
                          required
                          name="maxbonus"
                         
                          >
                      </TextField>
                      </ListItem>
                      
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="max Driving perday"
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.maxdrive}
                          required
                          name="maxdrive"
                         
                          >
                      </TextField>
                      </ListItem>

                      <ListItem>
                      <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="text"
                          label="max number of reject"
                          size="small"
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.maxnumberofreject}
                          required
                          name="maxnumberofreject"
                          />
                    </ListItem>
                    <ListItem>
                      <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="text"
                          label="Punishment In hours"
                          size="small"
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.punishment}
                          required
                          name="punishment"
                          />
                    </ListItem>
                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Refnumber"
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.refno}
                          required
                          size="small"
                          name="refno"
                          ></TextField>
                      </ListItem>
                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Description"
                          onChange={formikVehic.handleChange}
                          value={formikVehic.values.description}
                          required
                          size="small"
                          name="description"
                          ></TextField>
                      </ListItem>
            
            
            <Box display="flex"  mt="20px">
            <Button type='submit' color="secondary"  variant="contained"  onClick={formikVehic.onSubmit}> apply</Button>
            &nbsp;&nbsp;&nbsp; 
                   <Button type="submit" color="error" onClick={handleCloseVehic}variant="contained">
                           Close
                        </Button> &nbsp;&nbsp;&nbsp;
                     </Box>
            {/* <button color="warning"onClick={handleClosereg} >Close</button> */}
          </form>
        </Formik>
      </Box>
    </div> 
    </Modal>
    

{/* End Vehicle setting */}
{/* start document */}

<Modal
      	open={opendoc} 
				onClose={handleClosedoc} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
       <div >
       <Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 3"
        marginRight={50}
        width={20}
        sx={style} 
      >
     {/* <Formik */}
          
          {/* validationSchema={validationSchemarole}
          initialValues={{}} */}

          {/* // onSubmit={formikdoc.onSubmit} */}
        {/* > */}
         <form className='formContainer' onSubmit={formikdoc.onSubmit} encType='multipart/form-data'>
                    <ListItem  >
                      <TextField  
                        fullWidth
                        label="Document Name"
                          variant="filled"
                          type='text'
                          style = {{width: 400}}
                          onChange={formikdoc.handleChange}
                          value={formikdoc.values.documentname}
                          name="documentname"
                          size="small"
                          required
                        >
                         </TextField>
              </ListItem>
                    
                      
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="Document owner"
                          onChange={formikdoc.handleChange}
                          value={formikdoc.values.docowner}
                          required
                          name="docowner"
                          select
                         
                          >
                            <MenuItem value="Drivers">For Drivers</MenuItem>
                            <MenuItem value="Vehicles">For Vehicles</MenuItem>
                            <MenuItem value="Agents">For Agents</MenuItem>
                            <MenuItem value="Staffs">For Staffs</MenuItem>
                            <MenuItem value="Others">For Others</MenuItem>
                      </TextField>
                      </ListItem>

                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Refnumber"
                          onChange={formikdoc.handleChange}
                          value={formikdoc.values.refno}
                          required
                          size="small"
                          name="refno"
                          ></TextField>
                      </ListItem>
                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Description"
                          onChange={formikdoc.handleChange}
                          value={formikdoc.values.description}
                          required
                          size="small"
                          name="description"
                          ></TextField>
                      </ListItem>
            
            
            <Box display="flex"  mt="20px">
            <Button type='submit' color="secondary"  variant="contained"  onClick={formikdoc.onSubmit}> apply</Button>
            &nbsp;&nbsp;&nbsp; 
                   <Button  color="error" onClick={handleClosedoc}variant="contained">
                           Close
                        </Button> &nbsp;&nbsp;&nbsp;
                     </Box>
            {/* <button color="warning"onClick={handleClosereg} >Close</button> */}
          </form>
        {/* </Formik> */}
      </Box>
    </div> 
    </Modal>

{/* end document */}
<Box
      
      m="40px 0 0 0"
      height="60vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
     >
      {set.radio=="commission"?
      // <Button>commissio1n</Button>
        <DataGrid style={{height: '80%', width: '100%'}} rows={com} columns={commi}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>

:null}
{set.radio=="driving"?
        <DataGrid style={{height: '90%', width: '100%'}} rows={drive} columns={driving}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>

:null}
{set.radio=="tariff"?
        <DataGrid style={{height: '90%', width: '100%'}} rows={tar} columns={tariffcol}  components={{ Toolbar: GridToolbar }} rowHeight={20} />

:null}

      </Box>
        </TabPanel>



      {/* </SwipeableViews> */}
    </Box>
    </Box>
    
  );
};
export default SYSTEMSETTING;
