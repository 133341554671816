import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MyDatePicker = ({ value, onChange, ...props }) => {
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      dateFormat="MMMM d, yyyy"
      customInput={
        <input
          style={{ width: '200px', height: '40px' }} // Inline styles
          className="custom-date-picker" // CSS class
          {...props}
        />
      }
    />
  );
};

export default MyDatePicker;
