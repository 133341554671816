import React from "react";
import Layout from "../components/Layout/Layout";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import LocationIcon from "@mui/icons-material/LocalActivity";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  List, ListItem, ListItemText, ListItemIcon 
} from "@mui/material";

const Unsubscribe = () => {
  return (
    <Layout>
      <Box sx={{ my: 5, ml: 2, "& h4": { fontWeight: "bold", mb: 2 } }}>
        <Typography variant="h4"> Procedure for Unsubscribing in a Mobile App </Typography>
<List>
<ListItem><b>Step 1: Open the Mobile App</b></ListItem>

<ListItem>
Launch the App: Tap on the app icon on your mobile device to open the application
</ListItem>
        
        <ListItem>
<b>Step 2: Navigate to the Menu</b>
  </ListItem>
  <ListItem>
  Access the Menu: Look for a Delete Account
  under menu
 </ListItem>
 <ListItem>
 Press Delete Permanently Button 
</ListItem>



</List>
      </Box>
      
    </Layout>
  );
};

export default Unsubscribe;
