import { useState, useContext,useEffect  } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Sidebar from "./pages/global/Sidebar";
import Dashboard from "./pages/dashboard";
import Rider from "./pages/riders";
import RiderPage from "./pages/riderpage";
import Profile from "./pages/profile";
import Agents from "./pages/agents";
import Employee from "./pages/employee";


// import SMS from "./pages/sms";
import VehiclePage from "./pages/vehicle";
import Userlogdetail from "./pages/userlogdetail";
import User from "./pages/register/index";
import Register from "./pages/register";
import DriverPage from "./pages/driver";
// import Approval from "./pages/approvalpage";
import DriverDoc from "./pages/driverdocument";
import VehicleDoc from "./pages/vehicledocument";
import Driver from "./pages/drivers";
import Accaunts from "./pages/accaunts";
import Orders from "./pages/orders";
import SMS from "./pages/sms";
import Transactions from "./pages/transactions";
import Report from "./pages/report";
import SYSTEMSETTING from "./pages/SYSTEMSETTING";
import ManualOrder from "./pages/ManualOrder";
import Vehicle from "./pages/vehicles";
import PanoramicView from "./pages/panoramicView";
import LoginForm from "./pages/login";
import Bar from "./pages/bar";
import PrintPage from "./pages/print/index";
import Geography from "./pages/geography";
import Driverdetailtransaction from "./pages/driverdetailtransaction";
import StaffTransaction from "./pages/staffTransaction";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { AuthContext } from "./context/AuthContext";
// import Reset from "./pages/reset";
import Home from "./web/Home";
import About from "./web/About";
import Contact from "./web/Contact";
import Login from "./web/Login";
import Career from "./web/Career";
import Pagenotfound from "./web/Pagenotfound";
import Privacy from "./web/Privacy";
import Unsubscribe from "./web/unsubscribe";




function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const { currentUser } = useContext(AuthContext);

  return (
    <>
    {currentUser ?
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
              <Sidebar isSidebar={isSidebar} />
              <main className="content">
                <Topbar setIsSidebar={setIsSidebar} />
                <Routes>
                  <Route path="/home" element={<Dashboard />} />
                  <Route path="/riders" element={<Rider />} />
                  <Route path="/ManualOrder" element={<ManualOrder />} />
                  <Route path="/riders/:id" element={<RiderPage />} />
                  <Route path="/staffTransaction/:id" element={<StaffTransaction />} />
                  <Route path="/driver/:id" element={<DriverPage />} />
                  <Route path="/profile/:id" element={<Profile />} />
                  <Route path="/driverdetailtransaction/:id" element={<Driverdetailtransaction />} />
                  <Route path="/vehicle/:id" element={<VehiclePage />} />
                  {/* <Route path="/approvalpage/:id" element={<Approval />} /> */}
                  <Route path="/Userlogdetail/:id" element={<Userlogdetail />} />
                  <Route path="/vehicledocument/:id" element={<VehicleDoc />} />
                  <Route path="/driverdocument/:id" element={<DriverDoc/>} />
                  <Route path="/user" element={<User />} />
                  <Route path="/accaunts" element={<Accaunts />} />
                  <Route path="/sms" element={<SMS />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/drivers" element={<Driver />} />
                  <Route path="/vehicles" element={<Vehicle />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/agents" element={<Agents />} />
                  <Route path="/employee" element={<Employee />} />
                  <Route path="/panoramicView" element={<PanoramicView />} />
                  
                  {
                    currentUser.accesslevel == 'admin' && (
                      <Route path="/SYSTEMSETTING" element={<SYSTEMSETTING />} />
                    )
                  }
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/report" element={<Report />} />
                  <Route path="/driverdetailtransaction/:id" element={<Driverdetailtransaction />} />
                  <Route path="/staffTransaction/:id" element={<StaffTransaction />} />
                  <Route path="/geography" element={<Geography />} />
                </Routes>
              </main>
        </div>
     </ThemeProvider>
    </ColorModeContext.Provider>
    :
    <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portal" element={<LoginForm />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
    </div>
    }
    </>
  );
}

export default App;
