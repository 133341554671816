//import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import React, { useMemo } from "react";
import { useTable } from "react-table";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import LocalTaxiOutLinedIcon from "@mui/icons-material/LocalTaxiOutlined";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddFile from "@mui/icons-material/UploadFile";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
// import Header from "../../components/Header";
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EmailIcon from "@mui/icons-material/Email";
// import DatePicker from '@mui/material/DatePicker';
import List from '@mui/material/List';
import StatBox from "../../components/StatBox";
import { AuthContext } from '../../context/AuthContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import {Link, useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import AccordionDetails from "@mui/material/AccordionDetails";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Modal from "@mui/material/Modal"; 
import { GridPrintExportMenuItem } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { blue, lightBlue } from "@mui/material/colors";
import { Input,Snackbar } from '@mui/material';
// var RNFetchBlob = require('react-native-fetch-blob')

const style1 = {
  py: 6,
  width: '100%',
  maxWidth: 360,
  borderRadius: 2,
  //border: '2px solid',
  borderColor: "#2962ff",
  backgroundColor: '#343433',
};
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
}; 
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx2={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
 function VehiclePage() {
  const theme = useTheme();
  const { currentUser,url,logout} = useContext(AuthContext);
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  let { id } = useParams();
  // const url="http://localhost:3001";
  // const url ="https://www.walifride.com"
  const [doc, setData] = useState([]);
  // useEffect(() => {
  //   axios.post(url+`/api/vehiclesdocuments/${id}`,{username:currentUser.username}).then((response) => {
  //     setData(response.data);
  //      console.log(doc);
  //     // alert(driver.firstname)
  //      debugger;  
 
  //    }).catch(e => {
  //      console.log('error:', e);
  //    });
  //  });
  //End Fetch Vehicle File
  const [downloadOpen, setdownloadmodal] = React.useState(false); 
  const handledownloadmodal = () => setdownloadmodal(true); 
  const handleclosedownloadmodal = () => setdownloadmodal(false); 
  const [document, setDocument]=useState();
  function handleDownload(docid){
    setDocument(docid)
    handledownloadmodal(true)
    // alert(document)

  }


  const [openchangeprofile, setOpenchangeprofile] = React.useState(false); 
  const handleopenchangeprofile = () => setOpenchangeprofile(true); 
  const handleopenchangeprofileclose = () => setOpenchangeprofile(false); 

  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [pros, setPro] = useState([]);
  const formicspr = useFormik({
    initialValues: {
      path: '',
      vehicleId:id,
      username:currentUser.username
    },
    onSubmit: (values) => {
      // console.log(values);
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      // alert("change profile methode")
      const updatevehic = async () => {
        try {
          const config = {
              headers: {
                  'Authorization': `Bearer ${currentUser.accessToken}`
              }
          };
      
      axios.post(url+`/api/vehicles/pro/${id}`, formData,config).then((res) => {
        setOpenchangeprofile(false)
        setNotification({
          show: true,
          type: 'success',
          message: res.data
      });
      setIsRedirecting(true);
      const timer = setTimeout(() => {
      }, 3000);
    
      return () => clearTimeout(timer);


      });
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
  };
  
  updatevehic();
    },
  });

  
  function handleDownload2 (REMOTE_IMAGE_PATH ){
    // var date      = new Date();
    // // var url       = "http://www.clker.com/cliparts/B/B/1/E/y/r/marker-pin-google-md.png";
    // var ext       = this.extention(REMOTE_IMAGE_PATH);
    // ext = "."+ext[0];
    // const { config, fs } = RNFetchBlob
    // let PictureDir = fs.dirs.PictureDir
    // let options = {
    //   fileCache: true,
    //   addAndroidDownloads : {
    //     useDownloadManager : true,
    //     notification : true,
    //     path:  PictureDir + "/image_"+Math.floor(date.getTime() + date.getSeconds() / 2)+ext,
    //     description : 'Image'
    //   }
    // }
    // config(options).fetch('GET', REMOTE_IMAGE_PATH).then((res) => {
    //   Alert.alert("Success Downloaded");
    // });
  }
function extention(filename){
    // return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  }

  const [vdoc, setvdoc] = useState([]);
   const formics = useFormik({
    initialValues: {
      path: '',
      title: '',
      remark: '',
      vehicle: id,
      status:"",
      username:currentUser.username
    },
    onSubmit: (values) => {
      // console.log(values);
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      axios.post(url+'/api/vehiclesdocuments', formData).then((res) => {
        // setvdoc(vdoc.concat(res.data));
        setaddfile(false);
        // alert(res.data)
        setNotification({
          show: true,
          type: 'success',
          message: res.data
      });
      setIsRedirecting(true);
      const timer = setTimeout(() => {
      }, 3000);
    
      return () => clearTimeout(timer);

      });
    },
  });

 
const isNonMobile = useMediaQuery("(min-width:600px)");
  
  const [veh, setVehic] = useState([]);

  const [vehicleColorList, setVehicleColorList] = useState([]);
  const [vehicle, setVehicle] = useState([]);

  useEffect(() => {
    const fetchvehicle = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${currentUser.accessToken}`
                }
            };
            const response = await axios.post(url + '/api/vehicles/all', { username: currentUser.username }, config);
            setVehicle(response.data.vehicle);
            setVehicleColorList(response.data.colorList); 
          }catch (e) {
            if (e.toJSON().status === 401) {
                console.log('No valid token or session expired');
                logout();
            } else {
                console.error(e);
            }
        }
    };

    fetchvehicle();
}, [currentUser.username, currentUser.accessToken]);


const [tar, setTar]=useState() 


useEffect(() => {
  const gettariff = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + '/api/tariffs/gettariffvehic_class', { username: currentUser.username }, config);
      setTar(response.data.tar);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};
gettariff();
}, [currentUser.username, currentUser.accessToken]);

  const [imageSrc, setImageSrc] = useState('');
  const [tarif, setTariff] = useState('');

  const [extention2, setExtention] = useState('');
  useEffect(() => {
    const fetchDriver = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + `/api/vehicles/${id}`, { username: currentUser.username }, config);
        setVehic(response.data.vehicle);
        console.log(response.data.vehicle);
        setImageSrc(response.data.image);
        setExtention(response.data.extentions)
        setTariff(response.data.tarif)
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

fetchDriver();
}, [currentUser.username, currentUser.accessToken]);  
const [image, setImage] = useState("");

useEffect(() => {
  axios.get(url+`/api/vehicles/image/${id}`, { responseType: 'blob' })
  .then(response => {
    const im = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = im;
    link.setAttribute('download', 'file.png'); //or any other extension
    document.body.appendChild(link);
    setImage(im);
  })
  .catch(error => {
    console.log('There was an error!', error);
  });
}, []);

  const [updatevalue, setValueUpdate] = useState({
     username: currentUser.username,
  });
   const updateVehicle = (e) => {
    e.preventDefault()
    const updatevehic = async () => {
      try {
        alert(updatevalue.numbreofseats)
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
    
    axios.put(url+`/api/vehicles/${id}`, updatevalue,config)
    .then((res)=>{
      setOpen(false);
      if(res.data=="ok"){
        setNotification({
          show: true,
          type: 'success',
          message: "Vehicle Information is updated"
      });
      setIsRedirecting(true);
      const timer = setTimeout(() => {
      }, 3000);
    
      return () => clearTimeout(timer);

      }else if(res.data=="no"){
        setNotification({
          show: true,
          type: 'success',
          message: "Sorry, You have no permission to update Vehicle information"
      });
      setIsRedirecting(true);
      const timer = setTimeout(() => {
      }, 3000);
    
      return () => clearTimeout(timer);

        }else{
          setNotification({
            show: true,
            type: 'success',
            message: "Error Happen during updating Vehicle information"
        });
        setIsRedirecting(true);
        const timer = setTimeout(() => {
        }, 3000);
      
        return () => clearTimeout(timer);
  
      }
    })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
}
};

updatevehic();
}
 
const PdfViewer = ({ src, width, height }) => {
  return (
    <embed
      src={src}
      type="application/pdf"
      width={width || '100%'}
      height={height || '600px'}
    />
  );
};


const [open, setOpen] = React.useState(false); 
const handleOpen = () => setOpen(true); 

const handleClose = () => setOpen(false); 
const [addfileOpen, setaddfile] = React.useState(false); 
const handleopenaddfile = () => setaddfile(true); 
const handleCloseaddfile = () => setaddfile(false); 
const handleChange = (event, newValue) => {
 setValue(newValue);
  };
 
//dowload vehicle image
const handleDownloadpdf = () => {
  const config = {
    headers: {
      'Authorization': `Bearer ${currentUser.accessToken}`
    },
    responseType: 'blob' // Ensure responseType is correctly set here
  };

  axios.post(`${url}/api/vehicles/profiles/download/${id}`, { username: currentUser.username }, config)
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', veh.platenumber+'_'+id+'.jpg');
      document.body.appendChild(link);
      link.click();

    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        console.log('No valid token or session expired');
        logout(); // Assuming logout function exists to handle token expiration
      } else {
        console.error('Error downloading PDF:', error);
      }
    });
};


//End for table transaction 
  
  return (
    <Box m="20px"
    style={{height: '85%'}}
  >
  <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		      backgroundColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
        >
      <Tab label="Vehicle Detail" {...a11yProps(0)} />
      {/* <Tab label="Transaction" {...a11yProps(1)} /> */}
      {/* <Tab label="Manage status" {...a11yProps(2)} />
		  <Tab label="Order history" {...a11yProps(3)} />
		  <Tab label="Manage complain" {...a11yProps(4)} />
		  <Tab label="Online" {...a11yProps(5)} />
		  <Tab label="Accounts" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
        <TabPanel value={value} index={0} dir={theme.direction}>
		      <div>  
            <Box m="20px">
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="140px"
              gap="20px"
            >

{notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}



            <Box
                gridColumn="span 8"
                backgroundColor={colors.primary[300]}
                display="flex"
                gridRow="span 3" 
              >
         <List  label="Drivers Profile">
            <Box gridColumn="span 8">
              <ListItem>
                <ListItemText primary="Plate Number" display="grid" gridColumn="span 2"/>
                    <ListItemText  name="platenumber"size="small"primary={veh.platenumber}/>
                </ListItem>
              <ListItem>
              <ListItemText primary="Driver ID" />
                <ListItemText size="small"name="driverId"primary={veh.driverId} />
              </ListItem>
              <ListItem>
              <ListItemText primary="Vehicle Type" />
                <ListItemText size="small"name="vehicletype"primary={veh.vehicletype} id="e" />
              </ListItem>
              <Divider variant="middle" component="li" />
              <ListItem>
              <ListItemText primary="Brand" />
                <ListItemText size="small"primary={veh.brand} />
              </ListItem>
              <ListItem>
              <ListItemText primary="Vehicle Category" />
               <ListItemText name="Vehicle_Category"size="small"primary={tarif.id} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Model" />
              <ListItemText size="small"primary={veh.vehiclemodel} name="model"/>
              </ListItem>
              <ListItem>
              <ListItemText primary="Vehicle color" /> 
                <ListItemText size="small"name="vehiclecolor" primary={veh.vehiclecolor} />
              </ListItem>
              <ListItem>
               <ListItemText primary="Chargeperkm" />
              <ListItemText size="small" name="chargeperkm" primary={veh.chargeperkm}/>
              </ListItem>
              <ListItem>
              <ListItemText primary="Status" />
              <ListItemText size="small" name="status" primary={veh.status}/>
              </ListItem>               
            </Box>
          
          
          </List>
          <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[300]}
          display="flex"
          gridRow="span 3" 
        >
            <Typography sx={{ marginBottom: 4 }}>

          General Vehicle information
          </Typography>
          <Typography sx={{ marginBottom: 4 }}>
     
     <table>
       <tr>
         <td> vehicle_class</td>
       
         <td> {tarif.vehicle_class}</td>
       </tr>
       <tr>  <td> seat No</td>  <td> {tarif.seatnumber} </td>  </tr>
       <tr>  <td> startfare</td>  <td> {tarif.startfare} </td>  </tr>

       <tr>  <td> farekm</td>  <td> {tarif.farekm} </td>  </tr>

       <tr>  <td> elapsed_time</td>  <td> {tarif.elapsed_time} </td>  </tr>

       <tr>  <td> radius</td>  <td> {tarif.radius} </td>  </tr>
       <tr>  <td> commission</td>  <td> {tarif.commission} </td>  </tr>
       <tr>  <td> refno</td>  <td> {tarif.refno} </td>  </tr>
       <tr>  <td> createdby</td>  <td> {tarif.createdby} </td>  </tr>
       <tr>  <td> approvedby</td>  <td> {tarif.approvedby} </td>  </tr>
       <tr>  <td> status</td>  <td> {tarif.status} </td>  </tr>
       <tr>  <td> description</td>  <td> {tarif.description} </td>  </tr>
      </table>
     </Typography>
        </Box>
          </Box>
          
       
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[300]}
          display="flex"
          gridRow="span 3" 
        >
          
         <List>
      <Box gridColumn={4}>
      <ListItem>
      
       <Button aria-describedby={open} onClick={handleOpen} variant="contained"> 
					<Edit/>Edit
				</Button>&nbsp;&nbsp;&nbsp;
     
      </ListItem>
      {/* <img src={image} width={280} height={345} alt=""/>  */}
      {extention==='.pdf'?
            imageSrc && <PdfViewer src={imageSrc} width="300px" height="400px" />
      :
    imageSrc && < img src={imageSrc} width="350px" height="400px" />}
      </Box>
      <Typography>
      <Button  color="secondary" variant="contained"onClick={handleopenchangeprofile} sx={{ m: 1 }}>  Change Image</Button>
      <Button  color="secondary" variant="contained"onClick={handleDownloadpdf} sx={{m:1}}>  Download </Button>

      <Button  color="secondary" variant="contained"onClick={ () => {navigate(`/vehicledocument/${id}`)} }>   Document</Button>
   
   <Modal 
           open={openchangeprofile} 
           onClose={handleopenchangeprofileclose} 
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"> 
                   <Box
             //gridColumn="span 6"
             backgroundColor={colors.grey[400]}
             display="flex"
             gridRow="span 3" 
             // marginLeft={20}
             marginRight={50}
             width={20}
             height={500}
             sx={style}
            // backgroundColor="#2979ff"  welcometoreciept
             >
   
   <form className='formContainer' onSubmit={formicspr.handleSubmit} encType='multipart/form-data'>
        
        
        <ListItem>
        <ListItemText  id="dis2"primary="File" />
       {/*  */}
   <input type="file"  name="path"
                onChange={(e) => {
                 let reader = new FileReader();
                 reader.onload = () => {
                   if (reader.readyState === 2) {
                       formicspr.setFieldValue('path', e.target.files[0])
                      }
                  }
                 reader.readAsDataURL(e.target.files[0]);
                 }}
              />
   {/*  */}
   
        </ListItem>
       
   
   <Button type="submit" color="secondary" variant="contained"sx={{ m: 1 }} >  change</Button>
           <Button color="error" onClick={handleopenchangeprofileclose}variant="contained"> Close </Button> &nbsp;&nbsp;&nbsp;
           </form>
           </Box>
           </Modal>
   
      </Typography>
      </List>
</Box>

</Box>
  <Modal 
				open={addfileOpen} 
				onClose={handleCloseaddfile} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"> 
        <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[300]}
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
          width={20}
          sx={style}
         // backgroundColor="#2979ff"
          >
       
          <Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
       <form className='formContainer' onSubmit={formics.handleSubmit} encType='multipart/form-data'>
       <ListItem>
         <ListItemText  id="dis2"primary="File" />
           <input type="file"  name="path"
              onChange={(e) => {
               let reader = new FileReader();
               reader.onload = () => {
                 if (reader.readyState === 2) {
                     formics.setFieldValue('path', e.target.files[0])
                    }
                }
               reader.readAsDataURL(e.target.files[0]);
               }}
            />
         <ListItemText primary="Title"  id="dis4" />
         <FormControl  size="small"  id="dis5">
           {/* sx={{ m: 1, minWidth: 120 }} */}
              <Select name="title"
                onChange={formics.handleChange}
                value={formics.values.title}
                size='small'
                variant="filled"
                required
                id="dis6"
                sx={{ gridColumn: "span 2" }}>
                  <MenuItem value="bussinesslicence">Bussiness Licence</MenuItem>
                  <MenuItem value="Bussinessregistration">Bussiness Registration</MenuItem>   
                  <MenuItem value="Lebre"> libre</MenuItem>       
                  <MenuItem value="owner Representative Certificate">Representative Certificate if not owner</MenuItem>        
                  <MenuItem value="TIN  Certificate">TIN  Certificate</MenuItem>     
                  <MenuItem value="3rd party Insurance Certificate ">3rd party  Insurance Certificate</MenuItem>   
                  
              </Select>
         </ FormControl>
        </ListItem>
        <ListItem>
         <ListItemText primary="Remarks" />
         <TextField size="small" name="remark" 
            onChange={formics.handleChange}
            value={formics.values.remark}/>
            {/* <ListItemText primary="Date" />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
           <DatePicker />
          </LocalizationProvider> */}
        </ListItem>
        <Button type="submit" color="secondary" variant="contained">  Add File</Button>
        <Button color="error" onClick={handleCloseaddfile}variant="contained"> Close </Button> &nbsp;&nbsp;&nbsp;
      </form>
    </Typography>
   </Box>
 </Modal>
 {/* ghjghg */}

 <Modal 
				open={downloadOpen} 
				onClose={handleclosedownloadmodal} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"> 
        <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[400]}
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
          width={20}
          height={500}
          sx={style}
         // backgroundColor="#2979ff"  welcometoreciept
          >
            
            
       <Typography
       >
       <img src={url+document} width={550} height={600} alt=""/> 
       {/* <img src={image} alt="From Server" /> */}
       <Typography></Typography>
        <Button type="submit" color="secondary" variant="contained" onClick={ () => {handleDownload2(`${url+document}`)} }>  download</Button>
        <Button color="error" onClick={handleclosedownloadmodal}variant="contained"> Close </Button> &nbsp;&nbsp;&nbsp;
      
    </Typography>
   </Box>
 </Modal>

 {/* jhhjhg */}

<Modal 
				open={open} 
				onClose={handleClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
        headerAlign="modal-"
			> 
			<Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 3"
        marginRight={50}
        width={20}
        sx={style} 
          >
        		<Typography id="modal-modal-title" 
						variant="h6" component="h2"
            > 
					</Typography> 
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}
            > 
            {/* <form onSubmit={handleSubmit}> */}
            {/* <form className='formContainer' onSubmit={formik.handleSubmit} encType='multipart/form-data'> */}
                      <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                      >
                      <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Plate Number"
                          size="small"
                          defaultValue={veh.platenumber}
                        //  onChange={(e)=> setValues({...values, platenumber: e.target.value})}
                         onChange={(e)=> setValueUpdate({...updatevalue, platenumber: e.target.value})}
               
                        //   value={values.firstName}
                          //onChange={formik.handleChange}
                          name="platenumber"
                          sx={{ gridColumn: "span 1" }}
                        />
                          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small-label">Vehicle Class</InputLabel>
                          <Select name="numbreofseats"
                            size="small"
                            variant="filled"
                              label=""
                            //  onChange={(e)=> setValues({...values, vclass: e.target.value})}
                            onChange={(e)=> setValueUpdate({...updatevalue, numbreofseats: e.target.value})}
                              required
                              sx={{ gridColumn: "span 1" }}
                              >  
                            {tar ? (
                  tar.map(vehicle => (
                    <MenuItem key={vehicle.id} value={vehicle.id}>
                      {vehicle.vehicle_class}{vehicle.seatnumber}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No data available</MenuItem>
                )}
                            </Select>
                          </FormControl>
          
                            {
                            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                              <InputLabel id="demo-select-small-label">Vehicle Type</InputLabel>
                              <Select 
                                size="small"
                                variant="filled"
                                name="brand"
                                label="Vehicle Class"
                                required
                                defaultValue={veh.brand}
                                onChange={(e)=> setValueUpdate({...updatevalue, brand: e.target.value})}
                                >
                                                    <MenuItem value="Corolla Toyota">Corolla Toyota</MenuItem>
                  <MenuItem value="Vitz Toyota">Vitz Toyota</MenuItem>
                  <MenuItem value="Hitch Pack">Hitch Pack</MenuItem>
                  <MenuItem value="Suzuki Desire">Suzuki Desire</MenuItem>
                  <MenuItem value="Atos suzuki">Atos Suzuki</MenuItem>
                  <MenuItem value="others">Others</MenuItem>
                  <MenuItem value="Hitch Pack">Hitch Pack</MenuItem>
                  <MenuItem value="Mistbush">Mistbush</MenuItem>
                  <MenuItem value="Haundai">Haundai</MenuItem>
                  <MenuItem value="Dx">Dx</MenuItem>
                  <MenuItem value="Rech">Rech</MenuItem>
                  <MenuItem value="Wolsewagen">Wolsewagen</MenuItem>

                              </Select>
                        </FormControl>
                          }
                        <TextField 
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Vehicle Model"
                          required
                          name="vehiclemodel"
                          onChange={(e)=> setValueUpdate({...updatevalue, vehiclemodel: e.target.value})}
                          defaultValue={veh.vehiclemodel}
                          sx={{ gridColumn: "span 1" }}
                          >
                          </TextField>
                          <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          onChange={(e)=> setValueUpdate({...updatevalue, vehiclecolor: e.target.value})}
                          //value={formik.values.vehiclecolor}
                          required
                          defaultValue={veh.vehiclecolor}
                          name="vehiclecolor"
                          sx={{gridColumn: "span 1"}}
                          />

                          

                        <TextField
                          fullWidth
                          variant="filled"
                          type="number"
                          label="Charge per kM"
                         // onChange={formik.handleChange}
                         // value={formik.values.chargeperkm}
                          defaultValue={veh.chargeperkm}
                          required
                          name="chargeperkm"
                          sx={{ gridColumn: "span 1" }}
                          onChange={(e)=> setValueUpdate({...updatevalue, chargeperkm: e.target.value})}
                          >
                        </TextField>
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                        <Button type="submit" color="secondary" onClick={updateVehicle} variant="contained">
                          Update
                        </Button> &nbsp;&nbsp;&nbsp;
                        <Button type="submit" color="error" onClick={handleClose}variant="contained">
                           Close
                        </Button> &nbsp;&nbsp;&nbsp;
                     </Box>
                    {/* </form> */}
                  </Typography> 
                </Box> 
	           	</Modal> 
            </Box>
        </div>  
      </TabPanel>
      {/* </SwipeableViews> */}
  </Box>
    </Box>
  );

};
export default VehiclePage;
