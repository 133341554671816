import React from "react";
import Layout from "../components/Layout/Layout";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import LocationIcon from "@mui/icons-material/LocalActivity";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  List, ListItem, ListItemText, ListItemIcon 
} from "@mui/material";

const Privacy = () => {
  return (
    <Layout>
      <Box sx={{ my: 5, ml: 2, "& h4": { fontWeight: "bold", mb: 2 } }}>
        <Typography variant="h4"> Waliif Ride </Typography>
<List>
<ListItem><b>1. Introduction</b></ListItem>

<ListItem>
Welcome to Waliif Transport Share Company, an online taxi booking system. 
This Privacy Policy explains how we collect, use, disclose, and safeguard your information 
when you use our mobile application (the "App"). Please read this Privacy Policy carefully. 
If you do not agree with the terms of this Privacy Policy, please do not use the App.
</ListItem>
        
        <ListItem>
<b>2 Information We Collect</b>
  </ListItem>
  <ListItem>
 Personal Information
 </ListItem>
 <ListItem>
Name
Contact information (email address, phone number)
Payment information (credit card details, etc.)
Location data (pick-up and drop-off locations)
</ListItem>

<ListItem><b>Non-Personal Information</b>

</ListItem>

<ListItem>
Device information (device type, operating system, unique device identifiers),
Usage data (how you interact with the App),
Log data (IP address, browser type, pages visited, time spent on pages)</ListItem>


<ListItem><b>3. How We Collect Information</b>

</ListItem>

<ListItem> <b> Directly from You:</b>
When you create an account,
When you book a ride,
When you make a payment,
When you contact customer support</ListItem>



<ListItem> <b> Automatically:</b>
Through the use of cookies and similar technologies
Through your device's GPS and other location-based services</ListItem>


<ListItem><b>4. How We Use Your Information</b>

</ListItem>

<ListItem> <b> To Provide Our Services:</b>
To process and manage your bookings,
To facilitate payments,
To provide customer support</ListItem>

<ListItem> <b> To Improve Our Services:</b>
To analyze usage patterns and trends,
To develop new features and services</ListItem>

<ListItem> <b> To Communicate with You:</b>
To send you updates, notifications, and promotional materials
To respond to your inquiries and feedback</ListItem>
<ListItem> <b> For Legal and Compliance Purposes:</b>
To comply with applicable laws and regulations,
To enforce our Terms of Service</ListItem>
<ListItem><b>5. How We Share Your Information</b>

</ListItem>

<ListItem> <b> With Service Providers:</b>
To facilitate payments,
To provide customer support</ListItem>
<ListItem> <b> With Third-Party Partners:</b>
For marketing and advertising purposes,
For analytics and data processing</ListItem>

<ListItem> <b> For Legal Reasons:</b>
To comply with legal obligations,
To protect our rights and interests</ListItem>

<ListItem><b> 6. Data Security</b>

</ListItem>

<ListItem> We implement reasonable security measures to protect your information from 
  unauthorized access, disclosure, alteration, and destruction. However, no method of transmission 
  over the internet or method of electronic storage is 100% secure.</ListItem>

  <ListItem><b> 7. Data Retention</b>

</ListItem>

<ListItem> We retain your information for as long as necessary to fulfill the
   purposes outlined in this Privacy Policy,
   unless a longer retention period is required or permitted by law.</ListItem>

   <ListItem><b> 8. Your Rights</b>

</ListItem>

<ListItem> 
Depending on your location, you may have certain rights regarding your
 personal information, including the right to access, correct, or delete your information.
 To exercise these rights, please contact us at 7071.</ListItem>


 <ListItem><b>9. Children's Privacy</b>

</ListItem>

<ListItem> 

Our App is not intended for use by individuals under the age of 18. We do not knowingly
 collect personal information from children under the age of 18.
 If you believe we have collected such information, please contact us immediately..</ListItem>


 <ListItem><b>10. Changes to This Privacy Policy</b>

</ListItem>

<ListItem> 
We may update this Privacy Policy from time to time. We will notify you of any changes by posting the 
new Privacy Policy on this page and updating the effective date at the top.</ListItem>

<ListItem><b>11. Contact Us</b>

</ListItem>

<ListItem> 

If you have any questions or concerns about this Privacy Policy, please contact us at:</ListItem>
<ListItem>
  email: waliiftrsc@gmail.com

</ListItem>
<ListItem>
  Phone:7071
</ListItem>
</List>
      </Box>
      
    </Layout>
  );
};

export default Privacy;
