import waliiiiiiiiiiiiif from "../images/waliiiiiiiiiiiiif.jpg";
import waliifred from "../images/waliifred.jpg";
import bluetaxi from "../images/bluetaxi.jpg";
import MasalaDosa from "../images/masala.jpg";
import Paneer from "../images/paneer.jpg";
import Gujrati from "../images/gujrati.jpeg";

export const MenuList = [
  {
    name: "Waliif Transport Share Company",
    description:
      "We do have a surprise for you",
    image: waliiiiiiiiiiiiif,
    price: 200,
  },
  {
    name: "Finfinnee and Shagar",
    description:
      "New arrival discount",
    image: waliifred,
    price: 250,
  },
  {
    name: "Enjoy Our Package",
    description:
      "We have always Discount, thank you for choosing and believe in us",
    image: bluetaxi,
    price: 300,
  },
  // {
  //   name: "Masala Dosa",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, ab!",
  //   image: MasalaDosa,
  //   price: 100,
  // },
  // {
  //   name: "Paneer",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, ab!",
  //   image: Paneer,
  //   price: 400,
  // },
  // {
  //   name: "Gujrati",
  //   description:
  //     "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, ab!",
  //   image: Gujrati,
  //   price: 500,
  // },
];
