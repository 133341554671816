import React, { useContext } from 'react';
import { Alert, Snackbar,CircularProgress } from '@mui/material';
import { Formik, ErrorMessage, Form, Field } from 'formik';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import  { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { red } from "@mui/material/colors";
import * as yup from 'yup';
import wlogo from '../../images/waliiflogo.jpg';
//import wlogo from '../../images/waliifred.jpg';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";

const styles = {
  container: {
    height: '90vh',
    width: '90%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  auth: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    // backgroundColor: '#ffffff'
  },
  h1: {
    fontSize: 30,
    color: '#008080',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70%',
    height: '100%',
    flexDirection: 'row',
    borderWidth: 10,
    borderColor: '#4ca9ee',
    
  },
  logo: {
    width: '50%',
    height: '70%',
  },
  input: {
    padding: 10,
    width: '100%',
    border: 'none',
    borderRadius: 10,
    marginBottom: 10,
    marginTop: 10
  },
  button: {
    padding: 10,
    border: 'none',
    backgroundColor: '#008080',
    cursor: 'pointer',
    color: '#ffffff',
    width: '80%',
    borderRadius: 10
  }
}
const User = () => {
  const [theme, colorMode] = useMode();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const { currentUser, login, logout,err } = useContext(AuthContext);
  const [values, setValues] = useState({
    username: "",
    password: "",
  })

  const onSubmit = async (values, { setSubmitting }) => {
    try {
        setLoading(true);
        await login(values);
        if(currentUser) {
          navigate('/');
        setNotification({
            show: true,
            type: 'success',
            message: 'Login successful!'
        });
        setIsRedirecting(true);
        const timer = setTimeout(() => {
        }, 3000);
        return () => clearTimeout(timer);
      }
      else {
        setNotification({
          show: true,
          type: 'error',
          message: 'Login failed. Please try again .'
        });
      }
      setLoading(false);
    } catch (error) {
        setNotification({
            show: true,
            type: 'error',
            message: error
        });
    } finally {
        setSubmitting(false);
        setIsRedirecting(false);
    }
  };
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const validationSchema = yup.object({
    username: yup.string().required('Username cannot be empty.'),
    password: yup.string().required('Password cannot be empty.')
  })
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
    <div style={styles.container}>
      <div style={styles.auth}>
        <div style={styles.formContainer}>
          <Box sx={{display: {xs: 'none', sm: 'none', lg: 'block'}}} 
          style={styles.logo}>
            <img src={wlogo} style={{width: '100%', height: '100%'}} alt="Logo" />
          </Box>
          <Formik
            initialValues={{username: "", password: ""}}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ submitForm, isSubmitting }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingX: 5,
                backgroundColor: '#4ca9ee',
                width: {xs: '100%', sm: '100%', lg:'50%'},
                height: '70%',
                borderBottomRightRadius: '10px',
                borderTopRightRadius: '10px',
              }}
            >
              <Form style={{gap: 20,}}>
              <h1 style={styles.h1}> Login </h1>
              <Field style={styles.input} type='text' placeholder='username' id='username' name='username' />
              <ErrorMessage component='a' name='username' style={{color: 'red'}} />
              <Field style={styles.input} type='password' placeholder='password' id='password' name='password' />
              <ErrorMessage component='a' name='password' style={{color: 'red'}} />
              <div className='mt-8'>
                <button style={styles.input} id="submit-button" type='submit'  disabled={loading}>
                    Login
                </button>
              </div>
              {loading && (
      <Box mt={2} >
        <CircularProgress border={7}borderColor={red} />
      </Box>
    )}
                    {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}
                    </Form>
              {/* <p style={{color: 'red'}}>{err}</p> */}
            </Box>
            )}
          </Formik>
          {/* <PrintPage/> */}
        </div>
      </div>
    </div>
    </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default User;
