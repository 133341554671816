import React from "react";
import Layout from "./../components/Layout/Layout";
import { Link } from "react-router-dom";
import waliifTaxi from "../images/waliifTaxi.jpg";
import {Box,Button} from "@mui/material";
import axios from 'axios';

import "../styles/HomeStyles.css";
const Home = () => {

  
    const handleDownloaddriver = async () => {
      console.log('to download');
      try {
          const response = await axios.post('https://www.waliifride.com/auth/download/driver', {
              responseType: 'blob',
          });
  
          const url = window.URL.createObjectURL(new Blob([response.data]));
          
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'driver.apk');
  
          document.body.appendChild(link);
          link.click();
  
          link.parentNode.removeChild(link);
      } catch (error) {
          console.error('Error downloading the file', error);
      }
  };
  const handleDownloadrider = async () => {
    console.log('to download');
    try {
        const response = await axios.post('https://www.waliifride.com/auth/download/rider', {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'rider.apk');

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading the file', error);
    }
};
const handleDownloadagent = async () => {
  console.log('to download');
  try {
      const response = await axios.post('https://www.waliifride.com/auth/download/agent', {
          responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'agent.apk');

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
  } catch (error) {
      console.error('Error downloading the file', error);
  }
};
  
  
  return (
    <Layout>
      <div className="home" style={{ backgroundImage: `url(${waliifTaxi})`,  width:'100%', 
    height:'530px'}}>
        <div className="headerContainer" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '200px'}}>         
          <Box>
           <h2 marginTop='20px' style={{color: 'black'}}> Download Waliif Ride  App</h2>     
          {/* <p>Best Taxi In Ethiopia</p> */}
          
            <Button style={{marginLeft:'20px', width:250}} onClick={() => { handleDownloadrider()}}> Rider App </Button>
            <Button style={{marginLeft:'20px', width:250}} onClick={() => { handleDownloaddriver()}}> Driver App </Button>
            <Button style={{marginLeft:'20px', width:250}} onClick={() => { handleDownloadagent()}}> Agent App </Button>

          </Box>

        </div>
           
      </div>
    </Layout>
  );
};

export default Home;
