import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import Tab from '@mui/material/Tab';
import Mony from'@mui/icons-material/SaveAsRounded';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from '@mui/material/Button';
import {useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import Modal from "@mui/material/Modal"; 
import { blue } from "@mui/material/colors";
import { Input,Snackbar, Alert } from '@mui/material';
import { AuthContext } from "../../context/AuthContext";

const style3 = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 400, 
  borderColor: "#d50000", 
	p: 4, 
}; 
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx2={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
 function Driverdetailtransaction() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  let { id } = useParams();
  const { currentUser, url,logout} = useContext(AuthContext);
  const [preview, setPreview] = useState('');
  const isNonMobile = useMediaQuery("(min-width:600px)");
   const [trans, setTransaction] = useState([]);
  useEffect(() => {
  const fetchtransaction = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + `/api/transactions/byaccount/${id}`, { username: currentUser.username }, config);
      setTransaction(response.data);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};
fetchtransaction();
}, [currentUser.username, currentUser.accessToken]);
//


const [driver, setDrivers] = useState([]);
const [vehicle, setVehicle] = useState([]);

useEffect(() => {
  const fetchsetDrivers = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + `/api/transactions/driverinfo/${id}`, { username: currentUser.username }, config);
      setDrivers(response.data.driverinfo);
      setVehicle(response.data.vehicle)
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};

fetchsetDrivers();
}, [currentUser.username, currentUser.accessToken]);

  const [values, setValues] = useState({
    accountId: id,
    transactionmethod: "",
    username:currentUser.username,
    amaunt:""
    
  })

const [notification, setNotification] = useState(null);
const [isRedirecting, setIsRedirecting] = useState(false);

  const [open, setOpen] = React.useState(false); 
	const handleOpen = () => setOpen(true); 
	const handleClose = () => setOpen(false); 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function handleTransactions(e){
    e.preventDefault()
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
   
    axios.post(url+'/api/transactions/reg/byaccount', values,config)
    .then((res)=>{
        
        setOpen(false);
   
          setNotification({
            // vertical: 'top', horizontal: 'right',
            show: true,
            type: 'success',
            message: res.data
        });
        setIsRedirecting(true);
        const timer = setTimeout(() => {
          window.location.reload();
   
           }, 2000);
      
        return () => clearTimeout(timer);
      

    })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
}

}

  const handleChangeIndex = (index) => {
    setValue(index);
  };
 const columns = [
  // { field: "id", headerName: "ID" },
  {
    field: "id",
    headerName: "id",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  
  {
    field: "previoustotal",
    headerName: "previoustotal",
    // type: "number",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "transactionmethod",
    headerName: "T-method",
    flex: 1,
  },
  {
    field: "transactionhandler",
    headerName: "transactionhandler",
    flex: 3,
  },
  {
    field: "debitaccount",
    headerName: "debitaccount", filter:true,
    flex: 2,
  },
  {
    field: "creditaccount",
    headerName: "creditaccount",
    flex: 2,
  },
  {
    field: "amount",
    headerName: "amount",
    flex: 1,
  },
  {
    field: "commission",
    headerName: "commission",
    flex: 2,
    cellClassName: "name-column--cell",
  },
  {
    field: "vat",
    headerName: "vat",
    flex: 1,
  },
  {
    field: "newtotal",
    headerName: "newtotal",
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "createdAt",
    flex: 3,
  },

  {
    
    headerName: "Print",
    flex: 1,
    renderCell: ({ row: { id } }) => {
      return (
        <Box
          width="60%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
          backgroundColor={
              colors.blueAccent[600]
          }
          borderRadius="4px"
        >
          <Button type="submit">
            <PrintOutlinedIcon />
           </Button>
        </Box>
      );
    },
  },
   ];
//End for table transaction 

  return (
<Box m="20px"
    style={{height: '85%'}}
  >
  <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
  <AppBar position="static">
    <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		      backgroundColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
          >
      <Tab label="Transaction" {...a11yProps(1)} />
    </Tabs>
  </AppBar>
        <TabPanel value={value} index={3} dir={theme.direction}>

        </TabPanel>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>

            <div> 
		         	<div> 
               {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
         <Typography>Driver Full Name : {driver.firstname} {driver.middlename} {driver.lastname} Vehicle Information: Plate Number: { vehicle.platenumber}  Color: {vehicle.vehiclecolor} Model : {vehicle.vehiclemodel}
         </Typography>
	  		<Button type="submit" color="secondary" variant="contained" aria-describedby={open} onClick={handleOpen}>
        Deposit
              </Button> &nbsp;&nbsp;
					
			</div> 
			<Modal 
      
				open={open} 
				onClose={handleClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			> 
				<Box sx={style3} 
            backgroundColor={colors.grey[400]}
            gridRow="span 2" 
            marginRight={50}
            width={10}
        > 
					<Typography id="modal-modal-title"
						variant="h6" component="h2"> 
			  	Charge balance
					</Typography> 
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
						
        <form onSubmit={handleTransactions}>
            <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
            >
              <Grid   spacing={10}>
              <Grid item>
                {
            <FormControl sx={{ m: 1, minWidth: 120 }} >
            <InputLabel id="demo-select-small-label">Payment Method</InputLabel>
                   <Select name="transactionmethod"
                   size="small"
                   variant="filled"
                   style = {{width: 200}} 
                   label="Paymant Method"
                    onChange={(e)=> setValues({...values, transactionmethod: e.target.value})}
                   required           
                   sx={{ gridColumn: "span 3" }}
                   >
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Bank">Bank Transfer</MenuItem>
                    <MenuItem value="Telebirr">Telebirr</MenuItem>
                    <MenuItem value="M-Pessa">M-Pessa</MenuItem>
                   </Select>
                  </ FormControl>
                }
                </Grid>
                <Grid item>
               <TextField
                fullWidth
                variant="filled"
                type="number"
                label="amaunt"
                style = {{width: 200}} 
                onChange={(e)=> setValues({...values, amaunt: e.target.value})}
                required
                columns={2}
                name="amaunt"
                ></TextField>
                </Grid>
                </Grid>
               </Box>
              <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
              Save Changes
              </Button> &nbsp;&nbsp;
              <Button variant="secondary" onClick={handleClose} position="left">
            Close
          </Button>
            </Box>
               
            </form>
            
					</Typography> 
         
				</Box> 
        
			</Modal> 
		</div> 

      <DataGrid  rows={trans} columns={columns}   components={{ Toolbar: GridToolbar }} rowHeight={20} />

    </Box>
  </TabPanel>
  <TabPanel value={value} index={2} dir={theme.direction}>

  </TabPanel>
	<TabPanel value={value} index={3} dir={theme.direction}>
  
  </TabPanel>
	<TabPanel value={value} index={4} dir={theme.direction}>
  </TabPanel>

      {/* </SwipeableViews> */}
    </Box>
  </Box>
  );

};
export default Driverdetailtransaction;
