import * as React from 'react';
import { tokens } from "../../theme";
import { useTheme } from '@mui/material/styles';
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import { useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EyeIcon from "@mui/icons-material/OpenInFull";
import { DataGrid,GridToolbar } from "@mui/x-data-grid";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import Modal from "@mui/material/Modal"; 
import { useFormik } from 'formik';
import { Snackbar,Alert} from '@mui/material';
import { AuthContext } from "../../context/AuthContext";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const style2 = { 
	position: "absolute", 
	top: "40%", 
	left: "50%",
  height:"600", 
  transform: "translate(-50%, -50%)", 
	width: 700, 
  borderColor: "#d50000", 
	p: 1, 
};


const styleFile = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
}; 
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "60%",
  transform: "translate(-50%, -50%)", 
	width: 900, 
  height: 600, 

  borderColor: "#d50000", 
	p: 4, 
}; 

const DriverDoc = () => {
  const { currentUser, url ,logout} = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const navigate = useNavigate()
let { id } = useParams();


const [open, setOpen] = React.useState(false); 
const handleOpen = () => setOpen(true);
const handlecClose = () => setOpen(false);

const [open2, setOpen2] = React.useState(false);
const handleOpen2 = () => setOpen2(true);
const handlecClose2 = () => setOpen2(false);

const [openphoto, setoPenPhoto] = React.useState(false);
const handleOpenPhoto = () => setoPenPhoto(true);
const handleClosePhoto = () => setoPenPhoto(false);

  const handleClickOpen = () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
    
    axios.post(url+`/api/driversdocuments/confirm/${id}`, {username:currentUser.username},config)
  .then((res)=>{
    setOpen2(false)
  //  alert(res.data)
   setNotification({
    show: true,
    type: 'success',
    message: res.data 
  
});
setIsRedirecting(true);
const timer = setTimeout(() => {
  window.location.reload();

   }, 2000);

return () => clearTimeout(timer);

  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
  };

  // const handleDownloadpdf = (bb,title) => {
  //   const config = {
  //     headers: {
  //       'Authorization': `Bearer ${currentUser.accessToken}`
  //     },
  //     responseType: 'blob' // Ensure responseType is correctly set here
  //   };
  
  //   axios.post(`${url}/api/driversdocuments/download/${bb}`, { username: currentUser.username }, config)
  //     .then((res) => {
  //       // FileDownload(res.data, "download.jpg"); // Uncomment this line if using FileDownload library
  //       // Alternatively, use vanilla JavaScript to handle the download
        
  //       const url = window.URL.createObjectURL(new Blob([res.data]));
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', title+'_'+id+'.pdf');
  //       document.body.appendChild(link);
  //       link.click();

  //     })
  //     .catch((error) => {
  //       if (error.response && error.response.status === 401) {
  //         console.log('No valid token or session expired');
  //         logout(); // Assuming logout function exists to handle token expiration
  //       } else {
  //         console.error('Error downloading PDF:', error);
  //       }
  //     });
  // };
  
  
//

//
const handleDownloadpdf = (bb,title, path) => {
  const parts = path.split('.');
  const ext = parts[parts.length - 1];
  
  const config = {
    headers: {
      'Authorization': `Bearer ${currentUser.accessToken}`
    },
    responseType: 'blob' // Ensure responseType is correctly set here
  };
axios.post(`${url}/api/driversdocuments/download/${bb}`, { username: currentUser.username }, config)
  .then((res) => {
    // FileDownload(res.data, "download.jpg"); // Uncomment this line if using FileDownload library
    // Alternatively, use vanilla JavaScript to handle the download
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', title+'_'+id+'.'+ext);
    document.body.appendChild(link);
    link.click();
  })
  .catch((error) => {
    if (error.response && error.response.status === 401) {
      console.log('No valid token or session expired');
      logout(); // Assuming logout function exists to handle token expiration
    } else {
      console.error('Error downloading PDF:', error);
    }
  });
};

//
const [imageSrc, setImageSrc] = useState('');
const [extention2, setExtention] = useState('');
const [docIDD, setDoccIDD] = useState('');
const [doctitle, setdocTitle]=useState('');
const [docStatus, setdocStatus]=useState('');
const [docpath, setDocPath]=useState('');

const handleZoom = async (pp,path,title, Status) => {      

      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response =  await axios.post(url + `/api/driversdocuments/document/display/${pp}`, { username: currentUser.username }, config);
        // setVehic(response.data.vehicle);
        setImageSrc(response.data.image);
        setExtention(response.data.extentions)
        setDoccIDD(pp)
        setdocTitle(title)
        setDocPath(path)
        setdocStatus(Status)
        setoPenPhoto(true)
        setoPenPhoto(true);

      }catch (e) {
        // alert("jjj")
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };


// Approval

const [status, setApproval] = useState({
  
  username:currentUser.username,
  status:"",
})
function handleapproval(id){
  // alert(13131)
//  handleCloseregdetail(true)
try {
  const config = {
      headers: {
          'Authorization': `Bearer ${currentUser.accessToken}`
      }
  };
  if(docStatus=="Approved"){
    window.location.reload();
     setNotification({
      show: true,
      type: 'info',
      message: "The file is already approved!"
  });
  setIsRedirecting(true);
       const timer = setTimeout(() => {
       }, 2000);
       return () => clearTimeout(timer);
  }else{
  
axios.put(url+`/api/driversdocuments/doc/${id}`, status,config)
  .then((res)=>{
   setNotification({
    show: true,
    type: 'info',
    message: res.data
});
setIsRedirecting(true);

     const timer = setTimeout(() => {
      window.location.reload();

     }, 2000);
     return () => clearTimeout(timer);
  })
}
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
}

function removefile(id){
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };
  
  axios.post(url+`/api/driversdocuments/del/${id}`, {username:currentUser.username},config)
    .then((res)=>{
      setNotification({
        show: true,
        type: 'info',
        message: res.data
    });
    setIsRedirecting(true);
    
    const timer = setTimeout(() => {
      window.location.reload();
    }, 3000);
    return () => clearTimeout(timer);
    })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
}}
  //End Remove File

const [driverdoc, setDriverdoc] = useState([]);
useEffect(() => {
  const fetdoc = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + `/api/driversdocuments/doc/${id}`, { username: currentUser.username }, config);
      setDriverdoc(response.data);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};
fetdoc();
}, [currentUser.username, currentUser.accessToken]);

const formics = useFormik({
  initialValues: {
    path: '',
    title: '',
    remark: '',
    DriverId: id,
    status: "",
    username: currentUser.username
  },
  onSubmit: (values) => {
    const formData = new FormData();
    for (let key in values) {
      formData.append(key, values[key]);
    }
    try {
      const config = {
        headers: {
          'Authorization': `Bearer ${currentUser.accessToken}`
        }
      };

      axios.post(url + '/api/driversdocuments', formData, config).then((res) => {
        setOpen(false);
        setNotification({
          show: true,
          type: 'info',
          message: res.data
        });
        setIsRedirecting(true);
        const timer = setTimeout(() => {
          window.location.reload();
        }, 2000);
        return () => clearTimeout(timer);
      });
    } catch (e) {
      if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
      } else {
        console.error(e);
      }
    }
  },
});
const columndoc = [
  // { field: "id", headerName: "ID" },
  {
    field: "id",
    headerName: "id",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "title",
    headerName: "Document Title",
    flex: 3,
    cellClassName: "name-column--cell",
  },
  {
    field: "createdby",
    headerName: "Created by",
    // type: "number",
    headerAlign: "left",
    align: "left",
    flex: 2,
  },
  {
    field: "approvedby",
    headerName: "Approved by",
    // type: "number",
    headerAlign: "left",
    align: "left",
    flex: 2,
  },
  {
    field: "Status",
    headerName: "status",
    flex: 2,
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 2,
    
  },
  {
    
    headerName: "Detail",
    flex: 1,
    renderCell: ({ row: { id,title ,path,Status} }) => {
      return (
        Status!="Approved"?
        <Box

          backgroundColor={
              colors.redAccent[600]
          }
          borderRadius="4px"
        >
             <Button color ="error"onClick={ () => {handleZoom(id, path,title, Status)}}>
            Detail
             </Button>
        </Box>
        :
        <Box
        backgroundColor={
            colors.blueAccent[600]
        }
        borderRadius="4px"
      >
        
           <Button color ="warning"onClick={ () => {handleZoom(id, path,title)}}>
          Detail
           </Button>
      </Box>
      );
    },
  },
   ];

  return (
    
<Box m="20px"
    style2={{height: '85%'}}
    backgroundColor={colors.grey[400]}
  >

  <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[400]}
  
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
         
          sx={style2}
         width={500}
         // backgroundColor="#2979ff"
          >

  </Box>
  <Typography>
<Button type="submit" variant="contained" color="error"sx={{ m: 1 }} onClick={ () => {navigate(`/driver/${id}`)} }>Back Driver</Button>
<Button type="submit" color="secondary" variant='contained'sx={{ m: 1 }}onClick={handleOpen}>ADD File</Button>
<Button type="submit" color="info" variant='contained'sx={{ m: 1 }}onClick={handleOpen2}>Confirm Driver</Button></Typography>
 
 <Dialog
        open={open2}
        onClose={handlecClose2}

      >
        <DialogTitle backgroundColor={colors.grey[400]}>Confirmation Dialog</DialogTitle>
        <DialogContent backgroundColor={colors.grey[400]}>
          <DialogContentText>
          I confirm that the Driver documents listed below satisfy the requirements
           to provide service according to Waliif Transport Sc          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handlecClose2} color='error'variant="contained">Cancel</Button>
          <Button type="submit" onClick={handleClickOpen}color='secondary' variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
<Modal
      	open={open} 
				onClose={handlecClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>    
        <Box
      backgroundColor={colors.grey[400]}
      display="flex"
      gridRow="span 3"
      marginRight={50}
      width={20}
      sx={styleFile} 
    >
        <form className='formContainer' onSubmit={formics.handleSubmit} encType='multipart/form-data'>
     
     <div >
     <ListItem>
     <ListItemText  id="dis2"primary="File" />
    {/*  */}
<input type="file"  name="path"
             onChange={(e) => {
              let reader = new FileReader();
              reader.onload = () => {
                if (reader.readyState === 2) {
                    formics.setFieldValue('path', e.target.files[0])
                   }
               }
              reader.readAsDataURL(e.target.files[0]);
              }}
           />
{/*  */}

     </ListItem>
     
     <ListItem>
     <ListItemText primary="Title" />
       <FormControl sx={{m:1,
            '& .MuiInputBase-root': {
              height: 40, 
            },
          }}
          InputProps={{
            style: {
              padding: '0 14px', 
            },
          }}  
      >
                  <Select name="title"
               onChange={formics.handleChange}
                  value={formics.values.title}
                  size="small"
                  variant="filled"
                  required
                  sx={{ gridColumn: "span 2" }}
                  >
                   <MenuItem value="driverphoto">driverphoto</MenuItem>
                   <MenuItem value="drivinglicensefront">drivinglicensefront</MenuItem>
                   <MenuItem value="drivinglicenseback">drivinglicenseback</MenuItem>
                  </Select>
        </ FormControl>
     </ListItem>
     <ListItem>
     <ListItemText primary="Remark" />
       <TextField  name="remark"  
                 sx={{m:1,
                  '& .MuiInputBase-root': {
                    height: 40, 
                  },
                }}
                InputProps={{
                  style: {
                    padding: '0 14px', 
                  },
                }} 
       onChange={formics.handleChange}
       value={formics.values.remark}
       />
     </ListItem>
     {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
     <DatePicker />
   </LocalizationProvider>
   */}
   <Button type="submit" color="secondary" variant="contained" sx={{m:1}}>  Add File</Button>
   <Button  color="error" variant="contained" onClick={handlecClose}sx={{m:1}}>  close</Button>
   {/* <Button color="secondary" variant="contained" onClick={ () => {handlechangeprofile(`${driver.id}`)} }>  Change Profile</Button> */}
</div>
</form>
        </Box>
        
      </Modal>


      {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 
                    <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
               
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>
                 {/* <Button type="submit" onClick={ () => {navigate(`/driverdocument/${id}`)} }>document</Button> */}      
    
  <DataGrid rows={driverdoc} columns={columndoc} components={{ Toolbar: GridToolbar }}style={{ height: '100%', width: '100%' }} rowHeight={20}></DataGrid>
  <Modal
      	open={openphoto} 
				onClose={handleClosePhoto} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>    
      <Box
    backgroundColor={colors.grey[400]}
    display="flex"
    flexDirection="column" // Add this line to stack elements vertically
    gridRow="span 6"
    marginRight={50}
    width={100}
    sx={style}
  >
       {extention2==='.pdf'?
<Typography>The file is pdf please download and check before approve the Driver information</Typography> 
//  <PdfViewer src={imageSrc} width="300px" height="400px" />
:
    imageSrc && < img src={imageSrc} width="800px" height="500px" />}
        <Box
      gridRow="span 4"
      display="flex"
      flexDirection="column" // Add this line to stack elements vertically
    >
      <Typography>
        <TextField
        label="Manage Status"
          select
          name="approvalstatus"
          required
          onChange={(e) => setApproval({ ...status, status: e.target.value })}
          placeholder='waliif'
          sx={{m:1,
            '& .MuiInputBase-root': {
              height: 40, 
            },
          }}
          InputProps={{
            style: {
              padding: '0 14px', 
            },
          }} 
        >
          <MenuItem value="Approved">Approved</MenuItem>
          <MenuItem value="Reject">Reject</MenuItem>
        </TextField>
        <Button type="submit" onClick={ () => {handleapproval(docIDD) }} sx={{m:1,
            '& .MuiInputBase-root': {
              height: 40, 
            },
          }}
          InputProps={{
            style: {
              padding: '0 14px', 
            },
          }} >
           Apply
          </Button>
        <Button  variant=""color="secondary"onClick={() => { handleDownloadpdf(docIDD, doctitle, docpath) }}>
        <DownloadOutlinedIcon />
      </Button>  
      <Button type="submit" onClick={ () => {removefile(docIDD)} }>
        Remove
       </Button>
      <Button type="" color="error" onClick={handleClosePhoto}variant="contained">Close</Button>

      </Typography>
    </Box>
    </Box>
    </Modal>

</Box>

</Box>
   
);

};

export default DriverDoc;
