import { Box, Button, IconButton, Typography, useTheme, Label, Text } from "@mui/material";
import { tokens } from "../../theme";
// import { mockTransactions } from "../../data/mockData";
import TaxiIcon from "@mui/icons-material/LocalTaxi";
import People from "@mui/icons-material/PeopleOutlineRounded";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Payment from "@mui/icons-material/Payment";
// import Header from "../../components/Header";
// import LineChart from "../../components/LineChart";
import React, { useEffect, useState, useContext } from "react";
import Map from "../../components/Map";
import axios from 'axios';
import { AuthContext } from "../../context/AuthContext";
import {Link, useNavigate} from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LineChart, Line,CartesianGrid,BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';




const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { currentUser, accaunts, createacc,logout,url } = useContext(AuthContext);
  const navigate = useNavigate()
  const [riders, setRider] = useState([]); 
  const [rider, setRidernumber] = useState([]); 
  const [driverlist, setDriverList] = useState([]); 
  const [ordernum, setOrdernumber] = useState([]); 
  const [orderList, setOrderList] = useState([]); 

  

  


  const [driver, setDriver] = useState([]); 
  const [vehicle, setVehicle] = useState([]); 


  
 

const [transactions, setTransaction] = useState([]); 
  useEffect(() => {  
   const config = {
      headers: {
        'Authorization': `Bearer ${currentUser.accessToken}`
      }
    };
    
      axios.post(url+'/api/transactions/numberoftransactions',{username:currentUser.username}, config).then((response) => {
        setTransaction(response.data.transaction);
        setRidernumber(response.data.riders)
        setDriverList(response.data.drivers)
        setOrdernumber(response.data.orders)
        setOrderList(response.data.orderList)
        setRidernumber(response.data.riders)
        setVehicle(response.data.vehicle)
      }).catch(e => {
        console.log('No valid token or session expired');
        logout();
      });
      
}, []);  
const [reve, setRevenue] = useState([]);
useEffect(() => {
  handletransactionuser();
});
// useEffect(() => {
  const handletransactionuser = async () => {
    // x=2;
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + "/api/generalreport/report/employee/daily", { username: currentUser.username }, config);
      // setUserRep(response.data.transactionCounts);
      setRevenue(response.data.revenue) 
      // settrnshour(response.data.PERHOUR)
  
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
  }

const revenueLineChart = reve.map(item => ({
  name: item.createdAt,
  value: item.amaunt
}));


  return (
    <Box m="20px">
      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
      
        <Box
          gridColumn={{
            xs: 'span 12',
            sm: 'span 6',
            md: 'span 4',
            xl: 'span 2',
          }}          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >  <Button type="submit" onClick={ () => {navigate('/vehicles')}}>
          <Typography>
            
          <Button sx={{color: colors.greenAccent[600],fontSize: "26px"}}>  {vehicle}</Button>
            
              <TaxiIcon
                sx={{ color: colors.greenAccent[200], fontSize: "26px" }}
              />
            <br/>
            <Typography sx={{color: colors.greenAccent[600],fontSize: "12px"}}>vehicles</Typography>
            
          </Typography>
            </Button>
        </Box>
        <Box
          gridColumn={{
            xs: 'span 12',
            sm: 'span 6',
            md: 'span 4',
            xl: 'span 2',
          }}          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button type="submit" onClick={ () => {navigate('/drivers')}}>
          <Button sx={{color: colors.greenAccent[600],fontSize: "26px"}}>  {driverlist}</Button>
              <People
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            <br/>
            <Typography sx={{color: colors.greenAccent[600],fontSize: "12px"}}>Drivers</Typography>
          </Button>
        </Box>
        <Box
          gridColumn={{
            xs: 'span 12',
            sm: 'span 6',
            md: 'span 4',
            xl: 'span 2',
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
        <Button type="submit" onClick={ () => {navigate('/riders')}}>

        <Button sx={{color: colors.greenAccent[600],fontSize: "26px"}}>  {ordernum}</Button>
           {
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }<br/>
            <Typography sx={{color: colors.greenAccent[600],fontSize: "12px"}}>Riders</Typography>
        
        
        </Button>
        </Box>
        <Box
          gridColumn={{
            xs: 'span 12',
            sm: 'span 6',
            md: 'span 4',
            xl: 'span 2',
          }}          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
      <Button type="submit" onClick={ () => {navigate('/orders')}}>

      <Button sx={{color: colors.greenAccent[600],fontSize: "26px"}}>  {rider}</Button>
           
             
      <Button sx={{color: colors.greenAccent[600],fontSize: "12px"}}>   Orders</Button>
      
            
    
          
          </Button>
        </Box>
        <Box
          gridColumn={{
            xs: 'span 12',
            sm: 'span 6',
            md: 'span 4',
            xl: 'span 2',
          }}          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
      <Button type="submit" onClick={ () => {navigate('/transactions')}}>

      <Button sx={{color: colors.greenAccent[600],fontSize: "26px"}}>   {transactions}</Button>

                       
      <Button sx={{color: colors.greenAccent[600],fontSize: "12px"}}>   Transaction</Button>
          </Button>
        </Box>
        <Box
          gridColumn={{
            xs: 'span 12',
            sm: 'span 6',
            md: 'span 4',
            xl: 'span 2',
          }}          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
      <Button type="submit" onClick={ () => {navigate('/transactions')}}>

      <Button sx={{color: colors.redAccent[600],fontSize: "12px"}}>   Failed</Button>
      
            
           
         
        
          </Button>
        </Box>
        {/* ROW 2 */}
        <Box
     gridColumn= {{
      xs: 'span 12',
      sm: 'span 8',
      md: "span 8"
    }}        
      gridRow="span 3"
      backgroundColor={colors.primary[400]}
        >  
       <Box
            backgroundColor={colors.primary[400]}
            alignItems="center"
            justifyContent="center"
            gridRow="span 2"
          >
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={revenueLineChart}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 5 }} />
              </LineChart>
            </ResponsiveContainer>
            <Typography variant="h6" style={{ fontFamily: 'Arial, sans-serif' }}>
              Transaction Value
            </Typography>
          </Box>
     
        </Box>
        <Box
         gridColumn= {{
          xs: 'span 12',
          sm: 'span 8',
          md: "span 4"
        }}        
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Order List 
             </Typography>
          </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  Vehi-plate 
                  Driver Phone
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>riderphone</Box>

              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                Status
              </Box>
        
</Box>




          {orderList.map((ord, i) => (
            <Box
              key={`${ord.id}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {ord.platenumber} --
                  {ord.driverphone} 
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{ord.riderphone}</Box>
              { ord.status=="success"?

              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {ord.status}
              </Box>
              :
              <Box
              backgroundColor={colors.redAccent[500]}
              p="5px 10px"
              borderRadius="4px"
            >
              {ord.status}
            </Box>
            }
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
       
       {/*  */}
        {/* </Box>  */}
      </Box>
    </Box>
  );
};

export default Dashboard;
