// import * as React from 'react';
import { blue, red } from "@mui/material/colors";
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { tokens } from "../../theme";
// import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link, useNavigate} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridToolbar , GridRemoveIcon, GridAddIcon ,ColDef} from "@mui/x-data-grid";
import { Box, FormControl,Snackbar,Alert, Select, MenuItem, ListItem, ListItemText,Typography, useTheme,Button,TextField, Checkbox} from "@mui/material";
import  { useEffect, useState } from "react";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions, { dialogActionsClasses } from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import {Snackbar, Alert }from '@mui/material';
import { useFormik, Formik, ErrorMessage,  Form, Field } from 'formik';
import Modal from "@mui/material/Modal"; 
import { AuthContext } from '../../context/AuthContext';
import * as yup from 'yup';


const style3 = { 
	position: "absolute", 
	top: "50%", 
	left: "60%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 800, 
  borderColor: "#d50000", 
	p: 4, 
}; 
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
}; 
const styles = {
  container: {
    height: '90%',
    width: '90%',
    alignItems: 'center',
  },
  auth: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    // backgroundColor: '#ffffff'
  },
  h1: {
    fontSize: 30,
    color: '#008080',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    height: '100%',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#4ca9ee',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    backgroundColor:"BDBDBD",
    width: '60%',
    height: '70%',
    gap: 20,
  },
  logo: {
    width: '40%',
    height: '70%'
  },
  input: {
    padding: 10,
    width: '80%',
    border: 'none',
    borderRadius: 10,
  },
  button: {
    padding: 10,
    border: 'none',
    backgroundColor: '#008080',
    cursor: 'pointer',
    color: '#ffffff',
    width: '40%',
    // marginTop: 10,
    borderRadius: 10
  }
}



function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      style={{width: '100%'}}
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
 function Register() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  

  
const [open2, setOpen2] = React.useState(false);
const handleOpen2 = () => setOpen2(true);
const handlecClose2 = () => setOpen2(false);

const [open3, setStatus] = React.useState(false);
const statusHandle = () => setStatus(true);
const statusClose = () => setStatus(false);
  
   
  const [open, setOpen] = React.useState(false); 
	const handleOpen = () => setOpen(true);
  const handleClosereg = () => setOpen(false);
  const navigate = useNavigate()
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const { currentUser, logout,url} = useContext(AuthContext);
  
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);


  //create Mapping and Its  Modal
const [openMapping, setOpenMapping] = React.useState(false); 
const handleOpenMapping = () => setOpenMapping(true);
const handleCloseMapping = () => setOpenMapping(false);
 
const [accessmapping, setMapping] = useState([]);

const formikMapping = useFormik({
  initialValues: {
    requestedfor:"",
    accesslevel:"",
    actions:"",
    objects:"",
    grant:"",
    description:"",
    username:currentUser.username,
  }, onSubmit: (values) => {
    const abac = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };

     axios.post(url+'/api/abac/reg', values,config).then((res) => {
      setMapping(accessmapping.concat(res.data));
      setOpenMapping(false);
      setNotification({
        show: true,
        type: 'success',
        message: res.data 
      
    });
      // alert(res.data)
      setIsRedirecting(true);
      const timer = setTimeout(() => {
        window.location.reload();

         }, 2000);
    
      return () => clearTimeout(timer);
      
    })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
}
};

abac();
  },
  });


//End create Mapping and Its  Modal

//Object
const [openobject, setopenobject] = React.useState(false); 
const handleOpenobject = () => setopenobject(true);
const handleCloseobject = () => setopenobject(false);
const formikObject = useFormik({
  initialValues: {
    objectname:"",
    approvedby:"",
    refno:"",
    description:"",
    username:currentUser.username,
  }, onSubmit: (values) => {
    const registerobject = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };

     axios.post(url+'/api/objects/register', values,config).then((res) => {
      // setRoleReg(roleReg.concat(res.data));

      // alert(res.data)
      setopenobject(false);
      setNotification({
        show: true,
        type: 'success',
        message: res.data 
      
    });
    setIsRedirecting(true);
    const timer = setTimeout(() => {
      window.location.reload();

       }, 2000);
  
    return () => clearTimeout(timer);
    });
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
}
};

registerobject();
  },
  });

//End Object

//Role Create function and its modal management

const [openrole, setopenrole] = React.useState(false); 
const handleOpenrole = () => setopenrole(true);
const handleCloserole = () => setopenrole(false);

const [roleReg, setRoleReg] = useState([]);

const formikRole = useFormik({
  initialValues: {
    rolename:"",
    approvedby:"",
    refno:"",
    description:"",
    username:currentUser.username,
  }, onSubmit: (values) => {
    const registerroles = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };

     axios.post(url+'/api/roles/register', values,config).then((res) => {
      // setRoleReg(roleReg.concat(res.data));
      // alert(res.data)
      setopenrole(false);
      setNotification({
        show: true,
        type: 'success',
        message: res.data 
      
    });
    setIsRedirecting(true);
    const timer = setTimeout(() => {
      window.location.reload();

       }, 2000);
  
    return () => clearTimeout(timer);
    });
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
}
};

registerroles();
  },
  });
//End Role create function and its Modal management


//userregistration
const [eror, setError] = useState([""]);

const [userReg, setUserReg] = useState([]);
const formik = useFormik({
  initialValues: {
  firstname: "",
  middlename: "",
  lastname: "",
  email: "",
  phonenumber: "",
  approver:"",
  usertype:"",
  username:currentUser.username,
  }, onSubmit: (values) => {
    if(values.password.length<6){
      setError("The password cannot be less than 6 character")
    } else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,64}$/.test(values.password)) {
      setError("Please make your password strong")
    }else{
      setError("strong")
      const registeruser = async () => {
        try {
          const config = {
              headers: {
                  'Authorization': `Bearer ${currentUser.accessToken}`
              }
          };
    axios.post(url+"/auth/register/users/in", values,config).then((res) => {
      // userReg(userReg.concat(res.data));
      // alert(res.data)
      setOpen(false);
      setNotification({
        show: true,
        type: 'success',
        message: res.data 
      
    });
    setIsRedirecting(true);
    const timer = setTimeout(() => {
      window.location.reload();
 
       }, 2000);
  
    return () => clearTimeout(timer);
    });
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
}
};

registeruser();
  }
  },
  

  });
    
//end userregistration










  //  const onSubmit = async (values) => {
  //   register(values);
  //   alert("Object is created Succusfully")
  // }
  
  const validationSchema = yup.object({
    username: yup.string().required('Username cannot be empty.'),
    password: yup.string().required('Password cannot be empty.'),
    firstname:yup.string().required('first name cannot be emty'),
    middlename:yup.string().required("middle name cannot be emty"),
    lastname:yup.string().required("last name cannot be emty"),
    email:yup.string().required(" Email cannot be emty"),
    phonenumber:yup.string().required(" Phone number cannot be emty"),
  })
  const validationSchemarole = yup.object({
    ob: yup.string().required('Object cannot be empty.'),
    uid: yup.string().required('UserId cannot be empty.'),
    action:yup.string().required('Action cannot be emty'),
    ref:yup.string().required("Referance number cannot be emty"),
    // regby:yup.string().required("No current User log in"),
  })
  const objvalidation = yup.object({
    name: yup.string().required('Object cannot be empty.'),
    refno: yup.string().required('Refno cannot be empty.'),
  })
  const objvalidationa = yup.object({
    namea: yup.string().required('Object cannot be empty.'),
    refnoa: yup.string().required('Refno cannot be empty.'),
  })
//Fetch User List
  const [users, setUsers] = useState([]);
 



  useEffect(() => {
    const fetchuser = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/auth/all', { username: currentUser.username }, config);
        setUsers(response.data);
            }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchuser();
}, [currentUser.username, currentUser.accessToken]);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchsetRoles = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/roles/all', { username: currentUser.username }, config);
        setRoles(response.data);
            }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchsetRoles();
}, [currentUser.username, currentUser.accessToken]);


  const [obje, setObject] = useState([]);


  useEffect(() => {
    const fetchsetObject = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/objects/all', { username: currentUser.username }, config);
        setObject(response.data);
            }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchsetObject();
}, [currentUser.username, currentUser.accessToken]);


  const [actiondata, setAcc] = useState([]);


  useEffect(() => {
    const fetchsetAcc = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/actionss/all', { username: currentUser.username }, config);
        setAcc(response.data);
            }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchsetAcc();
}, [currentUser.username, currentUser.accessToken]);

  const [record, setRecord] = useState([]);

  useEffect(() => {
    const fetchsetRecord = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/log/all', { username: currentUser.username }, config);
        setRecord(response.data);
            }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchsetRecord();
}, [currentUser.username, currentUser.accessToken]);

  const [pre, setPre] = useState([]);
  
    
  useEffect(() => {
    const fetchsetPre = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/abac/all', { username: currentUser.username }, config);
        setPre(response.data);
            }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchsetPre();
}, [currentUser.username, currentUser.accessToken]);



const [loading, setLoading] = React.useState(false);

const [psw, setpsw] = useState({
  
  username:currentUser.username,
  
  resetusername:"",
})
function handlepswchange(e){
  e.preventDefault()
  setLoading(true);
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };


axios.post(url+`/auth/pass/reset`, psw,config)
  .then((res)=>{
    setOpen(false)
    setNotification({
      show: true,
      type: 'info',
      message: res.data
    
  });
  setTimeout(() => {
    setLoading(false);
  });
        // console.log(res);
        setIsRedirecting(true);
        const timer = setTimeout(() => {
          window.location.reload();

           }, 2000);
      
        return () => clearTimeout(timer);
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
}

//handle to change users status


const [sta, setUsersStatus] = useState({
  username:currentUser.username,
  usertochange:"",
  changedtostatus:""

})
function handlechangeuserstatus(e){
  e.preventDefault()
  // alert(sta.usertochange)
  // setLoading(true);

  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };


axios.post(url+`/auth/changeuser/status`, sta,config)
  .then((res)=>{
    setOpen(false)
    setNotification({
      show: true,
      type: 'info',
      message: res.data
    
  });
  setTimeout(() => {
    setLoading(false);
  });
        // console.log(res);
        setIsRedirecting(true);
        const timer = setTimeout(() => {
          window.location.reload();

           }, 2000);
      
        return () => clearTimeout(timer);
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
}

//end to handle change users status




  
  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   flex: 1,
    //   cellClassName: "name-column--cell",
    // },
    {
      field: "firstname",
      headerName: "FName",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "middlename",
      headerName: "MName",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex:1
    },
    {
      field: "lastname",
      headerName: "LName",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "phonenumber",
      headerName: "contact", filter:true,
      flex: 1,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 2,
    },
    {
      field: "accesslevel",
      headerName: "Position",
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
     flex:1,
    
    },    
    {
      field: "Detail",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box

            backgroundColor={
                colors.blueAccent[600]
            }
            borderRadius="4px"
          >
            {/* <Button type="submit" onClick={ () => {navigate(`/riders/${id}`)} }>
              <AdminPanelSettingsOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              </Typography>
            </Button> */}
          </Box>
        );
      },
    },
  ];
  //Object Column
  const objectcol = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "objname",
      headerName: "Object Name",
      flex: 3,
      cellClassName: "name-column--cell",
    },
    {
      field: "refno",
      headerName: "Ref Number",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 3,
    },
    {
      field: "approvedby",
      headerName: "approvedby",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
    },
    {
      field: "createdAt",
      headerName: "CreatedDate",
      flex: 3,
    },
    {
      field: "updatedAt",
      headerName: "UpdatedDate", 
      //filter:true,
      flex: 3,
    },    
    {
      field: "Detail",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
                colors.greenAccent[600]
            }
            borderRadius="4px"
          >
            {/* <Button type="submit" onClick={ () => {navigate(`/riders/${id}`)} }>
              <AdminPanelSettingsOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              </Typography>
            </Button> */}
          </Box>
        );
      },
    },
  ];
  const rolecol = [
    {
      field: "id",
      headerName: "SN",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "rolename",
      headerName: "Role Name",
      flex: 3,
      cellClassName: "name-column--cell",
    },
    {
      field: "createdby",
      headerName: "Createdby",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "approvedby",
      headerName: "approvedby",
      flex: 2,
    },
    {
      field: "refno",
      headerName: "refno",
      flex: 3,
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
    },
    {
      field: "description",
      headerName: "description",
      flex: 3,
    },
    {
      field: "createdAt",
      headerName: "CreatedDate",
      flex: 3,
    },
    {
      field: "updatedAt",
      headerName: "UpdatedDate", 
      //filter:true,
      flex: 3,
    },    
  ];

  const actioncol = [
    {
      field: "id",
      headerName: "SN",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "actnname",
      headerName: "Action Name",
      flex: 3,
      cellClassName: "name-column--cell",
    },
    {
      field: "refno",
      headerName: "Ref Number",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    
    {
      field: "registeredby",
      headerName: "Registered by",
      flex: 3,
    },
    {
      field: "approvedby",
      headerName: "approvedby",
      flex: 3,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
    },
    {
      field: "createdAt",
      headerName: "createAt",
      flex: 3,
    },
    {
      field: "updatedAt",
      headerName: "UpdatedDate", 
      //filter:true,
      flex: 3,
    },    
      
    
  ];

  const priviledge = [
    {
      field: "id",
      headerName: "SN",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "roleId",
      headerName: "RoleID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "objectId",
      headerName: "ObjectId",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    
    {
      field: "actionId",
      headerName: "ActionId",
      flex: 1,
    },
    {
      field: "userId",
      headerName: "UserId",
      flex: 3,
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "createAt",
      flex: 3,
    },
    {
      field: "updatedAt",
      headerName: "UpdatedDate", 
      //filter:true,
      flex: 3,
    },    
    ];
  const userlogs = [

    {
      field: "username",
      headerName: "username",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "url",
      headerName: "address",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    
    {
      field: "action",
      headerName: "action ",
      flex: 3,
    },
    {
      field: "types",
      headerName: "types",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
     
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: {id} }) => {
        return (
           <Box
  
          backgroundColor={
              colors.blueAccent[100]
          }
          borderRadius="4px"
        >
          <Button type="submit" onClick={ () => {navigate(`/Userlogdetail/${id}`)} }              
           sx={{
                fontSize: '8px',
                padding: '6px 10px',
              }}>
           Detail
          </Button>
          
        </Box>
        );
      },
    },
   
      
  ];



  return (
    <Box m="20px"
      style={{height: '85%'}}
    >
    <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
    		  backgroundColor="dark"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
        >
          <Tab label="User List" {...a11yProps(0)} />
          {currentUser.accesslevel=="admin" &&
          <Tab label="Manage role" {...a11yProps(1)} />
          }
          {currentUser.accesslevel=="admin" &&
          <Tab label="Manage Object" {...a11yProps(2)} />
          }
          {currentUser.accesslevel=="admin" &&
	    	  <Tab label="Manage Action" {...a11yProps(3)} />
        }
        {currentUser.accesslevel=="admin" &&
    		  <Tab label="Mapping" {...a11yProps(4)} />
      }{currentUser.accesslevel=="admin" &&
  		    <Tab label="UserLog" {...a11yProps(5)} />
    }
		      {/* <Tab label="Agents" {...a11yProps(6)} /> */}
  
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Box m="20px">
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
     {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
       
        <Box display="flex" justifyContent="end" mt="20px" hidden>
        <Button aria-describedby={open} onClick={handleOpen} variant="contained" color="secondary" sx={{ m: 1 }}> 
					Add User
				</Button>&nbsp;
        {currentUser.accesslevel === 'Super Admin' ||currentUser.accesslevel === 'admin' ?
   
        <Button type="submit" color="info" variant='contained'sx={{ m: 1 }}onClick={handleOpen2}>manage users password</Button>

:null}
 <Button type="submit" color="info" variant='contained'sx={{ m: 1 }}onClick={statusHandle}>manage users status</Button>

        </Box>


        
    
    <Modal id="register"
          // backgroundColor="seagreen "
          // sx={style1} 
            open={open} 
            onClose={handleClosereg} 
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          > 
			  	 <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[400]}
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
          width={10}
          sx={style3}
         // backgroundColor="#2979ff"
          >
					<Typography id="modal-modal-description"
          sx={{ mt: 2 }}
						> 
            {/* <form onSubmit={handleSubmit}> */}
         <Formik
            initialValues={{firstname: "", middlename: "",lastname:"",phonenumber:"", email:"",balance:"",password:"", accesslevel:"",username:currentUser.username, approver:""}}
            // validationSchema={registerSchema}
            onSubmit={onsubmit}
           >
            <form className='formContainer' onSubmit={formik.handleSubmit} encType='multipart/form-data'>
                    <ListItem  >
                      <TextField  
                        fullWidth
                        label="First Name"
                          variant="filled"
                          style = {{width: 400}}
                          onChange={formik.handleChange}
                          value={formik.values.firstname}
                          name="firstname"
                          size="small"
                        />
              </ListItem><ListItem>
                      <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="text"
                          label="Middle Name"
                          size="small"
                          onChange={formik.handleChange}
                          value={formik.values.middlename}
                          required
                          name="middlename"
                          
                          />
                    </ListItem>
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="Last Name"
                          onChange={formik.handleChange}
                          value={formik.values.lastname}
                          required
                          name="lastname"
                         
                          >
                      </TextField>
                      </ListItem>
                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="email"
                          label="Email Address"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          required
                          size="small"
                          name="email"
                          ></TextField>
                      </ListItem>
                     <ListItem>
                         <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          label="Phone Number"
                          size="small"
                          pattern="[1-9]*"
                          inputProps={{
                            maxLength: 9,
                            minWidth:9
                          }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          helperText="Please Enter exactly nine digits,exampt 0 "
                          
                          onChange={formik.handleChange}
                          value={formik.values.phonenumber}
                          required
                          name="phonenumber"
                          ></TextField>
                        </ListItem>
                        <ListItem>
                        <TextField
                          fullWidth
                          label="Password"
                          style = {{width: 400}}
                          variant="filled"
                          type="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          required
                          name="password"
                          size="small"
                          helperText={eror}
                         
                          ></TextField>
                      </ListItem>
                       
                     <ListItem>
                     <ListItemText primary="Register As "  size="small"  sx={{ gridColumn: "span 2" }}/>
                 <FormControl  size="small" Label="Registered As">
                       <Select name="usertype"
                       style = {{width: 100}}
                            onChange={formik.handleChange}
                              value={formik.values.usertype}
                              size="small"
                              variant="filled"
                              required
                                id="dis6"
                              sx={{ gridColumn: "span 2" }}
                              >
                                <MenuItem  value="Staff">
                              Staff</MenuItem>
                              <MenuItem  value="Agent">
                              Agent</MenuItem>
                         </Select>
                  </ FormControl>

               <ListItemText primary="Approver  "  size="small"  sx={{ gridColumn: "span 2" }}/>
                   <FormControl  size="small" >
                       <Select name="approver"
                            onChange={formik.handleChange}
                              value={formik.values.approver}
                              size="small"
                              variant="filled"
                              required
                                id="dis6"
                                style = {{width: 100}}
                              
                              >
                                  {users.map(user => (
                                <MenuItem id={user.id} value={user.username}>
                                {user.firstname} {user.middlename}</MenuItem>))}
                          
                        </Select>
                  </ FormControl>
               </ListItem>
               <ListItem>
               <ListItemText primary="Accesslevel  "  size="small"  sx={{ gridColumn: "span 2" }}/>
                   <FormControl size="small"  id="dis5">
                       <Select name="accesslevel"
                            onChange={formik.handleChange}
                              value={formik.values.accesslevel}
                              size="small"
                              variant="filled"
                              required
                                id="dis6"
                              sx={{ gridColumn: "span 2" }}
                              >
                              {roles.map(label => (
                             <MenuItem id={label.id} value={label.rolename}>
                                {label.rolename}
                             </MenuItem>
              ))}
                        </Select>
                  </ FormControl>
               </ListItem>
                        <Button type="submit" color="secondary" variant="contained" onClick={onsubmit}>
                         Add User
                        </Button>&nbsp; 
                        <Button   onClick={handleClosereg} position="left" variant="contained"color="warning">
                          Close
                        </Button>
                      
              </form>
            </Formik>
					</Typography> 
        </Box> 
     	</Modal> 
       <Dialog
        open={open3}
        onClose={statusClose}
       
      >

      <DialogTitle backgroundColor={colors.grey[400]}>Force Change Usters Status</DialogTitle>
        <DialogContent backgroundColor={colors.grey[400]}>
          <DialogContentText>
<Typography> Change user status Force</Typography>

          <TextField 
                     onChange={(e)=> setUsersStatus({...sta, usertochange: e.target.value})}

          size="small"name="usertochange"  fullWidth  required  label ="username" 
          //  sx={{ width: 200, height: 50 ,m: 1}}
           style = {{width: 400}}

          //  onChange={(e)=> setUsersStatus({...sta, usertochange: e.target.value})}

  />       
  {/* <TextField name="forcestatus" onChange={(e)=> setpsw({...psw, resetusername: e.target.value})} select
  
> */}

<TextField  
                        fullWidth
                        label="Status"
                          variant="filled"
                          select
                          style = {{width: 400}}
                          onChange={(e)=> setUsersStatus({...sta, changedtostatus: e.target.value})}
                          name="changedtostatus"
                          size="small"
                          required
                        >
  <MenuItem value="offline">OFF LINE</MenuItem>
  <MenuItem value="locked">LOCKED</MenuItem>
  <MenuItem value="inactive"> INACTIVE</MenuItem>
  <MenuItem value="fraud">FRAUD</MenuItem>
  </TextField>
          </DialogContentText>

        </DialogContent>
      <DialogActions>
          <Button onClick={statusClose} color='error'variant="contained">Cancel</Button>
          <Button type="submit" color='secondary' onClick={handlechangeuserstatus}disabled={loading}
                        // onClick={handleClick}
                        endIcon={loading && <CircularProgress size={24} />}
                        variant="contained">
                          {loading ? 'Loading...' : 'Reset'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={handlecClose2}
       
      >

      <DialogTitle backgroundColor={colors.grey[400]}>Password Reset Dialog</DialogTitle>
        <DialogContent backgroundColor={colors.grey[400]}>
          <DialogContentText>

          <TextField  size="small"name="resetusername"   required  label ="username" 
           sx={{ width: 200, height: 50 ,m: 1}}
           onChange={(e)=> setpsw({...psw, resetusername: e.target.value})}
                        
    
  />       
          </DialogContentText>

        </DialogContent>
      <DialogActions>
          <Button onClick={handlecClose2} color='error'variant="contained">Cancel</Button>
          <Button type="submit" color='secondary' onClick={handlepswchange}disabled={loading}
                        // onClick={handleClick}
                        endIcon={loading && <CircularProgress size={24} />}
                        variant="contained">
                          {loading ? 'Loading...' : 'Reset'}</Button>
        </DialogActions>
      </Dialog>
            <DataGrid style={{height: '85%', width: '100%'}} rows={users} columns={columns}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
      {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
       
      <Modal
      	open={openrole} 
				onClose={handleCloserole} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
       <div >
       <Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 3"
        marginRight={50}
        width={20}
        sx={style} 
      >
     <Formik
          initialValues={{rolename: "", approvedby: "",refno:"", description:""}}
          // validationSchema={validationSchemarole}
          onSubmit={formikRole.onSubmit}
        >
         <form className='formContainer' onSubmit={formikRole.handleSubmit} encType='multipart/form-data'>
                    <ListItem  >
                      <TextField  
                        fullWidth
                        label="Role Name"
                          variant="filled"
                          style = {{width: 400}}
                          onChange={formikRole.handleChange}
                          value={formikRole.values.rolename}
                          name="rolename"
                          size="small"
                          required
                        />
              </ListItem><ListItem>
                      <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="text"
                          label="Approved By"
                          size="small"
                          onChange={formikRole.handleChange}
                          value={formikRole.values.approvedby}
                          required
                          name="approvedby"
                          
                          />
                    </ListItem>
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="Referance Code"
                          onChange={formikRole.handleChange}
                          value={formikRole.values.refno}
                          required
                          name="refno"
                         
                          >
                      </TextField>
                      </ListItem>
                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Description"
                          onChange={formikRole.handleChange}
                          value={formikRole.values.description}
                          required
                          size="small"
                          name="description"
                          ></TextField>
                      </ListItem>
            
            
            <Box display="flex"  mt="20px">
            <Button type='submit' color="secondary"  variant="contained"  onClick={formikRole.onSubmit}> Create Role</Button>
            &nbsp;&nbsp;&nbsp; 
                   <Button type="submit" color="error" onClick={handleCloserole}variant="contained">
                           Close
                        </Button> &nbsp;&nbsp;&nbsp;
                     </Box>
            {/* <button color="warning"onClick={handleClosereg} >Close</button> */}
          </form>
        </Formik>
      </Box>
    </div> 
    </Modal>
    <Box display="flex" justifyContent="end" mt="20px" hidden>
        <Button aria-describedby={open} onClick={handleOpenrole} variant="contained" color="secondary"> 
					Add
				</Button>&nbsp;
        </Box>
        <Box
            m="40px 0 0 0"
            height="60vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
           >
            <DataGrid style={{height: '88%', width: '100%'}} rows={roles} columns={rolecol}  components={{ Toolbar: GridToolbar }}  rowHeight={20} />
        </Box>   

      </TabPanel>
      
      <TabPanel value={value} index={2} dir={theme.direction}>
      {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
       
      <Modal
      	open={openobject} 
				onClose={handleCloseobject} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
       <div >
       <Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 3"
        marginRight={50}
        width={20}
        sx={style} 
      >
     <Formik
          initialValues={{objectname: "", approvedby: "",refno:"", description:""}}
          // validationSchema={validationSchemarole}
          onSubmit={formikObject.onSubmit}
        >
         <form className='formContainer' onSubmit={formikObject.handleSubmit} encType='multipart/form-data'>
                    <ListItem  >
                      <TextField  
                        fullWidth
                        label="Object Name"
                          variant="filled"
                          select
                          style = {{width: 400}}
                          onChange={formikObject.handleChange}
                          value={formikObject.values.objectname}
                          name="objectname"
                          size="small"
                          required
                        >
                          <MenuItem value="abacs"> abacs</MenuItem>
                          <MenuItem value="accounts"> accounts</MenuItem>
                          <MenuItem value="actions"> actions</MenuItem>
                          <MenuItem value="chargerequests"> chargerequests</MenuItem>
                          <MenuItem value="drivers"> drivers</MenuItem>
                          <MenuItem value="driversdocuments"> driversdocuments</MenuItem>
                          <MenuItem value="emptransactions"> emptransactions</MenuItem>
                          <MenuItem value="feedbacks"> feedbacks</MenuItem>
                          <MenuItem value="filters"> filters</MenuItem>
                          <MenuItem value="objects"> objects</MenuItem>
                          <MenuItem value="orders"> orders</MenuItem>
                          <MenuItem value="permissions"> permissions</MenuItem>
                          <MenuItem value="revenues"> revenues</MenuItem>
                          <MenuItem value="riders"> riders</MenuItem>
                          <MenuItem value="ridersdocuments"> ridersdocuments</MenuItem>
                          <MenuItem value="roles"> roles</MenuItem>
                          <MenuItem value="settings"> settings</MenuItem>
                          <MenuItem value="profile">profile</MenuItem>
                          <MenuItem value="appoveLevel1">appoveLevel1</MenuItem>
                          <MenuItem value="collect cash">collect cash</MenuItem>
                          <MenuItem value="settings">settings</MenuItem>
                          <MenuItem value="log">log</MenuItem> 
                          <MenuItem value="tariffs">tariffs</MenuItem> 
                          <MenuItem value="drivings">drivings</MenuItem> 
                          <MenuItem value="commissions">commissions</MenuItem> 

                          
                        </TextField>
              </ListItem><ListItem>
                      <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="text"
                          label="Approved By"
                          size="small"
                          onChange={formikObject.handleChange}
                          value={formikObject.values.approvedby}
                          required
                          name="approvedby"
                          
                          />
                    </ListItem>
                    <ListItem>
                      <TextField
                          fullWidth
                          variant="filled"
                          style = {{width: 400}}
                          type="text"
                          size="small"
                          label="Referance Code"
                          onChange={formikObject.handleChange}
                          value={formikObject.values.refno}
                          required
                          name="refno"
                         
                          >
                      </TextField>
                      </ListItem>
                    <ListItem>
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Description"
                          onChange={formikObject.handleChange}
                          value={formikObject.values.description}
                          required
                          size="small"
                          name="description"
                          ></TextField>
                      </ListItem>
            
            
            <Box display="flex"  mt="20px">
            <Button type='submit' color="secondary"  variant="contained"  onClick={formikObject.onSubmit}> Create Objects</Button>
            &nbsp;&nbsp;&nbsp; 
                   <Button type="submit" color="error" onClick={handleCloseobject}variant="contained">
                           Close
                        </Button> &nbsp;&nbsp;&nbsp;
                     </Box>
            {/* <button color="warning"onClick={handleClosereg} >Close</button> */}
          </form>
        </Formik>
      </Box>
    </div> 
    </Modal>
    <Box display="flex" justifyContent="end" mt="20px" hidden>
        <Button aria-describedby={open} onClick={handleOpenobject} variant="contained" color="secondary"> 
					Add object
				</Button>&nbsp;
        </Box>
       <Box
            m="40px 0 0 0"
            height="60vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
           >
            <DataGrid style={{height: '88%', width: '100%'}} rows={obje} columns={objectcol}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
        </Box>   
      </TabPanel>
    <TabPanel value={value} index={3} dir={theme.direction}>
    {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
       
      <Modal
          open={open} 
          onClose={handleClosereg} 
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <div >
        <Box
          m="20px"
          display="flex"
          marginTop={20}
          justifyContent="center"
          borderRadius={10}
          position="relative"
          transform="translate('-50%', '-50%')"
          alignItems="right"
          //backgroundColor="#4ca9ee"//"#d3d3d3"
          height="50%"
          width="70%"
          marginLeft={40}
          marginBottom={10}
        >
      <Formik
            initialValues={{namea: "", refnoa:"", registeredby:currentUser.username, approvedby:""}}
            validationSchema={objvalidationa}
            // onSubmit={onSubmiacc}
          >
          <Form style={styles.form}>
              <Field style={styles.input} type='text' placeholder='Action name' id='namea' name='namea' />
              <ErrorMessage component='a' name='namea' style={{color: 'red'}} />
              <Field style={styles.input} type='text' placeholder='refnumber' id='refnoa' name='refnoa' />
              <ErrorMessage component='a' name='refnoa' style={{color: 'red'}} />
              <Field style={styles.input} type='text' placeholder='description' id='description' name='descriptiona' />
              <ErrorMessage component='a' name='descriptiona' style={{color: 'red'}} />
              <button type='submit' style={styles.button} >Create</button> 
              <button onClick={handleClosereg} type='submit' style={styles.button} color="#2962d">Close</button>
              {/* <button color="warning"onClick={handleClosereg} >Close</button> */}
            </Form>
          </Formik>
        </Box>
      </div> 
      </Modal>
      <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid style={{height: '88%', width: '100%'}} rows={actiondata} columns={actioncol}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
      </Box>   
        </TabPanel>
        <TabPanel value={value} index={4}>
        {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
       
        <Modal
      	open={openMapping} 
				onClose={handleCloseMapping} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
       <div >
       <Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 3"
        marginRight={50}
        width={20}
        sx={style} 
      >
     <Formik
          initialValues={{rolename: "", approvedby: "",refno:"", description:""}}
          // validationSchema={validationSchemarole}
          onSubmit={formikMapping.onSubmit}
        >
         <form className='formContainer' onSubmit={formikMapping.handleSubmit} encType='multipart/form-data'>
         <ListItem>
                     <ListItemText primary="Allow Access for Individual"  size="small"  sx={{ gridColumn: "span 2" }}/>
                 <FormControl  size="small" Label="Registered As">
                       <Select name="requestedfor"
                       style = {{width: 100}}
                            onChange={formikMapping.handleChange}
                              value={formikMapping.values.requestedfor}
                              size="small"
                              variant="filled"
                              required
                                id="dis6"
                              sx={{ gridColumn: "span 2" }}
                              >
                              {users.map(user => (
                                 <MenuItem id={user.id} value={user.username}>
                              {user.firstname} {user.middlename}</MenuItem>))}
      
                         </Select>
                  </ FormControl>
                  </ListItem>
               {/* <ListItem>
               <ListItemText primary="Access Level  "  size="small"  sx={{ gridColumn: "span 2" }} />
                   <FormControl  size="small" >
                       <Select name="accesslevel"
                            onChange={formikMapping.handleChange}
                              value={formikMapping.values.accesslevel}
                              size="small"
                              variant="filled"
                              required
                                id="dis6"
                                style = {{width: 100}}
                                
                              
                              >{roles.map(label => (
                                <MenuItem id={label.id} value={label.id}>
                                   {label.rolename}
                                </MenuItem>))}
                          
                        </Select>
                  </ FormControl>
               </ListItem> */}
               <ListItem>
               <ListItemText primary="Allow on Items "  size="small"  sx={{ gridColumn: "span 2" }}/>
                   <FormControl  size="small" >
                       <Select name="objects"
                            onChange={formikMapping.handleChange}
                              value={formikMapping.values.objects}
                              size="small"
                              variant="filled"
                              required
                                id="dis6"
                                style = {{width: 100}}
                              
                              >
                                  {obje.map(obj => (
                                <MenuItem id={obj.id} value={obj.id}>
                                {obj.objname} </MenuItem>))}
                          
                        </Select>
                  </ FormControl>
               </ListItem>
               <ListItem>
               <ListItemText primary="Allow Action "  size="small"  sx={{ gridColumn: "span 2" }}/>
                   <FormControl  size="small" >
                       <Select name="actions"
                            onChange={formikMapping.handleChange}
                              value={formikMapping.values.actions}
                              size="small"
                              variant="filled"
                              required
                                id="dis6"
                                style = {{width: 100}}
                              
                              >
                                  {actiondata.map(action => (
                                <MenuItem id={action.id} value={action.id}>
                                {action.actnname} </MenuItem>))}
                          
                        </Select>
                  </ FormControl>
               </ListItem>
 
               <ListItem>
               <ListItemText primary="Grant Action "  size="small"  sx={{ gridColumn: "span 2" }}/>
                   <FormControl  size="small" >
                       <Select name="grant"
                            onChange={formikMapping.handleChange}
                              value={formikMapping.values.grant}
                              size="small"
                              variant="filled"
                              required
                                id="dis6"
                                style = {{width: 100}}
                              
                              >
                                <MenuItem  value="create">
                                create role </MenuItem>
                                <MenuItem  value="revoke">
                                revoke role </MenuItem>
                                <MenuItem  value="resume">
                                 resume role </MenuItem>
                                 <MenuItem  value="release">
                                 Release for resume </MenuItem>
                                 
                        </Select>
                  </ FormControl>
                  </ListItem>
               <ListItem>
                
                          <TextField
                          fullWidth
                          style = {{width: 400}}
                          variant="filled"
                          type="textarea"
                          label="Description"
                          onChange={formikMapping.handleChange}
                          value={formikMapping.values.description}
                          required
                          size="small"
                          name="description"
                          ></TextField>
                      </ListItem>
              <Box display="flex"  mt="20px">
              <Button type='submit' color="secondary"  variant="contained"  onClick={formikMapping.onSubmit}> Apply Mapping</Button>
                     &nbsp;&nbsp;&nbsp; 
                   <Button type="submit" color="error" onClick={handleCloseMapping}variant="contained">
                           Close
                        </Button> &nbsp;&nbsp;&nbsp;
                     </Box>
          </form>
        </Formik>
      </Box>
    </div> 
    </Modal>
    <Box display="flex" justifyContent="end" mt="20px" hidden>
        <Button aria-describedby={open} onClick={handleOpenMapping} variant="contained" color="secondary"> 
					Mapping
				</Button>&nbsp;
        </Box>

        <Box
            m="40px 0 0 0"
            height="60vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
           >
            <DataGrid style={{height: '88%', width: '100%'}} rows={pre} columns={priviledge}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
        </Box>   
          
          </TabPanel> 
        <TabPanel value={value} index={5} dir={theme.direction}>
        {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
       
        <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
           
            <DataGrid style={{height: '88%', width: '100%'}} rows={record} columns={userlogs}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>
          </Box>   
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Register;
