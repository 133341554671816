import { Box, Button, ListItem, Typography, useTheme,Modal, TextField,TableHead,styled ,TableRow,Table,TableCell,TableBody } from "@mui/material";
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import { AuthContext } from '../../context/AuthContext';

// import Header from "../../components/Header";
import React, { useEffect, useState,useContext } from "react";
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";

const style = { 
	position: "absolute", 
	top: "60%", 
	left: "55%",
  height:"70%", 
  transform: "translate(-50%, -50%)", 
	width: 900, 
  borderColor: "#d50000", 
	p: 4, 
}; 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: 'red',
}));


const Rider = () => {
  const [riders, setRiders] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [riderid, setRiderid] = useState('');
  let navigate = useNavigate();
  const { currentUser, url, logout,isSessionExpired} = useContext(AuthContext);
  const [open, setOpen] = React.useState(false); 
	const handleOpen = () => setOpen(true); 
	const handleClose = () => setOpen(false); 
 
  useEffect(() => {
    const fetchRiders = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${currentUser.accessToken}`
                }
            };
            const response = await axios.post(url + '/api/riders/all', { username: currentUser.username }, config);
            console.log(response.data)
            setRiders(response.data);
            
        } catch (e) {
            if (e.toJSON().status === 401) {
                console.log('No valid token or session expired');
                logout();
            } else {
                console.error(e);
            }
        }
    };

    fetchRiders();
}, [currentUser.username, currentUser.accessToken]);
  
  const columns = [
    // { field: "id", headerName: "ID" },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastname",
      headerName: "Midde Name",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phonenumber",
      headerName: "Phone Number", filter:true,
      flex: 1,
    },
    {
      field: "otp",
      headerName: "OTP",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "expotoken",
      headerName: "Token",
     flex:1,
    
    },
   
    
    // {
    //   field: "createdAt",
    //   headerName: "Created At",
    //   flex: 1,
    // },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated At",
    //   flex: 1,
    // },

    {
      field: "accessLevel",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            backgroundColor={
                colors.blueAccent[600]
            }
          >
            <Button type="submit" onClick={ () => {navigate(`/riders/${id}`)} }
                    sx={{
                      fontSize: '8px',
                      padding: '6px 10px',
                    }}>
              <AdminPanelSettingsOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              </Typography>
            </Button>
          </Box>
        );
      },
    },
  ];


  const group = [
    // { field: "id", headerName: "ID" },
    {
      field: "id",
      headerName: "GroupID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Phonenumber",
      headerName: "Family Phone number",
      flex: 2,
      cellClassName: "name-column--cell",
    },
  
    {
      field: "riderId",
      headerName: "RiderId",
      flex: 1,
    },

    {
      field: "members_count",
      headerName: "Count",
      flex: 1,
    },

    
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 2,
    },
    // {
    //   field: "updatedAt",
    //   headerName: "Updated At",
    //   flex: 1,
    // },

    {
      field: "accessLevel",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
                colors.greenAccent[600]
            }
            borderRadius="4px"
          >
            <Button type="submit" >
              {/* onClick={ () => {navigate(`/riders/${id}`)} } */}
              <AdminPanelSettingsOutlinedIcon />
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              </Typography>
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
    <Box
      m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    > <div>
        <a href="/ManualOrder">
        <Button type="submit" color="secondary" variant="contained" sx={{m:2}}>
               Manual Order
              </Button>      

      </a>

      {/* <Button onClick={handleOpen} variant="contained" color="secondary"> Ride sharing</Button> */}

    </div>
        <DataGrid   rows={riders} columns={columns}  components={{ Toolbar: GridToolbar }}  rowHeight={20}/>   
        <Modal 
				open={open} 
				onClose={handleClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			> 
      <Box sx={style} 
      backgroundColor={colors.grey[400]}
      display="flex"
      gridRow="span 3" 
      marginRight={50}
       >     
       <Box overflow="auto">
 <TextField
        label="Search by Phone number"
        size="small"
        sx={{
          '& .MuiInputBase-root': {
            height: 30, // Custom height
          },
        }}
        InputProps={{
          style: {
            padding: '0 14px', // Custom padding
          },
        }}
      />    
      <Button>Create </Button>      
      <table>
      <thead>
        <tr style={{ backgroundColor: 'lightblue' }}>
          <td>id</td>
          <td>RideId</td>
          <td>Phonenumber</td>
          <td>Start</td>
          <td>Destination</td>
          <td>Detail</td>
        </tr>
      </thead>
      </table>
      <table>
      <tbody>
        {riders.map((row) => (
          <tr key={row.id} style={{ height: 10, width: 10 }}>
            <td style={{ height: 10, width: 10 }}>{row.id}</td>
            <td style={{ height: 10, width: 10 }}>{row.phonenumber}</td>
            <td style={{ height: 10, width: 10 }}>{row.phonenumber}</td>
            <td style={{ height: 10, width: 10 }}>{row.phonenumber}</td>
            <td style={{ height: 10, width: 10 }}>{row.phonenumber}</td>

            <td style={{ height: 10, width: 10 }}>
              <Button style={{ height: 5, width: 10 }}>Detail</Button>
            </td>
          </tr>
        ))}
         {riders.map((row) => (
          <tr key={row.id} style={{ height: 10, width: 10 }}>
            <td style={{ height: 10, width: 10 }}>{row.id}</td>
            <td style={{ height: 10, width: 10 }}>{row.phonenumber}</td>
            <td style={{ height: 10, width: 10 }}>{row.phonenumber}</td>
            <td style={{ height: 10, width: 10 }}>{row.phonenumber}</td>
            <td style={{ height: 10, width: 10 }}>{row.phonenumber}</td>

            <td style={{ height: 5, width: 10 }}>
              <Button style={{ height: 5, width: 10 }}>Detail</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>

				        {/* <DataGrid   rows={riders} columns={group}  components={{ Toolbar: GridToolbar }} />   */}
                </Box> 
                <Box
       m="40px 0 0 0"
       height="75vh">

<Button type="submit">  Save Changes </Button>
              <Button color="error" onClick={handleClose} position="left">
                  Close
              </Button>

<table border="2">
      <thead>
      <tr style={{ backgroundColor: 'lightblue' }}>
          <th>Attribute</th>
          <th>Value</th>
          <th>Attribute</th>
          <th>Attribute</th>

          <th>Attribute</th>

        </tr>
      </thead>
      <tbody>
        {riders.map(vehicle => (
          <tr key={vehicle.id}>
            <td>{vehicle.id}</td>
            <td>{vehicle.phonenumber}</td>
            <td>{vehicle.phonenumber}</td>
            <td>{vehicle.phonenumber}</td>
            <td><Button sx={{ height: 10, width:10 }}>Detail </Button></td>

            {/* Add more <td> elements as needed */}
          </tr>
        ))}
      </tbody>
    </table>





    </Box>
              </Box>
            </Modal>
            <Box>
x

            </Box>
      </Box>
    </Box>
  );


    

};


export default Rider;
