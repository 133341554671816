import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/PrintOutlined";
// import Header from "../../components/Header";
import Alert from '@mui/material/Alert';
import Tab from '@mui/material/Tab';
import Mony from'@mui/icons-material/SaveAsRounded';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import {useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from "@mui/material/Modal"; 
import { useFormik } from 'formik';
import { blue } from "@mui/material/colors";
import { Input, Snackbar } from '@mui/material';
import { AuthContext } from "../../context/AuthContext";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
const style1 = {
  py: 0,
  width: '100%',
  maxWidth: 360,
  borderRadius: 2,
  //border: '2px solid',
  borderColor: 'dark',
  backgroundColor: 'dark',
};
const style3 = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 400, 
  borderColor: "#d50000", 
	p: 4, 
}; 
const style5 = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
}; 


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx2={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const style2 = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
  
};
const stylePrint = {
  body: {
    '*': {
      visibility: 'hidden',
    },
  },
  '#printable-content': {
    visibility: 'visible',
    position: 'absolute',
    top: "40%", 
	left: "20%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
    },
};
 function DriverPage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  let { id } = useParams();

  // const { currentUser, accaunts, createacc } = useContext(AuthContext);

  const [preview, setPreview] = useState('');
  const [loading, setLoading] = React.useState(false);
  const { currentUser, url,logout } = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  // const [updatevalue, setValueUpdate] = useState({
    const formikupdatedriver = useFormik({
      initialValues: {
    firstname:'',
    middlename:"",
    lastname:"",
    email:"",
    licenceId:"",
    export:"",
    status:"",  
    username:currentUser.username  
  },
  onSubmit: (values) => {
    // console.log(values);
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }

    const registerpro = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
  
    axios.put(url+`/api/drivers/${id}`, values, config).then((res)=>{
      // navigate(`/drivers/${id}`);
      if(res.data=="success"){
        setNotification({
          show: true,
          type: 'success',
          message: "The Operation  success!" 
          
      });
      }else  if(res.data=="nochange"){
        setNotification({
          show: true,
          type: 'warning',
          message: "No Change made!" 
          
      });
      }
      else{
      setNotification({
        show: true,
        type: 'info',
        message: res.data 
      
    });
  }
       setIsRedirecting(true);
       const timer = setTimeout(() => {
        window.location.reload();

          }, 2000);
     
       return () => clearTimeout(timer);
    }).catch(error=>{
      setNotification({
        show: true,
        type: 'error',
        message: error.data 
    });
     
       setIsRedirecting(true);
       const timer = setTimeout(() => {
        window.location.reload();

          }, 2000);
     
       return () => clearTimeout(timer);
   });
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
  }
};

registerpro();
  },
});


  const formicspr = useFormik({
    initialValues: {
      path: '',
      driverid:id,
      username:currentUser.username
    },
    onSubmit: (values) => {
      // console.log(values);
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
      // alert("change profile methode")
      const registerpro = async () => {
        try {
          const config = {
              headers: {
                  'Authorization': `Bearer ${currentUser.accessToken}`
              }
          };
    
      axios.post(url+`/api/drivers/pro/${id}`, formData,config).then((res) => {
        // setdoc(doc.concat(res.data));
        setOpenchangeprofile(false);
        window.location.reload();
        setNotification({
          show: true,
          type: 'success',
          message: res.data     
      });
      setIsRedirecting(true);
      const timer = setTimeout(() => {
         
         }, 3000);
    
      return () => clearTimeout(timer);
      });
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
    }
  };
  
  registerpro();
    },
  });

  //End change profile

  const [downloadOpen, setdownloadmodal] = React.useState(false); 
  const handledownloadmodal = () => setdownloadmodal(true); 
  const handleclosedownloadmodal = () => setdownloadmodal(false); 

  const [openchangeprofile, setOpenchangeprofile] = React.useState(false); 
  const handleopenchangeprofile = () => setOpenchangeprofile(true); 
  const handleopenchangeprofileclose = () => setOpenchangeprofile(false); 


   

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handlecClose2 = () => setOpen2(false);

  const [openRP, setOpenRP] = React.useState(false);
  const handleOpenRP = () => setOpenRP(true);
  const handlecCloseRP= () => setOpenRP(false);


  
  const formicsRESET = useFormik({
    initialValues: {
      pin: '',
      driverid:id,
      username:currentUser.username
    },
    onSubmit: (values) => {
      // console.log(values);
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
    axios.post(url+`/api/drivers/reset/${id}`,values,config)
  .then((res)=>{
    setOpenRP(false)
  //  alert(res.data)
   setNotification({
    show: true,
    type: 'success',
    message: res.data 
  
});
setIsRedirecting(true);
const timer = setTimeout(() => {
  window.location.reload();

   }, 2000);
   window.location.reload();

return () => clearTimeout(timer);

  })
 
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
    }
  });






  const formicstsatus = useFormik({
    initialValues: {
      status: '',
      driverid:id,
      username:currentUser.username
    },
    onSubmit: (values) => {
      // console.log(values);
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
      }
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
    
    axios.post(url+`/api/drivers/status/${id}`,values,config)
  .then((res)=>{
    setOpen2(false)
  //  alert(res.data)
   setNotification({
    show: true,
    type: 'success',
    message: res.data 
  
});
setIsRedirecting(true);
const timer = setTimeout(() => {
  window.location.reload();

   }, 2000);
   window.location.reload();

return () => clearTimeout(timer);

  })
 
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
    }
  });
  
  const isNonMobile = useMediaQuery("(min-width:600px)");



  const [driver, setDrivers] = useState([]);
  const [imageSrc, setImageSrc] = useState('');
  const [extention, setExtention] = useState('');
  const [notif, setNotif] = useState('');
  const [totalwork, setTotalwork] = useState('');


   useEffect(() => {
    const fetchsetDrivers = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + `/api/drivers/${id}`, { username: currentUser.username }, config);
        setImageSrc(response.data.image);
        setDrivers(response.data.driver);
        setExtention(response.data.extentions)
        // setNotif(response.data.notif)
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

fetchsetDrivers();
}, [currentUser.username, currentUser.accessToken]);


   const [trans, setTransaction] = useState([]);
   const [driverprofile, setDriverProfile] = useState([]);
   const [driverNotification, setDriverNotifiction] = useState([]);



  useEffect(() => {
    const fetchsetTransaction = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + `/api/transactions/driverpage/${id}`, { username: currentUser.username }, config);
        setTransaction(response.data.listtransactionfordriver);
        console.log(response.data.driverprofile)
        setDriverProfile(response.data.driverprofile);
        setDriverNotifiction(response.data.notificationDriver)

      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchsetTransaction();
}, [currentUser.username, currentUser.accessToken]);

// alert(id)
  const [open, setOpen] = React.useState(false); 
	const handleOpen = () => setOpen(true); 
	const handleClose = () => setOpen(false); 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [values, setValues] = useState({
    const formicTransaction = useFormik({
      initialValues: {
    driverid: id,
    transactionmethod: "",
    username:currentUser.username,
    amaunt:""
    
  // })
  // function handleTransactions(){
  },
  onSubmit: (values) => {
    // console.log(values);
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
    const fetchsetDriverdoc1 = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
    axios.post(url+'/api/transactions/reg/driver', values, config) .then((res) => {
      setOpen(false);
      setNotification({
        show: true,
        type: 'info',
        message: res.data 
    });
     
       setIsRedirecting(true);
       const timer = setTimeout(() => {
        window.location.reload();
   
          }, 2000);
     
       return () => clearTimeout(timer);

    })
  }catch (e) {
    if (e.toJSON().status === 401) {
        console.log('No valid token or session expired');
        logout();
    } else {
        console.error(e);
    }
  }
};

fetchsetDriverdoc1();
}
});

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  const [driverdoc, setDriverdoc] = useState([]);
  const [historyorder, sethistoryOrder] = useState([]);
  const [fare, setFare] = useState([]);
  const [distance, setDrivedistance] = useState([]);
  const [count, setCount] = useState([]);


  useEffect(() => {
    const fetchorderhistory = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + `/api/drivers/ordershistory/${id}`, { username: currentUser.username }, config);
        sethistoryOrder(response.data.orderhistory);
        setFare(response.data.fare)
        setDrivedistance(response.data.distance)
        setCount(response.data.count)
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchorderhistory();
}, [currentUser.username, currentUser.accessToken]);


useEffect(() => {
  const fetchsetDriverdoc = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + `/api/driversdocuments/doc/${id}`, { username: currentUser.username }, config);
      setDriverdoc(response.data);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};

fetchsetDriverdoc();
}, [currentUser.username, currentUser.accessToken]);


 
const handleDownloadpdf = () => {
  const config = {
    headers: {
      'Authorization': `Bearer ${currentUser.accessToken}`
    },
    responseType: 'blob' // Ensure responseType is correctly set here
  };

  axios.post(`${url}/api/drivers/profile/download/${id}`, { username: currentUser.username }, config)
    .then((res) => {
      // FileDownload(res.data, "download.jpg"); // Uncomment this line if using FileDownload library
      // Alternatively, use vanilla JavaScript to handle the download
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', driver.firstname+driver.middlename+'_'+id+'.jpg');
      document.body.appendChild(link);
      link.click();

    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        console.log('No valid token or session expired');
        logout(); // Assuming logout function exists to handle token expiration
      } else {
        console.error('Error downloading PDF:', error);
      }
    });
};



 const columndoc = [
  // { field: "id", headerName: "ID" },
  {
    field: "id",
    headerName: "id",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "title",
    headerName: "Document Title",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "createdby",
    headerName: "Created by",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "Status",
    headerName: "status",
    flex: 1,
  },
  {
    field: "remark",
    headerName: "Remark",
    flex: 1,
  },
  {
    
    headerName: "File",
    flex: 1,
    renderCell: ({ row: { path } }) => {
      return (
        <Box
          width="60%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
          backgroundColor={
              colors.greenAccent[600]
          }
          borderRadius="4px"
        >
          {/* <Button onClick={ () => {handleDownloadpdf(path)}}> */}
          {/* </Button> */}
        </Box>
      );
    },
  },
   ];
//End for table transaction 

const [openDetailTransaction,setopenDetailTransaction]=React.useState(false)
const handleopenDetailTransaction=()=>setopenDetailTransaction(true)
const closeOpenTransaction =()=>setopenDetailTransaction(false)

const [idno,setId]=useState(0)
const [driverid,setdriverid]=useState(0)
const [previoustotal,setprevioustotal]=useState(0)
const [transactionmethod,settransactionmethod]=useState(0)
const [transactionhandler,settransactionhandler]=useState(0)
const [debitaccount,setdebitaccount]=useState(0)
const [creditaccount,setcreditaccount]=useState(0)


const [amount,setamount]=useState(0)
const [commission,setcommission]=useState(0)
const [vat,setvat]=useState(0)
const [newtotal,setnewtotal]=useState(0)
const [AccountId,setAccountId]=useState(0)

const [createdAt,setcreatedAt]=useState(0)
const[updatedAt,setupdatedAt]=useState(0)

function handleopenDetailTransactionDisp( id,driverid,previoustotal,
  transactionmethod,transactionhandler,
  debitaccount,creditaccount,amount,
  commission,vat,newtotal,AccountId ,createdAt,updatedAt){
  let dateObject = new Date(createdAt);
let formattedDate = dateObject.getFullYear() + '/' +
                   (dateObject.getMonth() + 1).toString().padStart(2, '0') + '/' +
                   dateObject.getDate().toString().padStart(2, '0');

let dateObject2 = new Date(updatedAt);
let formattedDate2 = dateObject2.getFullYear() + '/' +
                  (dateObject2.getMonth() + 1).toString().padStart(2, '0') + '/' +
                  dateObject2.getDate().toString().padStart(2, '0');

              

  setId(id)
  setdriverid(driverid)
  setprevioustotal(previoustotal)
  settransactionmethod(transactionmethod)
  settransactionhandler(transactionhandler)
  setdebitaccount(debitaccount)
  setcreditaccount(creditaccount)
  setamount(amount)
  setcommission(commission)
  setvat(vat)
  setnewtotal(newtotal)
  setAccountId(AccountId)
  setcreatedAt(formattedDate)
  setupdatedAt(formattedDate2)

  setopenDetailTransaction(true)
  // alert(id+"----"+ac+"---"+"----"+st)
}


const columns = [
  // { field: "id", headerName: "ID" },
  {
    field: "id",
    headerName: "id",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "driverid",
    headerName: "Driver ID",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "previoustotal",
    headerName: "previoustotal",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "transactionmethod",
    headerName: "T-method",
    flex: 1,
  },
  {
    field: "transactionhandler",
    headerName: "transactionhandler",
    flex: 1,
  },
  {
    field: "debitaccount",
    headerName: "debitaccount", filter:true,
    flex: 1,
  },
  {
    field: "creditaccount",
    headerName: "creditaccount",
    flex: 1,
  },
  {
    field: "amount",
    headerName: "amount",
    flex: 1,
  },
  {
    field: "vat",
    headerName: "vat",
    flex: 1,
  },
  {
    field: "newtotal",
    headerName: "newtotal",
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "createdAt",
    flex: 1,
  },

  {
    
    headerName: "Print",
    flex: 1,
    renderCell: ({ row: { id,driverid,previoustotal,transactionmethod,transactionhandler,debitaccount,creditaccount,amount,
      commission,vat,newtotal,AccountId ,createdAt,updatedAt} }) => {
      return (
        <Box
        width="60%"
        m="0 auto"
        p="5px"
        display="flex"
        justifyContent="center"
        backgroundColor={
            colors.greenAccent[600]
        }
        borderRadius="4px"
      >
        <Button onClick={()=>{handleopenDetailTransactionDisp(`${id}`,`${driverid}`,`${previoustotal}`,
        `${transactionmethod}`,`${transactionhandler}`,`${debitaccount}`,`${creditaccount}`,`${amount}`,
        `${commission}`, `${vat}`,`${newtotal}`,`${AccountId}`,`${createdAt}`,`${updatedAt}`)}}>
          <AdminPanelSettingsOutlinedIcon />
          <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
          </Typography>
        </Button>
      </Box>
      );
    },
  },
   ];

   const PdfViewer = ({ src, width, height }) => {
    return (
      <embed
        src={src}
        type="application/pdf"
        width={width || '100%'}
        height={height || '600px'}
      />
    );
  };

  const orderhistory = [

    {
      field: "platenumber",
      headerName: "platenumber",
      flex: 1,
    },
    
    {
      field: "riderphone",
      headerName: "riderphone", filter:true,
      flex: 1,
    },
    {
      field: "requesttime",
      headerName: "requesttime",
      flex: 1,
    },
    {
      field: "distance",
      headerName: "distance",
      flex: 1,
    },
    {
      field: "duration",
      headerName: "duration",
     flex:1,
    
    },    
    {
      field: "startdate",
      headerName: "startdate",
     flex:1,
    
    },  
    {
      field: "fare",
      headerName: "fare",
     flex:1,
    
    },  
    {
      field: "enddate",
      headerName: "enddate",
     flex:1,
    
    },  
    {
      field: "status",
      headerName: "status",
     flex:1,
    
    }, 
    {
      field: "Detail",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
                colors.greenAccent[600]
            }
            borderRadius="4px"
          >
          </Box>
        );
      },
    },
  ];

  const notifications = [
    {
      field: "id",
      headerName: "NotificationId",
      flex: 1,
    },

    {
      field: "riderid",
      headerName: "riderId",
      flex: 1,
    },
    
    {
      field: "driverid",
      headerName: "driverid", filter:true,
      flex: 1,
    },
    {
      field: "eventid",
      headerName: "eventid",
      flex: 1,
    },
    {
      field: "title",
      headerName: "title",
      flex: 2,
    },
    {
      field: "body",
      headerName: "body",
     flex:3,
    
    },    
    {
      field: "status",
      headerName: "status",
     flex:1,
    
    },  
    {
      field: "createdAt",
      headerName: "createdAt",
     flex:2,
    
    },  
 
    {
      field: "updatedAt",
      headerName: "updatedAt",
     flex:2,
    
    }, 
   
  ];


  return (
<Box m="20px"
    style={{height: '85%'}}
  >
  <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
  <AppBar position="static">
    <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		      backgroundColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
          >
       {/* <Tab label="Balance" {...a11yProps(0)} /> */}
      <Tab label="Transaction" {...a11yProps(0)} />
      {/* <Tab label="Manage status" {...a11yProps(2)} /> */}
		  <Tab label="Order history" {...a11yProps(1)} />
		  <Tab label="Manage complain" {...a11yProps(2)} />
		  {/* <Tab label="Online" {...a11yProps(3)} /> */}
      <Tab label="Driver Profile" {...a11yProps(3)} />
      <Tab label="Notification" {...a11yProps(4)} />
    </Tabs>
  </AppBar>
        <TabPanel value={value} index={3} dir={theme.direction}>
		<div>  
    <Box m="20px">
    
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
      <Box
          gridColumn="span 12"
          backgroundColor={colors.grey[400]}
          display="flex"
          gridRow="span 3"
          
        >
        <Typography>
        {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
        </Typography>
        <List sx={style1} label="Drivers Profile">
        <Box gridColumn={4}>
        <form className='formContainer' onSubmit={formikupdatedriver.handleSubmit} encType='multipart/form-data'>
   
        <ListItem>
        <ListItemText primary="First Name4" />
        <TextField name="firstname"size="small"label={driverprofile.firstname}
        // onChange={(e)=> setValueUpdate({...updatevalue, firstname: e.target.value})}
        onChange={formikupdatedriver.handleChange}
        value={formikupdatedriver.values.firstname}
      
        />
      </ListItem>
      
      <ListItem>
      <ListItemText primary="Midle Name" />
        <TextField size="small"name="middlename"label={driverprofile.middlename} 
         onChange={formikupdatedriver.handleChange}
         value={formikupdatedriver.values.middlename}
        />
      </ListItem>
      <ListItem>
      <ListItemText primary="Last Name" />
        <TextField size="small"name="lastname"label={driverprofile.lastname} id="e" 
        // onChange={(e)=> setValueUpdate({...updatevalue, lastname: e.target.value})}
        onChange={formikupdatedriver.handleChange}
        value={formikupdatedriver.values.lastname}
     
        />
      </ListItem>
 
      <ListItem>
      <ListItemText primary="PhoneNumber" />
        <TextField size="small"label={driverprofile.phonenumber} disabled />
      </ListItem>
   
      <ListItem>
        <ListItemText primary="Licence Id" />
        <TextField name="licenceId"size="small"label={driverprofile.licenceId} 
          onChange={formikupdatedriver.handleChange}
        value={formikupdatedriver.values.licenceId}
        />
      </ListItem>
      <ListItem>
      <ListItemText primary="Email" /> 
        <TextField size="small"name="email" label={driverprofile.email} 
        onChange={formikupdatedriver.handleChange}
        value={formikupdatedriver.values.email}
        />
      </ListItem>
      <Typography sx={{ marginBottom: 4 }}>
        {/* Content for the first Typography */}
      </Typography>
      <Typography>
        <Button
          type="submit"
          color="secondary"
          onClick={formikupdatedriver.onSubmit}
          disabled={loading}
          endIcon={loading && <CircularProgress size={24} />}
          variant="contained"
        >
          {loading ? 'Loading...' : 'Update profile'}
        </Button>
        &nbsp;&nbsp;
        <Button color="info" variant="contained" sx={{ m: 1 }} onClick={handleOpen2}>
          Change status
        </Button>
        <Button color="info" variant="contained" sx={{ m: 1 }} onClick={handleOpenRP}>
          Pin Reset
        </Button>
      </Typography>
   </form>
  
   <Dialog
        open={open2}
        onClose={handlecClose2}

      >
        <DialogTitle backgroundColor={colors.grey[400]}>Change Driver Status</DialogTitle>
        <form className='formContainer' onSubmit={formicstsatus.handleSubmit} encType='multipart/form-data'>
       
        <DialogContent backgroundColor={colors.grey[400]}>  
       
          <DialogContentText> 
         

            <TextField disabled name="currentstatus" label="Current status" defaultValue={driver.status}></TextField>    
           
             <TextField select name="status"
                             InputProps={{
                              style: {
                                height: 50,
                                width: 200,
                              },
                            }} 
                            
                            onChange={formicstsatus.handleChange}
                value={formicstsatus.values.status}
                            >   
                    <MenuItem value="active">Active</MenuItem>
                    <MenuItem value="blacklist">Black List</MenuItem>
                    <MenuItem value="penality">Penality</MenuItem>
                    <MenuItem value="inactive">Inactive</MenuItem>
                    <MenuItem value="nsf">None Sufficient Fund</MenuItem>

            </TextField> 

          </DialogContentText>
         
        </DialogContent>
        <Button onClick={handlecClose2} color='error'variant="contained" sx={{m:1}}>Cancel</Button>
          <Button type="submit" color='secondary' variant="contained"sx={{m:1}}>Apply</Button>
          </form>

        <DialogActions>
        </DialogActions>

      </Dialog>


      <Dialog
        open={openRP}
        onClose={handlecCloseRP}

      >
        <DialogTitle backgroundColor={colors.grey[400]}>RESET Driver PIN</DialogTitle>
        <form className='formContainer' onSubmit={formicsRESET.handleSubmit} encType='multipart/form-data'>
       
        <DialogContent backgroundColor={colors.grey[400]}>  
       
          <DialogContentText> 
         

           
             <TextField  name="pin"
                             InputProps={{
                              style: {
                                height: 50,
                                width: 200,
                              },
                            }} 
                            
                            onChange={formicsRESET.handleChange}
                value={formicsRESET.values.pin}
                            >   
                 

            </TextField> 

          </DialogContentText>
         
        </DialogContent>
        <Button onClick={handlecCloseRP} color='error'variant="contained" sx={{m:1}}>Cancel</Button>
          <Button type="submit" color='secondary' variant="contained"sx={{m:1}}>RESET</Button>
          </form>

        <DialogActions>
        </DialogActions>

      </Dialog>



    <Modal 
           open={openchangeprofile} 
           onClose={handleopenchangeprofileclose} 
           aria-labelledby="modal-modal-title"
           aria-describedby="modal-modal-description"> 
              <Box
             //gridColumn="span 6"
             backgroundColor={colors.grey[400]}
             display="flex"
             gridRow="span 3" 
             // marginLeft={20}
             marginRight={50}
             width={20}
             height={500}
             sx={style5}
             >
   
   <form className='formContainer' onSubmit={formicspr.handleSubmit} encType='multipart/form-data'>             
   <ListItem>
        <ListItemText  id="dis2"primary="File" />
       {/*  */}
   <input type="file"  name="path"
                onChange={(e) => {
                 let reader = new FileReader();
                 reader.onload = () => {
                   if (reader.readyState === 2) {
                       formicspr.setFieldValue('path', e.target.files[0])
                      }
                  }
                 reader.readAsDataURL(e.target.files[0]);
                 }}
              />
   {/*  */}
   
        </ListItem>
   <Button type="submit" color="secondary" variant="contained" >  change</Button>&nbsp;&nbsp;&nbsp;
           <Button color="error" onClick={handleopenchangeprofileclose}variant="contained"> Close </Button> &nbsp;&nbsp;&nbsp;
           </form>
           </Box>
           </Modal>
      </Box>
      
    </List>

    <Typography >
      {extention==='.pdf'?
            imageSrc && <PdfViewer src={imageSrc} width="300px" height="400px" />
      :
    imageSrc && < img src={imageSrc} width="350px" height="400px" />}

    <Box>
    <Typography sx={{ marginBottom: 7 }}>
        {/* Content for the first Typography */}
      </Typography>
  <Button  color="secondary" variant="contained"onClick={handleopenchangeprofile} sx={{m:1}}>  Change photo</Button>
  <Button  color="secondary" variant="contained"onClick={handleDownloadpdf} sx={{m:1}}>  Download</Button>

  <Button type="submit" color="secondary"variant="contained" onClick={ () => {navigate(`/driverdocument/${id}`)} }>document home</Button>
  </Box>
  </Typography>
        </Box>
</Box>

      </Box>
      </div>  
 </TabPanel>
        <TabPanel value={value} index={0} dir={theme.direction}>
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>

            <div> 
		         	<div> 
               <Box>
			<Button type="submit" color="secondary" variant="contained" aria-describedby={open} onClick={handleOpen}>
        Deposit
              </Button> &nbsp;&nbsp;  Driver Full Name :
{driverprofile.firstname} {driverprofile.middlename} {driverprofile.lastname} , Phone Number :{driverprofile.phonenumber}, Current Balance : {driverprofile.balance}</Box>
              <Modal 
      
      open={openDetailTransaction} 
      onClose={closeOpenTransaction} 
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      
    > 
       <>
      <style media="print">{`
        ${Object.entries(stylePrint).map(([key, value]) => `${key} {${Object.entries(value).map(([k, v]) => `${k}: ${v};`).join('')}}`).join('')}
      `}</style>
    <Box sx={style2  } 
      //gridColumn="span 6"
      backgroundColor={colors.grey[400]}
      // display="flex"
      display="flex"
      gridRow="span 3" 
      // marginLeft={20}
      marginRight={50}
       > 
       <Typography>
       <img src="./assets/wlogo.png" alt='logo' style={{marginLeft: 5}} width={80} height={80} />

       </Typography>
          <Box  justifyContent="end">
          <Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
          <table border="1"     id="printable-content"> 
            <tr background-color= "#f2f2f2">
                <td>Attribute</td>
                <td>Value</td>
                <td>Attribute</td>
                <td>Value</td>
            </tr>
            <tr>      
                <td>ID Number</td>
                <td>{idno}</td>
                <td>Driver Id</td>
                <td>{driverid}</td>
            </tr>
            <tr>
                <td>Previous Balance</td>
                <td>{previoustotal}</td>
                <td>Transaction Method</td>
                <td>{transactionmethod}</td>
            </tr>
            <tr>
                <td>Transaction Handler</td>
                <td>{transactionhandler}</td>
                <td>Debitaccount</td>
                <td>{debitaccount}</td>

            </tr>

            <tr>
                <td>Creditaccount</td>
                <td>{creditaccount}</td>
                <td>Amount</td>
                <td>{amount}</td>

            </tr>

            <tr>
                <td>Commission</td>
                <td>{commission}</td>
                <td>Vat</td>
                <td>{vat}</td>

            </tr>

            <tr>
                <td>Newtotal</td>
                <td>{newtotal}</td>
                <td>AccountId</td>
                <td>{AccountId}</td>

            </tr>

            <tr>
                <td>Created Date</td>
                <td>{createdAt}</td>
                <td>Updated Date</td>
                <td>{updatedAt}</td>

            </tr>
         </table>
         <Box>
    
    <Button variant="secondary" color="error"onClick={closeOpenTransaction} position="right">
  Close
</Button>
<Button variant="secondary" color="error" onClick={() => window.print()}>
Print
</Button>
</Box>
        </Typography>
            
      </Box>

    </Box>
   
     
    </>
   </Modal>




              {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
			</div> 
			<Modal 
      	open={open} 
				onClose={handleClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
    	> 
				<Box sx={style3} 
                  //gridColumn="span 6"
                  backgroundColor={colors.grey[400]}
                 // display="flex"
                  gridRow="span 2" 
                  // marginLeft={20}
                  marginRight={50}
                  width={10}
           > 
					<Typography id="modal-modal-title"
						variant="h6" component="h2"> 
  			Charge balance

					</Typography> 
          <Typography id="modal-modal-title"
						variant="h6" component="h2"> 
Driver Name:                             Phone number
Plate number:                          
					</Typography> 
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
					  <form className='formContainer' onSubmit={formicTransaction.handleSubmit} encType='multipart/form-data'>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Grid   spacing={10}>
              <Grid item>
                {
            <FormControl sx={{ m: 1, minWidth: 120 }} >
            <InputLabel id="demo-select-small-label">Payment Method</InputLabel>
                   <Select name="transactionmethod"
                   size="small"
                   variant="filled"
                   style = {{width: 200}} 
                   label="Paymant Method"
                   required
                   onChange={formicTransaction.handleChange}
                   value={formicTransaction.values.transactionmethod}
                   sx={{ gridColumn: "span 3" }}
                   >
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Bank">Bank Transfer</MenuItem>
                    <MenuItem value="Telebirr">Telebirr</MenuItem>
                    <MenuItem value="M-Pessa">M-Pessa</MenuItem>
                   </Select>
                  </ FormControl>
                }
                </Grid>
                <Grid item>
               <TextField
                fullWidth
                variant="filled"
                type="number"
                label="amaunt"
                name="amaunt"
                style = {{width: 200}} 
                onChange={formicTransaction.handleChange}
                value={formicTransaction.values.amaunt}
                required
                columns={2}
                
                ></TextField>
                </Grid>
                </Grid>
               </Box>
              <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained"> 
              <Mony/>Save Changes
              </Button> &nbsp;&nbsp;
              <Button variant="secondary" onClick={handleClose} position="left">
              Close
          </Button>
            </Box>
               
            </form>
            
					</Typography> 
         
				</Box> 
        
			</Modal> 
		</div> 

<DataGrid  style={{height: '90%', width: '100%'}} rowHeight={20} columns={columns} rows={trans}   components={{ Toolbar: GridToolbar }} />

    </Box>
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
        <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>
                
       <DataGrid rows={driverdoc} columns={columndoc} components={{ Toolbar: GridToolbar }} ></DataGrid>
</Box>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>


    <Box m="20px">
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 8"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridRow="span 3"
        >  
         <DataGrid rows={historyorder} columns={orderhistory} components={{ Toolbar: GridToolbar }} ></DataGrid>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Driver work summery    
           </Typography>
            </Box>
            <Typography color={colors.blueAccent[400]} variant="h5" fontWeight="600">
               Daily performance    
           </Typography>
            <Typography>Handle Order: {count}</Typography>
            <Typography>Total Fare: {fare}</Typography>
            <Typography>Total Driving: {distance}</Typography>
            <Typography> Rating: {distance}</Typography>
            <Typography color={colors.blueAccent[400]} variant="h5" fontWeight="600">
               weekly performance    
           </Typography>
           <Typography>Handle Order: {count}</Typography>
            <Typography>Total Fare: {fare}</Typography>
            <Typography>Total Driving: {distance}</Typography>
            <Typography> Rating: {distance}</Typography>
            <Typography color={colors.blueAccent[400]} variant="h5" fontWeight="600">
               Monthly performance    
           </Typography>
           <Typography>Handle Order: {count}</Typography>
            <Typography>Total Fare: {fare}</Typography>
            <Typography>Total Driving: {distance}</Typography>
            <Typography> Rating: {distance}</Typography>
              <Typography color={colors.blueAccent[400]} variant="h5" fontWeight="600">
               Annual performance    
           </Typography>
           <Typography>Handle Order: {count}</Typography>
            <Typography>Total Fare: {fare}</Typography>
            <Typography>Total Driving: {distance}</Typography>
            <Typography> Rating: {distance}</Typography>
            
            <Typography color={colors.blueAccent[400]} variant="h5" fontWeight="600">
               Over all performance    
           </Typography>
           <Typography>Handle Order: {count}</Typography>
            <Typography>Total Fare: {fare}</Typography>
            <Typography>Total Driving: {distance}</Typography>
            <Typography> Rating: {distance}</Typography>
        </Box>
      </Box>
    </Box>
  </TabPanel>
  <TabPanel value={value} index={4} dir={theme.direction}>
        <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>
                
       <DataGrid rows={driverNotification} columns={notifications} components={{ Toolbar: GridToolbar }} rowHeight={20}></DataGrid>
</Box>
        </TabPanel>
</Box>
  </Box>
  );

};

export default DriverPage;
