import { Box,Divider, FormControl,Snackbar,FormControlLabel ,Alert, Select,MenuItem,InputLabel,List,ListItem, ListItemText,Typography, useTheme,Button,TextField, Checkbox} from "@mui/material";
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import NewItem from "@mui/icons-material/PeopleAlt";
import Orders from "@mui/icons-material/TravelExploreSharp";
import Active from "@mui/icons-material/WorkHistoryOutlined";
import Vehicle from "@mui/icons-material/CarRental";
import DriverIcon from "@mui/icons-material/Add";


import { AuthContext } from "../../context/AuthContext";
import React, { useEffect, useState, useContext } from "react";
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal"; 
// import Wallet from "../../images/Wallet.png"
import * as yup from 'yup';
import {Formik, Field, ErrorMessage, useFormik } from 'formik';
const style3 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'primary.main',
    border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const textFieldStyle = {
  flex: 1,
  size: 'small',
  bgcolor:'info.main',
  sx: {
    '& .MuiInputBase-root': {
      height: 40, 
      width:  300,// Custom height
      backgroundColor:"info.main"// Custom height

    },
    '& .MuiInputBase-input': {
      padding: '0 14px', // Custom padding
    },
  },
};

const formControlStyle = {
  flex: 1,
  sx: {
    '& .MuiInputBase-root': {
      height: 40, 
      width:  300,
      backgroundColor:"info.main"// Custom height
    },
    '& .MuiInputBase-input': {
      padding: '0 14px', // Custom padding
    },
  },
};

const Driver = () => {
  const { currentUser,url ,logout,isSessionExpired} = useContext(AuthContext);
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [notification, setNotification] = useState(null);
const [isRedirecting, setIsRedirecting] = useState(false);
const [preview, setPreview] = useState('');
const navigate = useNavigate()
const isNonMobile = useMediaQuery("(min-width:600px)");
let controldisp=0

const formik = useFormik({
initialValues: {
firstname: "",
middlename: "",
lastname: "",
email: "",
phonenumber: "",
licenceId:"",
pin:"00",
otp:"00",
balance:"",
platenumber:"",
status:"",
expotoken:currentUser.username,
location:"",
photo: "",
username:currentUser.username,
transactionmethod:""
}, onSubmit: (values) => {
  // console.log(values);
  //alert(values.platenumber+"wewwewe");
  const formData = new FormData();
  for (let value in values) {
    formData.append(value, values[value]);
  //  alert(values[value]); 
  }
  const registerdriver6 = async () => {
    
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
  
 axios.post(url+"/api/drivers", formData,config).then((res) => {
    // setDriverReg(driverReg.concat(res.data));
    setOpen(false);
    if(res.data=="Driver registration completed, status is inactive"){
    setNotification({
      vertical: 'top', horizontal: 'right',
      show: true,
      type: 'success',
      message: "Driver registration completed, status is new" 
  });
}else if(res.data=="no"){
  setNotification({
    show: true,
    type: 'error',
    message: "No Vehicle assosiated to plate number" 
});
}else if(res.data=="exist"){
    setNotification({
      show: true,
      type: 'info',
      message: "The driver is already registered with this phonenumber" 
  });}
  else{
    setNotification({
      show: true,
      type: 'error',
      message: res.data 
  });

  }
    setIsRedirecting(true);
    const timer = setTimeout(() => {
        
       }, 3000);
  
    return () => clearTimeout(timer);
  });
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
};
registerdriver6();

},

});
 
	const [open, setOpen] = React.useState(false); 
  

  //manage register modal
	const handleOpen = () => setOpen(true);
  const handleClosereg = () => setOpen(false);
  

  //manage register modal


  const [driver, setDriver] = useState([]);
  const [asignedVehic, setAssigned] = useState([]);
  const [active, setActive] = useState([]);
  const [all, SetAllDriver] = useState([]);
  const [listActive, setlistActive] = useState([]);
  const [balanceA, setBalance] = useState([]);
  const [records, setrecords] = useState([]);
  const [orders, setOrders] = useState([]);


  

  

   useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/drivers/all', { username: currentUser.username }, config);
        setDriver(response.data.driver);
        setAssigned(response.data.ass);
        setActive(response.data.active);
        SetAllDriver(response.data.all);
        setlistActive(response.data.listActive)
        setBalance(response.data.balance)
        setrecords(response.data.records)
        setOrders(response.data.orders)
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

fetchDrivers();
}, [currentUser.username, currentUser.accessToken]);
function dispActiveDriver(){
  controldisp=1;
  
}
function dispAllDriver(){
  controldisp=0;
}

 
  const [platenumer, setPlate]=useState([]);
  useEffect(() => {
    const fetchplate = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + '/api/vehicles/plate', { username: currentUser.username }, config);
        setPlate(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };

  fetchplate();
}, [currentUser.username, currentUser.accessToken]);

  //Form Validation
  const registerSchema = yup.object().shape({
    firstname: yup.string().required('First Name is required'),
    middlename: yup.string().required('Middle Name is required'),
    lastname: yup.string().required('Middle Name is required'),
    balance: yup.string().required('Middle Name is required'),
    licenceId: yup.string().required('Middle Name is required'),
    platenumber: yup.string().required('Middle Name is required'),
    transactionmethod: yup.string().required('Middle Name is required'),
    phonenumber: yup
      .string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(9, 'Must be exactly 9 digits')
      .max(9, 'Must be exactly 9 digits')
      .required('Phone Number is required'),
    });


    const [eror, setError] = useState([""]);

    const handleDownloaddriver = () => {
      
      const config = {
        headers: {
          'Authorization': `Bearer ${currentUser.accessToken}`
        },
        responseType: 'blob' // Ensure responseType is correctly set here
      };
    axios.post(`${url}/api/drivers/download/driver`, { username: currentUser.username }, config)
      .then((res) => {
        // FileDownload(res.data, "download.jpg"); // Uncomment this line if using FileDownload library
        // Alternatively, use vanilla JavaScript to handle the download
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'driverapp.apk');
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log('No valid token or session expired');
          logout(); // Assuming logout function exists to handle token expiration
        } else {
          console.error('Error downloading PDF:', error);
        }
      });
    };

   



  //End Form validation
  const columns = [
    // { field: "id", headerName: "ID" },
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "middlename",
      headerName: "Midde Name",
      // type: "number",
      headerAlign: "left",
      align: "left",
      flex: 2,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      flex: 2,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      flex: 2,
    },
    {
      field: "phonenumber",
      headerName: "Phone Number", filter:true,
      flex: 1,
      flex: 2,
    },
    {
      field: "balance",
      headerName: "Balance",
      flex: 1,
    },
    {
      field: "LicenceId",
      headerName: "LicenceId",
      flex: 1,
      
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },

    {
      field: "accessLevel",
      headerName: "Detail",
      flex: 1,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            backgroundColor={
              colors.blueAccent[600]
          }          
          >
       
        <Button type="submit"   onClick={ () => {navigate(`/driver/${id}`)} }
        sx={{
          fontSize: '8px',
          padding: '6px 10px',
        }}
        >
          <AdminPanelSettingsOutlinedIcon />
        </Button>
        
          </Box>
        );
      },
    },
     ];

  return (
    <Box m="20px"       
    >
   
    <Box
      m="40px 0 0 0"
      height="75vh"
      backgroundColor={colors.grey[400]}
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[300],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    > 
       <div >
        <Box display="flex" justifyContent="end" mt="20px" hidden>
        {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
                      
         <Button  onClick={dispAllDriver} variant="contained" color="secondary"> 
					Total Driver :: {all}<NewItem/>
				 </Button>&nbsp;
         <Button  onClick={dispActiveDriver} variant="contained" color="secondary"> 
         Active Driver:{active}<Active/>

				 </Button>&nbsp;
         <Button  variant="contained" color="secondary" onClick={() => { handleDownloaddriver()}}> 
					download <Active/>
				 </Button>&nbsp;
         <Button  variant="contained" color="secondary"> 
         <img src="./assets/wallet.png" alt='logo' style={{marginLeft: 5}} width={80} height={80} /> {balanceA}
 
				 </Button>&nbsp;
         <Button variant="contained" color="secondary"> 
         Total Free Vehicle : {asignedVehic}<Vehicle/>
				 </Button>&nbsp;
         <Button  variant="contained" color="secondary"> 
         Total Vehicle Assigned {records}<Vehicle/>
				 </Button>&nbsp;
         <Button variant="contained" color="secondary"> 
         Total Orders:{orders} <Orders/>
				 </Button>&nbsp;
         <Button aria-describedby={open} onClick={handleOpen} variant="contained" color="secondary"> 
				<DriverIcon/>Register
				 </Button>&nbsp;
        </Box>

         </div> 


         <Modal
      open={open}
      onClose={handleClosereg}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style3}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Formik
            initialValues={{ firstname: "", middlename: "", lastname: "", phonenumber: "", licenceId: "", balance: "", transactionmethod: "", platenumber: "" }}
            validationSchema={registerSchema}
            onSubmit={onsubmit}
          >
            <form className='formContainer' onSubmit={formik.handleSubmit} encType='multipart/form-data'>
              <ListItem>
                <ListItemText primary="First Name" sx={{ width: '150px' }} />
                <TextField
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.firstname}
                  pattern="[A-Za-z]"
                  name="firstname"
                  {...textFieldStyle}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Middle Name" sx={{ width: '150px' }} />
                <TextField
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.middlename}
                  required
                  name="middlename"
                  {...textFieldStyle}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Last Name" sx={{ width: '150px' }} />
                <TextField
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.lastname}
                  name="lastname"
                  {...textFieldStyle}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Email" sx={{ width: '150px' }} />
                <TextField
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  {...textFieldStyle}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Phone Number" sx={{ width: '150px' }} />
                <TextField
                  type="text"
                  pattern="[1-9]*"
                  onChange={formik.handleChange}
                  value={formik.values.phonenumber}
                  required
                  inputProps={{ maxLength: 9 }}
                  helperText="Please Enter exactly nine digits, e.g., 0"
                  name="phonenumber"
                  {...textFieldStyle}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Licence Number" sx={{ width: '150px' }} />
                <TextField
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.licenceId}
                  name="licenceId"
                  {...textFieldStyle}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Location" sx={{ width: '150px' }} />
                <TextField
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                  name="location"
                  {...textFieldStyle}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Driver Photo" sx={{ width: '150px' }} />
                <TextField
                  type="file"
                  name="image"
                  {...textFieldStyle}
                  onChange={(e) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                      if (reader.readyState === 2) {
                        formik.setFieldValue('image', e.target.files[0]);
                        // setPreview(reader.result); // Assuming you have a setPreview function
                      }
                    };
                    reader.readAsDataURL(e.target.files[0]);
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Plate Number" sx={{ width: '150px' }} />
                <FormControl {...formControlStyle}>
                  <Select
                    name="platenumber"
                    onChange={formik.handleChange}
                    value={formik.values.platenumber}
                    size="small"
                    variant="filled"
                    required
                    {...textFieldStyle}
                  >
                    {platenumer.map(vehicle => (
                      <MenuItem key={vehicle.id} value={vehicle.id}>
                        {vehicle.platenumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button type="submit" color="secondary" variant="contained">
                  Create New Driver
                </Button>
                <Button onClick={handleClosereg} variant="contained" color="warning">
                  Close
                </Button>
              </Box>
            </form>
          </Formik>
        </Typography>
      </Box>
    </Modal>
     {controldisp==0?
        <DataGrid   rows={driver} columns={columns}  components={{ Toolbar: GridToolbar }} rowHeight={20}/>
       :
        <DataGrid   rows={listActive} columns={columns}  components={{ Toolbar: GridToolbar }} rowHeight={20}/>
}


    </Box>
  </Box>
);

};

export default Driver;
