import { useContext, useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, useTheme ,Typography} from "@mui/material";
import { Link } from "react-router-dom";
// import { TreeItem } from '@mui/x-tree-view/TreeItem';
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SMS from "@mui/icons-material/SmsRounded";
import Accounting from "@mui/icons-material/AccountBalanceOutlined";
import Usermanager from "@mui/icons-material/ManageAccounts";
import Agents from "@mui/icons-material/SupportAgentRounded";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MapIcon from "@mui/icons-material/MapRounded";
import TaxiIcon from "@mui/icons-material/LocalTaxi";
import PaymentIcon from "@mui/icons-material/PaymentsOutlined";
import ReportIcon from "@mui/icons-material/Report";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { AuthContext } from "../../context/AuthContext";
// import wlogo from '../../images/waliiflogo.jpg';
import React from "react";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const {currentUser} = useContext(AuthContext);

  const handleResize = () => {
    if (window.innerWidth < 720) {
        setIsCollapsed(true)
    } else {
        setIsCollapsed(false)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })
  

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={
          <>
            <img src="./assets/wlogo.png" alt='logo' style={{marginLeft: 5}} width={80} height={80} />
            <MenuOutlinedIcon />
            {/* <wlogo/> */}
          </>
          }
          style={{
            margin: "10px 10px 20px 10px",
            color: colors.grey[100],
          }}
        >
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "1%"}>
          {currentUser.accesslevel === 'Customer Support'||currentUser.accesslevel === 'Agent' ||currentUser.accesslevel === 'user' ||currentUser.accesslevel === 'Super Admin' || currentUser.username === 'admin.wtsc'|| currentUser.accessLevel==="Customer Support"?
 
            <Item
              title="Dashboard"
              to="/home"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />:null}
 {currentUser.accesslevel === 'admin'||currentUser.accesslevel === 'Customer Support'||currentUser.accesslevel === 'user' ||currentUser.accesslevel === 'Super Admin' || currentUser.username === 'admin.wtsc'|| currentUser.accessLevel==="Customer Support"?
            <Item
                 title="Rider"
              to="/riders"
              icon={<PeopleOutlinedIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            :null}
            {/* <TreeItem itemId="grid" label="Data Grid">
                  <TreeItem itemId="grid-community" label="@mui/x-data-grid" />
                  <TreeItem itemId="grid-pro" label="@mui/x-data-grid-pro" />
                  <TreeItem itemId="grid-premium" label="@mui/x-data-grid-premium" />
                </TreeItem> */}
                {currentUser.accesslevel === 'admin'||currentUser.accesslevel === 'Customer Support'||currentUser.accesslevel === 'Agent' ||currentUser.accesslevel === 'user' ||currentUser.accesslevel === 'Super Admin' || currentUser.username === 'admin.wtsc'|| currentUser.accessLevel==="Customer Support"?
 
            <Item
              title="Driver"
              to="/drivers"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            :null}
            <Item
              title="Order"
              to="/orders"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
                 {currentUser.accesslevel === 'Customer Support'||currentUser.accesslevel === 'Agent' ||currentUser.accesslevel === 'user' ||currentUser.accesslevel === 'admin'?
 
            <Item
              title="Manage Vehicles"
              to="/vehicles"
              icon={<TaxiIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            :null}

             {currentUser.accesslevel === 'admin' || currentUser.accesslevel==='HR'?
            <Item
              title="Manage User"
              to="/user"
              icon={<Usermanager />}
              selected={selected}
              setSelected={setSelected}
            />
          :null}
                       {currentUser.accesslevel === 'admin' || currentUser.accesslevel==='HR'?
            <Item
              title="Manage Agent"
              to="/agents"
              icon={<Usermanager />}
              selected={selected}
              setSelected={setSelected}
            />
          :null}
            {/* {currentUser.accesslevel === 'admin'||currentUser.accesslevel === 'Customer Support'||currentUser.accesslevel === 'user' ||currentUser.accesslevel === 'Super Admin' || currentUser.username === 'Bod.Bod1' || currentUser.accessLevel==="Customer Support"?
 
                 <Item
              title="Accounts"
              to="/accaunts"
              icon={<Accounting />}
              selected={selected}
              setSelected={setSelected}
            />
            :null} */}
            {currentUser.accesslevel === 'Super Admin' || currentUser.username === 'admin.wtsc' ||currentUser.accesslevel === 'admin' ?
            <Item
              title="Employee"
              to="/employee"
              icon={<Agents />}
              selected={selected}
              setSelected={setSelected}
            />
            : null }
            
            <Item
              title="Transactions"
              to="/transactions"
              icon={<PaymentIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Report"
              to="/report"
              icon={<ReportIcon />}
              selected={selected}
              setSelected={setSelected}
            />
           {/* <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {currentUser.accesslevel === 'admin' &&
              <Item
                title="System Settings"
                to="/SYSTEMSETTING"
                icon={<MapIcon  />}
                selected={selected}
                setSelected={setSelected}
              />
            }
              {/* :
              null
            } */}

            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography> */}
            {/* <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Item
              title="sms"
              to="/sms"
              icon={<SMS />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Panoramic View"
              to="/panoramicView"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Test Bed"
              to="/form"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
               {/* <Item
              // title="print"
              // to="/print"
              // icon={<MapOutlinedIcon />}
              // selected={selected}
              // setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
