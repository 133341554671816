import { Box, IconButton, useTheme, Button } from "@mui/material";
import React, { useContext } from "react";
import { tokens } from "../../theme";
import LogoutIcon from "@mui/icons-material/LogoutSharp";
import People from "@mui/icons-material/PeopleOutlineRounded";
import {useNavigate} from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";

const Topbar = () => {
  const theme = useTheme();
  
  const colors = tokens(theme.palette.mode);
 const navigate = useNavigate()
  const {logout} = useContext(AuthContext);;   
  const {currentUser} = useContext(AuthContext);
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
      </Box>
      <Box display="flex">
        <IconButton>
        </IconButton>
        <Button onClick={ () => {navigate(`/profile/${currentUser.id}`)} } color="error">
              <People/>
            </Button>
        <IconButton>
          {currentUser.username}
        </IconButton>
        <IconButton onClick={logout}>
         < LogoutIcon/>
         </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
