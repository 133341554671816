import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import LocalTaxiOutLinedIcon from "@mui/icons-material/LocalTaxiOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
// import Header from "../../components/Header";
import Tab from '@mui/material/Tab';
import Mony from'@mui/icons-material/SaveAsRounded';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EmailIcon from "@mui/icons-material/Email";
// import DatePicker from '@mui/material/DatePicker';
import List from '@mui/material/List';
import StatBox from "../../components/StatBox";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import {Link, useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Modal from "@mui/material/Modal"; 
import { GridPrintExportMenuItem } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { blue } from "@mui/material/colors";
import { Input ,Snackbar} from '@mui/material';
import { AuthContext } from "../../context/AuthContext";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; 
// import {MaterialReactTable,useMaterialReactTable,} from 'material-react-table'

function Alert(props) {
  // return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const style2 = { 
	position: "absolute", 
	top: "40%", 
	left: "50%",
  height:"600", 
  transform: "translate(-50%, -50%)", 
	width: 700, 
  borderColor: "#d50000", 
	p: 1, 
};

const style1 = {
  position: "absolute",
  py: 0,
  width: 700,
  maxWidth: 700,
  borderRadius: 1,
  border: '2px solid',
  borderColor: 'dark',
  backgroundColor: 'blue   ',
  top: "10%", 
	left: "20%",
  bottom: "5%",
};
const style3 = { 
	position: "absolute", 
	top: "50%", 
	left: "60%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 800, 
  borderColor: "#d50000", 
	p: 4, 
}; 
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 
}; 
const styles = {
  container: {
    height: '90%',
    width: '90%',
    alignItems: 'center',
  },
  auth: {
    display: 'flex',
    alignItems: 'center',
    margin: 10,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    // backgroundColor: '#ffffff'
  },
  h1: {
    fontSize: 30,
    color: '#008080',
  },
  formContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%',
    height: '100%',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#4ca9ee',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    backgroundColor:"BDBDBD",
    width: '60%',
    height: '70%',
    gap: 20,
  },
  logo: {
    width: '40%',
    height: '70%'
  },
  input: {
    padding: 10,
    width: '80%',
    border: 'none',
    borderRadius: 10,
  },
  button: {
    padding: 10,
    border: 'none',
    backgroundColor: '#008080',
    cursor: 'pointer',
    color: '#ffffff',
    width: '40%',
    // marginTop: 10,
    borderRadius: 10
  }
}



const detailstyle = { 
	position: "absolute", 
	top: "50%", 
	left: "50%", 
  
	transform: "translate(-50%, -50%)", 
	width: 500, 
	bgcolor: "background.paper", 
	border: "10px solid #7888", 
	boxShadow: 24, 
	p: 4, 
}; 

const Userlogdetail = () => {
  const { currentUser, url,logout } = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
const [driver, setDriver] = useState([]);
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const navigate = useNavigate()

// const url="https://walifride.com";
 //End Remove File
 let { id } = useParams();
const [singlelog, setsinglelog] = useState([]);


useEffect(() => {
  const fetchsetPre = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + `/api/log/fetch/${id}`, { username: currentUser.username }, config);
      setsinglelog(response.data);
          }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};

fetchsetPre();
}, [currentUser.username, currentUser.accessToken]);
// const [error1, setError]=useState("");
// const [notification, setNotification] = useState(null);
const columndoc = [
  // { field: "id", headerName: "ID" },
  {
    field: "id",
    headerName: "id",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "username",
    headerName: "username",
    flex: 2,
    cellClassName: "name-column--cell",
  },
  {
    field: "url",
    headerName: "url",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "ip",
    headerName: "ip",
    // type: "number",
    headerAlign: "left",
    align: "left",
    flex: 2,
  },
  {
    field: "types",
    headerName: "types",
    flex: 1,
  },
  {
    field: "auth",
    headerName: "auth",
    flex: 1,
  },
  {
    field: "action",
    headerName: "action",
    flex: 5,
    
  },
  {
    field: "others",
    headerName: "others",
    flex: 1,
  },
  {
    field: "description",
    headerName: "description",
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "createdAt",
    flex: 3,
  },
  {
    field: "updatedAt",
    headerName: "updatedAt",
    flex: 3,
  },
]



  return (
    
<Box m="20px"
    style2={{height: '85%'}}
    backgroundColor={colors.grey[400]}
  >

  <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[400]}
  
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
         
          sx={style2}
         width={500}
         // backgroundColor="#2979ff"
          >

  </Box>
<Button type="submit" color="secondary" onClick={ () => {navigate(`/register`)}}>Back</Button>
{/* <Button type="submit" color="secondary" onClick={handleOpen}>ADD File</Button> */}


      {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 
                    <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.blueAccent[700],
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              }}>
                 {/* <Button type="submit" onClick={ () => {navigate(`/driverdocument/${id}`)} }>document</Button> */}

                
                 {/* // wrapping container with theme & size */}
 
   
              
       <DataGrid rows={singlelog} columns={columndoc} components={{ Toolbar: GridToolbar }} ></DataGrid>

       <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
        

            
     
        
      
    </Box>

</Box>

</Box>
   
);

};

export default Userlogdetail;
