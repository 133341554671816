import React, { useEffect, useState ,useContext} from "react";
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { LoadScript, GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';

import CustomMarker from '../../images/car50.png';
import CustomMarkerActive from '../../images/active.png';
import CustomMarkerOnline from '../../images/online.png';

// import CustomMarkerActive from '../../images/active.png';
// const CustomMarkerActive = {
//   url: '../../images/active.png', // Replace with the path to your custom marker icon
//   scaledSize: new window.google.maps.Size(30, 30), // Set the size of the icon
// };

//import CustomMarker from "../assets/images/map-marker-svgrepo-com.svg";

const libraries = ['places']; // Optional for place name search

const PanoramicView = () => {
  const [locations, setLocations] = useState([]);
  const [pass, setPassenger] = useState([]);
  const [active, setActive] = useState([]);
  const [online, setOnline] = useState([]);
  const [waiting, setWaiting] = useState([]);

  const { currentUser,url ,logout} = useContext(AuthContext);
 
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url+'/api/orders/all',{username:currentUser.username},config); // actual URL endpoint that provides your location data in JSON format
        console.log(response.data.orders);
        setWaiting(response.data.waiting);
        setPassenger(response.data.waiting);
        setActive(response.data.active);
        setOnline(response.data.online);

      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
    };
    fetchLocations();
  }, []);
  const handleMarkerClick = (title, location) => {
    navigator.clipboard.writeText(title).then(
      () => {
        console.log('Title copied to clipboard:', title);
        if(location.status=='online'){
          alert("Driver phone:"+location.driverphone+", Vehicle Plate Number :"+location.platenumber+" Driver is Online")
        }
        else if(location.status=='waiting'){
        alert("Driver phone:"+location.driverphone+", Vehicle Plate Number :"+location.platenumber+"Rider Phone"+location.riderphone+" Driver and Customers are finding each other")
        }
        else if(location.status=='active'){
          alert("Driver phone:"+location.driverphone+", Vehicle Plate Number :"+location.platenumber+"Rider Phone"+location.riderphone+"Trip started from "+location.startlocation+"  To"+location.endlocation)
        }
      },
      (err) => {
        console.error('Could not copy title: ', err);
      }
    );
  };

  const mapContainerStyle = {
    width: '78vw',
    height: '45vw',
  };

  const center = {
    lat:9.0192, // Initial center location, Nekemte
    lng: 38.7525,
  };

  const zoom = 13;
    return (
    <div>
    <div  style={{textAlign:`center`, fontWeight:`bold`}}>
      <h1>PANORAMIC VIEW</h1>
    </div>
  <LoadScript
    googleMapsApiKey="AIzaSyBoxZiahvHjS32xojb5BusYcN2s6KLGBb8" // Your Google Maps API Key
    libraries={libraries}
  >
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={zoom}
      
       center={center}
    >

{waiting.map((location) => {
        const title = `WALIIF RIDE ${location.driverphone}`;
        return (
          <MarkerF
            key={location.id} // Use unique identifier for each location
            position={{ lat: location.driverlat, lng: location.driverlng }}
            options={{ icon: CustomMarker }}
            title={title}
            onClick={() => handleMarkerClick(title,location)}
          />
        );
      })}

      {/* {online.map((location) => (
        <MarkerF
          key={location.id} // Use unique identifier for each location
          position={{ lat:location.driverlat, lng:location.driverlng }}
          options={{ icon: CustomMarkerOnline }}
          title={`WALIIF RIDE ${location.driverphone}`}      
      
        />
      ))} */}


{online.map((location) => {
        const title = `WALIIF RIDE ${location.driverphone}`;
        return (
          <MarkerF
            key={location.id} // Use unique identifier for each location
            position={{ lat: location.driverlat, lng: location.driverlng }}
            options={{ icon: CustomMarkerOnline }}
            title={title}
            onClick={() => handleMarkerClick(title, location)}
          />
        );
      })}


     {active.map((location) => {
        const title = `WALIIF RIDE ${location.driverphone}`;
        return (
          <MarkerF
            key={location.id} // Use unique identifier for each location
            position={{ lat: location.driverlat, lng: location.driverlng }}
            options={{ icon: CustomMarkerActive }}
            title={title}
            onClick={() => handleMarkerClick(title,location)}
          />
        );
      })}
      {/* {active.map((location) => (
        <MarkerF
          key={location.id} // Use unique identifier for each location
          position={{ lat:location.driverlat, lng:location.driverlng }}
          options={{ icon: CustomMarkerActive }}
          title='WALIIF RIDE'
        />
      ))} */}
    </GoogleMap>
  </LoadScript>
        
  </div>
  
  );

};


export default PanoramicView;
