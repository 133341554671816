import React, { useState } from 'react';
import Modal from "@mui/material/Modal";
import { styled } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';
import { Button,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
// import PrintPage from './index668';

const PrintPage2 = styled('div')({
    '& @media print': {
      '@page': {
        margin: '0',
      },
      body: {
        marginLeft:"4cm",
        margin: '3.6cm',
      },
    },
  });
  

const PrintPage = () => {
    const [open, setOpen] = React.useState(false); 
	const handleOpen = () => setOpen(true);
  const handleClose= () => setOpen(false);
  const [items] = useState([
    { id: 1, name: 'Item 1', price: 10, quantity: 2 },
    { id: 2, name: 'Item 2', price: 20, quantity: 1 },
    { id: 3, name: 'Item 3', price: 15, quantity: 3 },
  ]);

  const calculateTotal = () => {
    return items.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const printInvoice = () => {
    // Logic to print the invoice
    // You can use window.print() or any other method to print the content
    window.print();
  };

  return (
    <div>
        {/* <Button onClick={open}>open modal</Button> */}
          {/* <Modal 
          // backgroundColor="seagreen "
          // sx={style1} 
            open={open} 
            onClose={handleClose} 
            // aria-labelledby="modal-modal-title"
            // aria-describedby="modal-modal-description"
          >  */}
        <Box> here is waliif ride invoice</Box>  
      <TableContainer window={5}component={Paper}>
      <PrintPage2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(item => (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>${item.price}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>${item.price * item.quantity}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={4} align="right">Total</TableCell>
              <TableCell>${calculateTotal()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        </PrintPage2>
      </TableContainer>
      
      <Button variant="contained" color="primary" onClick={printInvoice}>
        Print Invoice
      </Button>
      {/* </Modal> */}
      
    </div>
  );
};

export default PrintPage;
