import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import Danger from "@mui/icons-material/WarningAmberOutlined";

import Preview from "@mui/icons-material/Preview";
import {Moment} from 'moment'
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
// import Header from "../../components/Header";
import Tab from '@mui/material/Tab';
import Mony from'@mui/icons-material/SaveAsRounded';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EmailIcon from "@mui/icons-material/Email";
// import DatePicker from '@mui/material/DatePicker';
import List from '@mui/material/List';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import {Link, useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Modal from "@mui/material/Modal"; 
import { GridPrintExportMenuItem } from '@mui/x-data-grid';
import { useFormik ,Formik} from 'formik';
import { blue } from "@mui/material/colors";
import { Input ,Snackbar,Alert, TextareaAutosize} from '@mui/material';
import { AuthContext } from "../../context/AuthContext";
import Dialog from '@mui/material/Dialog';
import DialogActions, { dialogActionsClasses } from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import TablePagination from "@mui/material/TablePagination"; 

import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';


const style2 = { 
	position: "absolute", 
	top: "40%", 
	left: "50%",
  height:"600", 
  transform: "translate(-50%, -50%)", 
	width: 700, 
  borderColor: "#d50000", 
	p: 1, 
};





const SMS = () => {
  const { currentUser, url,logout } = useContext(AuthContext);
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
const [driver, setDriver] = useState([]);
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const [showAlert, setShowAlert] = useState(null);
const [captchaText, setCaptchaText] = useState(''); 
const [userInput, setUserInput] = useState(''); 
// const canvasRef = useRef(null); 
const navigate = useNavigate()
let { id } = useParams();


const [open, setOpen] = React.useState(false); 
const handleOpen = () => setOpen(true);
const handlecClose = () => setOpen(false);

const [opendel, setOpendel] = React.useState(false); 
const handleOpendel = () => setOpendel(true);
const handlecClosedel = () => setOpendel(false);

const [edit, setEdit] = React.useState(false); 
const editclose = () => setEdit(false);
const openEdit = () => setEdit(true);


const [open2, setOpen2] = React.useState(false);
const handleOpen2 = () => setOpen2(true);
const handlecClose2 = () => setOpen2(false);

  const handleClickOpen = () => {
    axios.post(url+`/api/vehiclesdocuments/confirm/${id}`, {username:currentUser.username})
  .then((res)=>{
    setOpen2(false)
  //  alert(res.data)
   setNotification({
    show: true,
    type: 'success',
    message: res.data 
  
});
setIsRedirecting(true);
const timer = setTimeout(() => {
   
   }, 3000);

return () => clearTimeout(timer);

  })
  .catch((err)=>console.log(err))
    // setOpen2(true);
  };

  const handleClose = () => {
    setOpen2(false);
  };



const [control, setControl]=React.useState(0)
const [check, setCheck]=React.useState(0)


const [state, setState] = React.useState({
  gilad: true,
  jason: false,
  antoine: true,
});

const handleChangeSwitch = (event) => {

  if(control===1){
  setControl(0)
  }else{
    setControl(1)

  }

  setState({
    ...state,
    [event.target.name]: event.target.checked,
  });
};

// Approval
const [status, setApproval] = useState({
  
  username:currentUser.username,
  status:"",
})

const [send, setSend] = useState('');
const [res, setRes] = useState('');
const [sub, setSubject] = useState('');
const [bod, setbody] = useState('');
const [urgency, seturgency] = useState('');
const [oth, setothers] = useState('');
const [idnum, setId] = useState('');


function handleEdit(id,sender,reciever,subject,body,urgency,others){
 
setRes(reciever)
setSend(sender)
setSubject(subject)
setbody(body)
seturgency(urgency)
setothers(others)
setId(id)
openEdit(true)
}

const [del, setDel]=React.useState('')
  function setDelete(id){
    setDel(id)
  setOpen(true)
  }
  
function handledelete(del,){
// alert(del)

const config = {
  headers: {
    'Authorization': `Bearer ${currentUser.accessToken}`
  }
};
axios.post(url+`/api/generalreport/sms/del/${del}`, {username:currentUser.username},config)
  .then((res)=>{
    setOpen(false)
    setNotification({
    show: true,
    type: 'error',
    message: res.data
});
setIsRedirecting(true);
     const timer = setTimeout(() => {
      window.location.reload();
    }, 3000);
        return () => clearTimeout(timer);
  })
  .catch((err)=>console.log(err))
}
const [sms, setSMS] = useState([]);
const [page, setPage] = useState(1);
const [pg, setpg] = React.useState(0); 
const [rpg, setrpg] = React.useState(5); 
const rowsPerPage = 10;
function handleChangePage(event, newpage) { 
  setpg(newpage); 
} 
useEffect(() => {
 // axios.get('http://localhost:3001/transactions').then((response) => {

 const config = {
  headers: {
    'Authorization': `Bearer ${currentUser.accessToken}`
  }
};

  axios.post(url+`/api/generalreport/sms/fetch/all`,{username:currentUser.username,rowsPerPage:rowsPerPage,page:page },config).then((response) => {
    
    setSMS(response.data);
    debugger;  

  }).catch(e => {
    if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
  });
},[page]);

const formics = useFormik({
  initialValues: {
    audence:"",
    reciever: '',
    subject: '',
    body: '',
    urgency: '',
    seen:"",
    others:"",
    username:currentUser.username
  },
  onSubmit: (values) => {
    // console.log(values);
    const formData = new FormData();
    for (let value in values) {
      formData.append(value, values[value]);
    }
 
    const config = {
      headers: {
        'Authorization': `Bearer ${currentUser.accessToken}`
      }
    };

    axios.post(url+'/api/generalreport/sms', values, config).then((res) => {
      // setdoc(doc.concat(res.data));
      setOpendel(false)
            setNotification({
              
        show: true,
        type: 'info',
        message: res.data
    });
    setIsRedirecting(true);
         const timer = setTimeout(() => {
          window.location.reload();
         }, 3000);
         return () => clearTimeout(timer);
    }).catch(error=>{
      console.log(error)
   });
  },
});



const [filterValue, setFilterValue] = useState('');

const handleFilterChange = (event) => {
  setFilterValue(event.target.value);
};

const filteredData = sms.filter(row => {
  // Example: filter by firstname containing filterValue
  return row.reciever.toLowerCase().includes(filterValue.toLowerCase());
});



function handleChangeRowsPerPage(event) { 
  setrpg(parseInt(event.target.value, 10)); 
  setpg(0); 
} 

  return (
    
<Box m="20px"
    style2={{height: '85%'}}
    backgroundColor={colors.grey[400]}
  >


<Typography sx={{ m: 1 }}>
<Button type="submit" color="error" variant='contained'sx={{ m: 1 }} onClick={handleOpendel} >Create SMS</Button>
{/* <Button type="submit" color="secondary" sx={{ m: 1 }}variant='contained' onClick={handleOpen}>Inbox</Button> */}
   <Button type="submit" color="info" variant='contained'sx={{ m: 1 }}>Draft</Button>
  
   <TextField
        label="Find By Reciever"
        
        sx={{ m: 1, margin: '20px', width: '200px',height: '0'}}
        value={filterValue}
        onChange={handleFilterChange}
        align="right"
       
      />
      </Typography>
  {/* <React.Fragment> */}
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={open2}
        onClose={handlecClose2}
      
      >
        <DialogTitle backgroundColor={colors.grey[400]}>Confirmation Dialog</DialogTitle>
        <DialogContent backgroundColor={colors.grey[400]}>
          <DialogContentText>
          I confirm that the vehicle documents listed below satisfy the requirements
           to provide service according to Waliif Transport Sc          </DialogContentText>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handlecClose2} color='error'variant="contained">Cancel</Button>
          <Button type="submit" onClick={handleClickOpen}color='secondary' variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
      {/* start Dialog to removed file */}
      <Dialog
        open={opendel}
        onClose={handlecClosedel}
      >
        <DialogTitle backgroundColor={colors.grey[400]}> Create New SMS </DialogTitle>
        <form className='formContainer' onSubmit={formics.handleSubmit} encType='multipart/form-data'>
       
        <DialogContent backgroundColor={colors.grey[400]}>
          <DialogContentText>
            <TextField name="audence" sx={{m:1}} select     onChange={formics.handleChange}
              value={formics.values.audence} style = {{width: 400}} label="Audiance Type">
            <MenuItem value="indvidual">Individual</MenuItem>
            <MenuItem value="Bulk drivers">Bulk for Drivers</MenuItem>
            <MenuItem value="Bulk Rider">Bulk Rider</MenuItem>
            <MenuItem value="Bulk Staff">Bulk for staff</MenuItem>
            </TextField>     
            <TextField name="reciever" sx={{m:1}}style = {{width: 400}}label="Reciever" defaultValue={res}
            onChange={formics.handleChange}
            value={formics.values.reciever}
            ></TextField>     
            <TextField name="subject" sx={{m:1}}style = {{width: 400}}label="Subject"
            onChange={formics.handleChange}
            value={formics.values.subject} defaultValue={send}
            ></TextField>     
            <TextField name="body" sx={{m:1}}style = {{width: 400}}label="Body"
             onChange={formics.handleChange}
             value={formics.values.body}></TextField>     
            <TextField name="others" sx={{m:1}}style = {{width: 400}} 
                         onChange={formics.handleChange}
                         value={formics.values.others}
            ></TextField>       
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlecClosedel} color='warning'variant="contained">Cancel</Button>
          <Button onClick={handlecClosedel} color='info'variant="contained">Save draft</Button>
          <Button type="submit" color='secondary' variant="contained">Send</Button>
         
        </DialogActions>
        </form>
      </Dialog>
  


   <Dialog
             open={edit}
             onClose={editclose}   
      >
        <DialogTitle backgroundColor={colors.grey[400]}>
        <FormControlLabel
         label={<Edit/>}
          control={
            
            <Switch checked={state.gilad} onChange={handleChangeSwitch} name="gilad" />
          }         

        />
         </DialogTitle>
        <form className='formContainer' onSubmit={formics.handleSubmit} encType='multipart/form-data'>
       
        <DialogContent backgroundColor={colors.grey[500]}>
          <DialogContentText>
          {control===1?        

            <TextField name="audence" sx={{m:1}} select onChange={formics.handleChange} 
            defaultValue={res} style = {{width: 400}} label="Audiance Type">
            <MenuItem value="indvidual">Individual</MenuItem>
            <MenuItem value="Bulk drivers">Bulk for Drivers</MenuItem>
            <MenuItem value="Bulk Rider">Bulk Rider</MenuItem>
            <MenuItem value="Bulk Staff">Bulk for staff</MenuItem>
            </TextField> :
            <TextField  disabled name="audence" sx={{m:1}} select onChange={formics.handleChange} 
            defaultValue={res} style = {{width: 400}} label="Audiance Type">
            <MenuItem value="indvidual">Individual</MenuItem>
            <MenuItem value="Bulk drivers">Bulk for Drivers</MenuItem>
            <MenuItem value="Bulk Rider">Bulk Rider</MenuItem>
            <MenuItem value="Bulk Staff">Bulk for staff</MenuItem>
            </TextField> }
            {control===1?        
            <TextField name="reciever" sx={{m:1}}style = {{width: 400}}label="Reciever"
            onChange={formics.handleChange}
            defaultValue={res}
            ></TextField>:         
            <TextField  disabled name="reciever" sx={{m:1}}style = {{width: 400}}label="Reciever"
            onChange={formics.handleChange}
            defaultValue={res}
            ></TextField> }  
            {control===1?     
            <TextField name="subject" sx={{m:1}}style = {{width: 400}}label="Subject" 
            onChange={formics.handleChange}
            defaultValue={sub}
            ></TextField>  :
            <TextField  disabled name="subject" sx={{m:1}}style = {{width: 400}}label="Subject" 
            onChange={formics.handleChange}
            defaultValue={sub}
            ></TextField>}
            {control===1?     
            <TextField name="body" sx={{m:1}}style = {{width: 400}}label="Body"
             onChange={formics.handleChange}
             defaultValue={bod}></TextField> :
             <TextField  disabled name="body" sx={{m:1}}style = {{width: 400}}label="Body"
             onChange={formics.handleChange}
             defaultValue={bod}></TextField>}
             {control===1?    
            <TextField name="others" sx={{m:1}}style = {{width: 400}}  
                         onChange={formics.handleChange}
                         defaultValue={oth}
            ></TextField> : 
            <TextField  disabled name="others" sx={{m:1}}style = {{width: 400}}  
                         onChange={formics.handleChange}
                         defaultValue={oth}
            ></TextField>}     
          </DialogContentText>
        </DialogContent>
        <DialogActions>

       
        {control===1?
        <Button   onClick={editclose} color='error'variant="contained">discard</Button>
:null}
{control===1?
          <Button  onClick={handlecClosedel} color='info'variant="contained">update and Save draft</Button>
        
        :null} {control===1?
         <Button type="submit" color='secondary' variant="contained">Update and Send</Button>
        :null}
        </DialogActions>
        </form>
      </Dialog>
  
  

      {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}  
     

<Dialog
      	open={open} 
				onClose={handlecClose} 
	
			>    
      {/* <DialogTitle> <Danger allign="center" color='error'/></DialogTitle> */}
      <DialogContent>
      <Box alignItems="center" justifyContent="center">
        <Danger sx={{width:30, height:30}} color='error'  />
      </Box>

      <DialogContentText sx={{m:4}}> Are You Sure do you want to delete?</DialogContentText>
        <Button type="submit" color="secondary" variant="contained" onClick={handledelete(`${del}`)}sx={{m:1}}>  Yes</Button>
        <Button color="error" onClick={handlecClose}variant="contained"> Close </Button> &nbsp;&nbsp;&nbsp;
      
       
        </DialogContent>
      </Dialog>


      {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 
                              
                 {/* <Button type="submit" onClick={ () => {navigate(`/driverdocument/${id}`)} }>document</Button> */}

                
    <TableContainer component={Paper}> 
                <Table sx={{ minWidth: 650 }}  
                    aria-label="simple table"> 
                       {/* <TableHead sx={{ backgroundColor: 'blue' }}> Change 'blue' to 'blue[400]' or your preferred color */}
        <TableRow sx={{ backgroundColor: '#53a9e6' }}>
          <TableCell align="right">Reciever</TableCell>
          <TableCell align="right">subject</TableCell>
          <TableCell align="center">Body</TableCell>
          <TableCell align="right">seen</TableCell>
          <TableCell align="right">action</TableCell>

        </TableRow>
      {/* </TableHead> */}
                    {/* <TableBody>  */}
                    
                        {filteredData.slice(pg * rpg, pg * rpg + rpg).map((row) => ( 
                            <TableRow 
                                key={row.id} 
                                // sx={{ "&:last-child td, 
                                // &:last-child th": { border: 0 } }} 
                                sx={{ backgroundColor: '#ffff',color: theme.palette.common.black,fontWeight : 'bold' }}
                                
                                style={{ height:10 }}
                            >   <TableCell align="right">{row.reciever} 
                                </TableCell> 
                                <TableCell align="right">{row.subject} 
                                </TableCell> 
                                <TableCell align="center">{row.body} 
                                </TableCell> 
                                <TableCell align="right">{row.seen} 
                                </TableCell> 
                                <TableCell align="right">
                                <Button color='info' onClick={ () => {handleEdit(`${row.id}`,`${row.sender}`,`${row.reciever}`,`${row.subject}`,`${row.body}`,`${row.urgency}`,`${row.others}`)} }>  <Preview/></Button>
                                  <Button color='error' onClick={ () => {handledelete(`${row.id}`)} }>  <Delete/></Button>
                                </TableCell> 
                            </TableRow> 
                        ))} 
                </Table> 
            </TableContainer> 
    <TablePagination 
                rowsPerPageOptions={[ 5]} 
                component="div"
                count={filteredData.length} 
                rowsPerPage={rpg} 
                page={pg} 
                onPageChange={handleChangePage} 
                onRowsPerPageChange={handleChangeRowsPerPage} 
            /> 
    
       {/* <DataGrid rows={vehicledoc} columns={columndoc} components
       ={{ Toolbar: GridToolbar }} ></DataGrid> */}

</Box>
   
);

};

export default SMS;
