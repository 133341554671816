import { Box, FormControl,InputLabel, ListItem, Typography, useTheme, Alert, Snackbar } from "@mui/material";
import { DataGrid,GridToolbar ,ColDef, GridCloseIcon, GridAddIcon} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
// import Header from "../../components/Header";
import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import useMediaQuery from "@mui/material/useMediaQuery";
import { blue } from "@mui/material/colors";
import  ColorPicker  from 'mui-color-picker';
import SketchPicker   from "react-color"
// import { ColorPicker } from "material-ui-color";

import { toast } from "react-toastify";
import { useFormik ,Formik, Field} from 'formik';
// import Select from '@mui/joy/Select';
// import Option from '@mui/joy/Option';
import { DialogActions, Button, Modal,  DialogContent, DialogTitle, MenuItem,TextField, Select,} from "@mui/material";

const style = { 
	position: "absolute", 
	top: "50%", 
	left: "50%", 
  bgcolor: blue,
	transform: "translate(-50%, -50%)", 
	width: 900, 
	bgcolor: "background.paper", 
	border: "2px solid #1111", 
	boxShadow: 24, 
	p: 4, 
}; 
const style3 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'primary.main',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const textFieldStyle = {
  width: 300,
  height: 50,
  m: 1,
  '& .MuiInputBase-root': {
    height: 50,
    bgcolor:"info.main"
  },
  '& .MuiInputBase-input': {
    padding: '0 14px',
  },
  '&:hover .MuiInputBase-root': {
    borderColor: 'primary.main',
  },
  '&:focus-within .MuiInputBase-root': {
    borderColor: 'primary.main',
  },
};

const formControlStyle = {
  width: 300,
  height: 50,
  m: 1,
  '& .MuiInputBase-root': {
    height: 50,
    bgcolor:"info.main",
  },
  '& .MuiInputBase-input': {
    padding: '0 14px',
  },
  '&:hover .MuiInputBase-root': {
    borderColor: 'primary.main',
  },
  '&:focus-within .MuiInputBase-root': {
    borderColor: 'primary.main',
  },
};


const Vehicle = () => {
 const [color, setColor] =React.useState('#fff');
  const [preview, setPreview] = useState('');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false); 
	const handleOpen = () => setOpen(true); 
	const handleClose = () => setOpen(false); 
  const handleClosedetail = () => setOpendetail(false);
  const [opendetail, setOpendetail] = React.useState(false); 
   //manage detail modal 
   const handleOpendetail = () => setOpendetail(true); 
   const { currentUser,url,logout} = useContext(AuthContext);
   const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [seat, setSeat] = useState(0);

  const handleonchangecolor=(color)=>{
    color=setColor(color);
  }
  const [vehicleColorList, setVehicleColorList] = useState([]);
  const [vehicle, setVehicle] = useState([]);

  useEffect(() => {
    const fetchvehicle = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${currentUser.accessToken}`
                }
            };
            const response = await axios.post(url + '/api/vehicles/all', { username: currentUser.username }, config);
            setVehicle(response.data.vehicle);
            setVehicleColorList(response.data.colorList); 
          }catch (e) {
            if (e.toJSON().status === 401) {
                console.log('No valid token or session expired');
                logout();
            } else {
                console.error(e);
            }
        }
    };

    fetchvehicle();
}, [currentUser.username, currentUser.accessToken]);


const [tar, setTar]=useState() 


useEffect(() => {
  const gettariff = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + '/api/tariffs/gettariffvehic_class', { username: currentUser.username }, config);
      setTar(response.data.tar);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};
gettariff();
}, [currentUser.username, currentUser.accessToken]);

const navigate = useNavigate()

  const isNonMobile = useMediaQuery("(min-width:600px)");
  const formik = useFormik({
    initialValues: {
      platenumber: "",
      code: "",
      vclass:"",
      driverId: "",
      vehicletype: "",
      brand: "",
      numbreofseats: "",
      vehiclemodel: "",
      vehiclecolor: "",
      vehicleimage: "",
      chargeperkm: 1,
      status: "Idle",
      username:currentUser.username,
   
    }, onSubmit: (values) => {
      const formData = new FormData();
      for (let value in values) {
        formData.append(value, values[value]);
        // alert(values[value]); 
// alert(color)

      }

      const registerdriver = async () => {
        try {
          const config = {
              headers: {
                  'Authorization': `Bearer ${currentUser.accessToken}`
              }
          };
   axios.post(url+"/api/vehicles", formData, config).then((res) => {
  if(res.data=="Success"){
    setNotification({
        show: true,
        type: 'success',
        message: "The Vehicle has seccussfully registered, "
    });
  }else if(res.data=="failed"){
    setNotification({
      show: true,
      type: 'error',
      message: "The Operation is failed, Try Later, "
  });
  
  }else if(res.data=='Vehicle exists.'){
    setNotification({
      show: true,
      // position:top,
      type: 'info',
      message: "The Vahicle you are trying to register is already registered !"
  });
  
  }else{
  setNotification({
    show: true,
    // position:top,
    type: 'error',
    message: res.data
});

}
  setIsRedirecting(true);
  const timer = setTimeout(() => {
    window.location.reload();
    }, 2000);

  return () => clearTimeout(timer);
});
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
 }
 registerdriver();

},
});


// const notifyrr = () => {
// }
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "platenumber",
      headerName: "Plate No",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "vehicletype",
      headerName: "Vehic_Type",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "driverId",
      headerName: "DriverId",
      // type: "number",
      headerAlign: "left",
      align: "left",
    },
    
    {
      field: "numbreofseats",
      headerName: "TariffCat",
      flex: 1,
    },
    {
      field: "vehiclemodel",
      headerName: "Vehic_Model",
      flex: 1,
    },
    {
      field: "vehiclecolor",
      headerName: "Vehicle_color", filter:true,
      flex: 1,
    },
    
    {
    field: "approvedby",
    headerName: "AprovedBy",
   flex:1,
  
  },
  {
  field: "status",
  headerName: "status",
 flex:1,
},
{
  field: "updatedAt",
  headerName: "updatedDate",
 flex:1,
},
{
  field: "accessLevel",
  headerName: "Detail",
  flex: 1,
  renderCell: ({ row: { id } }) => {
    return (
      <Box
        backgroundColor={
            colors.blueAccent[600]
        }
      >
        <Button type="submit" onClick={ () => {navigate(`/vehicle/${id}`)} }
                sx={{
                  fontSize: '8px',
                  padding: '6px 10px',
                }}
        >
          <AdminPanelSettingsOutlinedIcon />
          <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
          </Typography>
        </Button>
        
      </Box>
    );
  },
},
  ];

  return (
    <Box m="20px">
      <Box
       m="40px 0 0 0"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: colors.greenAccent[200],
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: colors.blueAccent[700],
          borderBottom: "none",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: colors.blueAccent[700],
        },
        "& .MuiCheckbox-root": {
          color: `${colors.greenAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
          color: `${colors.grey[100]} !important`,
        },
      }}
    > <div>
       <Button aria-describedby={open} onClick={handleOpen} variant="contained" color="secondary"> 
					<GridAddIcon></GridAddIcon>
				</Button> 
        <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style3}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <Formik
            initialValues={{ code: "", platenumber: "", vclass: "", numbreofseats: "", vehicletype: "", vehiclemodel: "", vehiclecolor: "", vehicleimage: "" }}
            onSubmit={formik.handleSubmit}
          >
            <form className='formContainer' onSubmit={formik.handleSubmit} encType='multipart/form-data'>
              <TextField
                select
                name="code"
                onChange={formik.handleChange}
                value={formik.values.code}
                label="Plate No code"
                sx={textFieldStyle}
                size="small"
              >
                <MenuItem value="3A-AA">3A-AA</MenuItem>
                <MenuItem value="3B-AA">3B-AA</MenuItem>
                <MenuItem value="3C-AA">3C-AA</MenuItem>
                <MenuItem value="3D-AA">3D-AA</MenuItem>
                <MenuItem value="3A-OR">3A-OR</MenuItem>
                <MenuItem value="3B-OR">3B-OR</MenuItem>
                <MenuItem value="3C-OR">3C-OR</MenuItem>
                <MenuItem value="2A-AA">2A-AA</MenuItem>
                <MenuItem value="2B-AA">2B-AA</MenuItem>
                <MenuItem value="2C-AA">2C-AA</MenuItem>
                <MenuItem value="2A-OR">2A-OR</MenuItem>
                <MenuItem value="2B-OR">2B-OR</MenuItem>
              </TextField>
              <TextField
                name="platenumber"
                onChange={formik.handleChange}
                value={formik.values.platenumber}
                label="Plate Number, Please enter exactly five digits"
                sx={textFieldStyle}
                pattern="[1-9]*"
                inputProps={{
                  maxLength: 5,
                  minWidth: 5,
                }}
                size="small"
              />
              <TextField
              label="Vehicle Class"
                name="vclass"
                select
                onChange={formik.handleChange}
                value={formik.values.vclass}
                sx={textFieldStyle}
                size="small"
              >
                {tar ? (
                  tar.map(vehicle => (
                    <MenuItem key={vehicle.id} value={vehicle.id}>
                      {vehicle.vehicle_class}{vehicle.seatnumber}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No data available</MenuItem>
                )}
              </TextField>
              <TextField sx={formControlStyle} size="small"
                  name="numbreofseats"
                  label="Vehicle Seat"
                  
                  onChange={formik.handleChange}
                  value={formik.values.numbreofseats}
                  variant="filled"
                >
              </TextField>
              <FormControl sx={formControlStyle}>
                <InputLabel id="demo-select-small-label">Vehicle Type</InputLabel>
                <Select
                  name="vehicletype"
                  label="Vehicle Class"
                  onChange={formik.handleChange}
                  value={formik.values.vehicletype}
                  variant="filled"
                  required
                  size="small"
                >
                  <MenuItem value="Corolla Toyota">Corolla Toyota</MenuItem>
                  <MenuItem value="Vitz Toyota">Vitz Toyota</MenuItem>
                  <MenuItem value="Hitch Pack">Hitch Pack</MenuItem>
                  <MenuItem value="Suzuki Desire">Suzuki Desire</MenuItem>
                  <MenuItem value="Atos suzuki">Atos Suzuki</MenuItem>
                  <MenuItem value="others">Others</MenuItem>
                  <MenuItem value="Hitch Pack">Hitch Pack</MenuItem>
                  <MenuItem value="Mistbush">Mistbush</MenuItem>
                  <MenuItem value="Haundai">Haundai</MenuItem>
                  <MenuItem value="Dx">Dx</MenuItem>
                  <MenuItem value="Rech">Rech</MenuItem>
                  <MenuItem value="Wolsewagen">Wolsewagen</MenuItem>
                </Select>
              </FormControl>
              <TextField
                type="number"
                label="Vehicle Model"
                required
                name="vehiclemodel"
                onChange={formik.handleChange}
                sx={textFieldStyle}
                value={formik.values.vehiclemodel}
              />
              <TextField
                name="vehiclecolor"
                value={formik.values.vehiclecolor}
                select
                onChange={formik.handleChange}
                sx={{ ...textFieldStyle, bgcolor: vehicleColorList.find(v => v.colorName === formik.values.vehiclecolor)?.colorName }}
              >
                {vehicleColorList.map(vehicle => (
                  <MenuItem key={vehicle.id} value={vehicle.colorName} sx={{ bgcolor: vehicle.colorName, fontSize: '18px' }}>
                    {vehicle.colorName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                type="file"
                label="Image"
                required
                name="vehicleimage"
                sx={textFieldStyle}
                onChange={(e) => {
                  let reader = new FileReader();
                  reader.onload = () => {
                    if (reader.readyState === 2) {
                      formik.setFieldValue('vehicleimage', e.target.files[0]);
                      setPreview(reader.result);
                    }
                  };
                  reader.readAsDataURL(e.target.files[0]);
                }}
              />
             
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained" startIcon={<GridAddIcon />}>
                  Add Vehicle
                </Button>
                <Button type="button" color="error" onClick={handleClose} variant="contained" startIcon={<GridCloseIcon />} sx={{ ml: 2 }}>
                  Close
                </Button>
              </Box>
              {/* {preview && <img src={preview} width="70" alt="Preview" style={{ marginTop: '10px' }} />} */}
            </form>
          </Formik>
        </Typography>
      </Box>
    </Modal>    {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )}
    </div>
       <DataGrid   rows={vehicle} columns={columns}  components={{ Toolbar: GridToolbar }}   rowHeight={20}/>  
   </Box>
  </Box>
  );
};
export default Vehicle;