//import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import React, { useMemo } from "react";
import { useTable } from "react-table";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import LocalTaxiOutLinedIcon from "@mui/icons-material/LocalTaxiOutlined";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddFile from "@mui/icons-material/UploadFile";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
// import Header from "../../components/Header";
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import EmailIcon from "@mui/icons-material/Email";
// import DatePicker from '@mui/material/DatePicker';
import List from '@mui/material/List';
import StatBox from "../../components/StatBox";
import { AuthContext } from '../../context/AuthContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import {Link, useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import AccordionDetails from "@mui/material/AccordionDetails";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import Modal from "@mui/material/Modal"; 
import { GridPrintExportMenuItem } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { blue, lightBlue } from "@mui/material/colors";
import { Input } from '@mui/material';
const style1 = {
  py: 6,
  width: '100%',
  maxWidth: 360,
  borderRadius: 2,
  //border: '2px solid',
  borderColor: "#2962ff",
  backgroundColor: '#343433',
};
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 

  
}; 
const style2 = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 500, 
  borderColor: "#d50000", 
	p: 4, 
};
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx2={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
 function StaffTransaction() {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [value, setValue] = React.useState(0);
//   const { currentUser,url} = useContext(AuthContext);

//   const navigate = useNavigate()
//   let { id } = useParams();
//   const [vehicleReg, setVehicleReg] = useState([]);
//   const [preview, setPreview] = useState('');
//   // const url="http://localhost:3001";
//   // const url="https://walifride.com";
//   // const url = "https://128.140.76.56:3443";

//   //const url="http://116.203.134.102:3001";
//   // const url="http://128.140.76.56:3001";
//   //Fetch Vehicle File
//   const [acc, setPersonalBalance] = useState([]);
//   const [doc, setData] = useState([]);
//    const [hist, setHist] = useState([]);
//    useEffect(() => {
//     axios.post(url+`/api/emptransac/others/dispPersonalTransactiondetail/${id}`, {username:currentUser.username, role: currentUser.accesslevel, objct: 'emptransactions', actn: 'read'}).then((response) => {
//       setHist(response.data);
//        debugger;  
 
//      }).catch(e => {
//       console.log('error:', e);
//     });
//   });
      
//   // Start To handle E-money Request for Staff{}
//   const [opent, setOpenstaff] = React.useState(false);
//   const handleOpenstaff = () => setOpenstaff(true);

//   const handleClosestaff = () => setOpenstaff(false);
//   //End To handle E-money Request for staff
// const isNonMobile = useMediaQuery("(min-width:600px)"); 
//   const [updatevalue, setValueUpdate] = useState({
//      username: currentUser.username,
//   });
//   const [user, setUser] = useState();
//   useEffect(() => {
//     setUser(localStorage.getItem('user'));
//        axios.get(url+"settings", {
//        params: {
//         çurrentuser: user
//       }
//     })

//       .then(result => setData(result.data));
//     }, []); 

// const [open, setOpen] = React.useState(false); 
// const handleOpen = () => setOpen(true); 
// const [addfileOpen, setaddfile] = React.useState(false); 
// const handleClose = () => setOpen(false); 
// const handleopenaddfile = () => setaddfile(true); 
// const handleCloseaddfile = () => setaddfile(false); 
// const handleChange = (event, newValue) => {
//  setValue(newValue);
//   };

// //Account fetching
// // const [acc, setPersonalBalance] = useState([]);
// useEffect(() => {
//   // axios.post(url+`/emptransac/dispPersonalBalance/${id}`, {role: currentUser.accesslevel, objct: 'emptransac', actn: 'read'}).then((response) => {
//     axios.post(url+`/api/emptransac/others/dispPersonalBalance/${id}`, {role: currentUser.accesslevel, objct: 'emptransactions', actn: 'read'}).then((response) => {
    
//   setPersonalBalance(response.data);
//    });
//  }, []);
// //End Account fetching


// const [values, setValues] = useState({
//   transactionmethod: "",
//   username:currentUser.username,
//   amaunt:"",
// })
// function handleTransactions(e){
//   e.preventDefault()

//   axios.post(url+'/api/emptransac/others/regstaff', values)
//   .then((res)=>{
//       setOpenstaff(false);
//     alert(res.data)  
//     })
//   .catch((err)=>console.log(err))
// }

// //Start userEffect of Personal Transaction

// const [recieved, setRecieved] = useState({
  
//   username:currentUser.username,
//   recievedstatus:"",
// })
// function handleRecieved(id){
// // alert(id)
// // alert(recieved.recievedstatus)
// axios.post(url+`/api/emptransac/recieved/${id}`, recieved)

//   .then((res)=>{
//    alert(res.data)
//   })
//   .catch((err)=>console.log(err))
// }


// //End use Effect of Transaction

// const columns = [
//   // { field: "id", headerName: "ID" },
  
//   {
//     field: "accountnumber",
//     headerName: "Acccount Number ",
//     flex: 1,
//     cellClassName: "name-column--cell",
//   },
//   {
//     field: "accountType",
//     headerName: "Account Type",
//     // type: "number",
//     headerAlign: "left",
//     align: "left",
//   },
//   {
//     field: "balance",
//     headerName: "Balance",
//     flex: 1,
//   },
  
//   {
//     field: "bonus",
//     headerName: "bonus", filter:true,
//     flex: 1,
//   },
//   {
//     field: "status",
//     headerName: "status", filter:true,
//     flex: 1,
//   },
  
//   {
//     field: "createdAt",
//     headerName: "createdAt",
//     flex: 1,
//   },
//   {
//     field: "updatedAt",
//     headerName: "updatedAt",
//     flex: 1,
//   },
// ];

// const column = [
//   // { field: "id", headerName: "ID" },
//   {
//     field: "username",
//     headerName: "username",
//     flex: 1,
//     cellClassName: "name-column--cell",
//   },
//   {
//     field: "previoustotal",
//     headerName: "PrivevousB ",
//     flex: 1,
//     cellClassName: "name-column--cell",
//   },
//   {
//     field: "transactionmethod",
//     headerName: "Tx Method",
//     // type: "number",
//     headerAlign: "left",
//     align: "left",
//   },
//   {
//     field: "acctype",
//     headerName: "acctype",
//     flex: 1,
//   },
//   {
//     field: "creditaccount",
//     headerName: "creditaccount",
//     flex: 1,
//   },
//   {
//     field: "debitaccount",
//     headerName: "debitaccount",
//     flex: 1,
//   },
//   {
//     field: "status",
//     headerName: "status", filter:true,
//     flex: 1,
//   },
//   {
//     field: "amaunt",
//     headerName: "amaunt",
//     flex: 1,
//   },
//   {
//     field: "newtotal",
//     headerName: "total",
//     flex: 1,
//   },
//   {
//     field: "createdAt",
//     headerName: "createdAt",
//     flex: 1,
//   },
//   {
//     field: "action",
//     headerName: "Action",
//     flex: 1,
//     renderCell: ({ row: {status, username } }) => {
//       return (
//         status=="RO" && username==currentUser.username?
//         <Typography
//           width="60%"
//           m="0 auto"
//           p="5px"
//           display="flex"
//           justifyContent="center"
//           backgroundColor={
//               colors.greenAccent[600]
//           }
//           borderRadius="4px"
//         >
//          < TextField select  name="recievedstatus"
//          onChange={(e)=> setRecieved({...recieved, recievedstatus: e.target.value})}    
//          >
//           <MenuItem  value="Recieved">Recieved</MenuItem>
//           <MenuItem  value="Cancelled">Cancelled</MenuItem>
//          </TextField>
//         </Typography>
//         :null
//       );
//     },
//   },
//   {
//     field: "Edit",
//     headerName: "Detail",
//     flex: 1,
//     renderCell: ({ row: {id , status, username} }) => {
//       return (
//         status=="RO" ?
//         <Box
//           width="60%"
//           m="0 auto"
//           p="5px"
//           display="flex"
//           justifyContent="center"
//           backgroundColor={
//               colors.greenAccent[600]
//           }
//           borderRadius="4px"
//         >
//           <Button type="submit"  onClick={ () => {handleRecieved(`${id}`)} }> apply
//           </Button>
//         </Box>:null
//       );
//     },
//   },
//    ];
// //End for table transaction 
//   function handlefetch(){
//   }
//   return (
//     <Box m="20px"
//     style={{height: '85%'}}
//   >
//   <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
//       <AppBar position="static">
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           indicatorColor="secondary"
// 		      backgroundColor="primary"
//           textColor="inherit"
//           variant="fullWidth"
//           aria-label="full"
// 		      display="flex"
//         >
      
//       <Tab label="Staff Transaction" {...a11yProps(0)} />
//       <Tab label="Agent Transaction" {...a11yProps(2)} />
//         </Tabs>
//       </AppBar>
      
//       <TabPanel value={value} index={0} dir={theme.direction}>
		      
//             <Box m="20px">
//              <Box
//             m="40px 0 0 0"
//             height="75vh"
//             sx={{
//               "& .MuiDataGrid-root": {
//                 border: "none",
//               },
//               "& .MuiDataGrid-cell": {
//                 borderBottom: "none",
//               },
//               "& .name-column--cell": {
//                 color: colors.greenAccent[300],
//               },
//               "& .MuiDataGrid-columnHeaders": {
//                 backgroundColor: colors.blueAccent[700],
//                 borderBottom: "none",
//               },
//               "& .MuiDataGrid-virtualScroller": {
//                 backgroundColor: colors.primary[400],
//               },
//               "& .MuiDataGrid-footerContainer": {
//                 borderTop: "none",
//                 backgroundColor: colors.blueAccent[700],
//               },
//               "& .MuiCheckbox-root": {
//                 color: `${colors.greenAccent[200]} !important`,
//               },
//               "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//                 color: `${colors.grey[100]} !important`,
//               },
//             }}
//           >
//         {/* Start Request E-money for staff */}


     


//         {/* End Request E-Money for Staff */}
//               <DataGrid style={{height: '90%', width: '100%'}} rows={hist} columns={column}  components={{ Toolbar: GridToolbar }} />
              
//             {/* <DataGrid style={{height: '80%', width: '100%'}} rows={trans} columns={columns}  components={{ Toolbar: GridToolbar }} /> */}
//           </Box>  </Box>
              
//               </TabPanel>


        

// {/* start agent request */}

// <TabPanel value={value} index={2} dir={theme.direction}>
		      
//           <Box m="20px">
//            <Box
//           m="40px 0 0 0"
//           height="75vh"
//           sx={{
//             "& .MuiDataGrid-root": {
//               border: "none",
//             },
//             "& .MuiDataGrid-cell": {
//               borderBottom: "none",
//             },
//             "& .name-column--cell": {
//               color: colors.greenAccent[300],
//             },
//             "& .MuiDataGrid-columnHeaders": {
//               backgroundColor: colors.blueAccent[700],
//               borderBottom: "none",
//             },
//             "& .MuiDataGrid-virtualScroller": {
//               backgroundColor: colors.primary[400],
//             },
//             "& .MuiDataGrid-footerContainer": {
//               borderTop: "none",
//               backgroundColor: colors.blueAccent[700],
//             },
//             "& .MuiCheckbox-root": {
//               color: `${colors.greenAccent[200]} !important`,
//             },
//             "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//               color: `${colors.grey[100]} !important`,
//             },
//           }}
//         >
//       {/* Start Request E-money for staff */}



//       <Button type="submit" color="secondary" variant="contained"  onClick={handleOpenstaff}>     
//          Request Agent E-Money
//             </Button> &nbsp;&nbsp;
//             <Button type="submit" color="secondary" variant="contained"  onClick={handleOpenstaff}>     
//          withdrow bonus
//             </Button> &nbsp;&nbsp;
//             <Button type="submit" color="secondary" variant="contained"  onClick={handleOpenstaff}>     
//          Transfer bonus
//             </Button> &nbsp;&nbsp;
//     <Modal 
    
//       open={opent} 
//       onClose={handleClosestaff} 
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
      
//     > 
//       <Box sx={style2  } 
//                 //gridColumn="span 6"
//                 backgroundColor={colors.grey[400]}
//                // display="flex"
//                 gridRow="span 2" 
//                 // marginLeft={20}
//                 marginRight={50}
//                 width={10}
             
//       > 
//         <Typography id="modal-modal-title"
//           variant="h6" component="h2"> 

//       Charge balance
//         </Typography> 
//         <Typography id="modal-modal-description"
//           sx={{ mt: 2 }}> 
          
//           <form onSubmit={handleTransactions}>
//           <Box
//             display="grid"
//             gap="30px"
//             gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//             sx={{
//               "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//             }}
//           >               <TextField
//               fullWidth
//               variant="filled"
//               type="number"
//               label="amaunt"
//               style = {{width: 200}} 
//               onChange={(e)=> setValues({...values, amaunt: e.target.value})}
//               required
//               columns={2}
//               name="amaunt"
//               ></TextField>
//              </Box>
//             <Box display="flex" justifyContent="end" mt="20px">
//             <Button type="submit" color="secondary" variant="contained">
//             Apply
//             </Button> &nbsp;&nbsp;
//             <Button variant="secondary" onClick={handleClosestaff} position="right">
//           Close
//         </Button>
//           </Box>
             
//           </form>
          
//         </Typography> 
       
//       </Box> 
      
//     </Modal>
   


//       {/* End Request E-Money for Staff */}
//             <DataGrid style={{height: '80%', width: '100%'}} rows={hist} columns={column}  components={{ Toolbar: GridToolbar }} />
            
//           {/* <DataGrid style={{height: '80%', width: '100%'}} rows={trans} columns={columns}  components={{ Toolbar: GridToolbar }} /> */}
//         </Box>  </Box>
            
//             </TabPanel>

//       {/* </SwipeableViews> */}
//   </Box>
//     </Box>
//   );

};
export default StaffTransaction;
