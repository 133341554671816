import React from "react";
import Layout from "./../components/Layout/Layout";
import { Box, Typography } from "@mui/material";

const About = () => {
  return (
    <Layout>
      <Box
        sx={{
          my: 15,
          textAlign: "center",
          p: 2,
          "& h4": {
            fontWeight: "bold",
            my: 2,
            fontSize: "2rem",
          },
          "& p": {
            textAlign: "justify",
          },
          "@media (max-width:600px)": {
            mt: 0,
            "& h4 ": {
              fontSize: "1.5rem",
            },
          },
        }}
      >
        <Typography variant="h4">Welcome To Waliif Ride</Typography>
        <p>Our Fleet</p>
        <p>
        The vast majority of our cars are new and prestige models. 
        Your comfort and safety are paramount to us. We want you to start or end your holiday or
         business trip on a high service.
        </p>
        <br />
        <p>Our Drivers</p>
        <p>
        Our drivers are fully licensed, highly experienced and polite and courteous at all times.They know exactly where they are going and you can be certain your pickup or drop-off is in capable hands. 
        </p>
      </Box>
    </Layout>
  );
};

export default About;
