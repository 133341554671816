//import * as React from 'react';
import PropTypes from 'prop-types';
import { tokens } from "../../theme";
import { useTheme } from '@mui/material/styles';
import React, { useMemo } from "react";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { AuthContext } from '../../context/AuthContext';
import Button from '@mui/material/Button';
import {useNavigate,useParams} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Snackbar, Alert} from '@mui/material';

import { DataGrid,GridToolbar ,ColDef} from "@mui/x-data-grid";
import  { useEffect, useState, useContext } from "react";
import axios from 'axios';
import Modal from "@mui/material/Modal"; 
import { useFormik } from 'formik';
const style = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 600, 
  borderColor: "#d50000", 
	p: 4, 

  
}; 
const style2 = { 
	position: "absolute", 
	top: "50%", 
	left: "72%",
  height:"100", 
  transform: "translate(-50%, -50%)", 
	width: 500, 
  borderColor: "#d50000", 
	p: 4, 
};
function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx2={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
 function Profile() {
  const theme = useTheme();
  const { currentUser,url,logout} = useContext(AuthContext);
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate()
  let { id } = useParams();
  const [vehicleReg, setVehicleReg] = useState([]);
  const [preview, setPreview] = useState('');
  const [notification, setNotification] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [acc, setPersonalBalance] = useState([]);
  const [doc, setData] = useState([]);
   const [hist, setHist] = useState([]);

  useEffect(() => {
    const fetchsetHist = async () => {
      try {
        const config = {
            headers: {
                'Authorization': `Bearer ${currentUser.accessToken}`
            }
        };
        const response = await axios.post(url + `/api/emptransac/dispPersonalTransactiondetail/${id}`, { username: currentUser.username }, config);
        setHist(response.data);
      }catch (e) {
        if (e.toJSON().status === 401) {
            console.log('No valid token or session expired');
            logout();
        } else {
            console.error(e);
        }
    }
  };
  
  
  fetchsetHist();
  }, [currentUser.username, currentUser.accessToken]);
  

  const [debited,setDebited] = useState([]);
 useEffect(() => {
  const fetchsetDebited = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + `/api/emptransac/dispPersonaldebited/${id}`, { username: currentUser.username }, config);
      setDebited(response.data);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};


fetchsetDebited();
}, [currentUser.username, currentUser.accessToken]);


  // Start To handle E-money Request for Staff
  const [opent, setOpenstaff] = React.useState(false);
  const handleOpenstaff = () => setOpenstaff(true);

  const handleClosestaff = () => setOpenstaff(false);
  //End To handle E-money Request for staff


const isNonMobile = useMediaQuery("(min-width:600px)");
  
const [loading, setLoading] = React.useState(false);

  // const [preview, setPreview] = useState('');
  const [chargebal, setBal] = useState([]);
     const formics = useFormik({
      initialValues: {
        Reciept: "",
        amaunt: "",
        username:currentUser.username
      },
      onSubmit: (values) => {
        console.log(values);
        const formData = new FormData();
        for (let value in values) {
          formData.append(value, values[value]);
        }
        axios.post(url+'/api/emptransac/reg', formData).then((res) => {
          setBal(chargebal.concat(res.data));
          setOpen(false)
         // handleopenaddfile=setaddfile(false);
          if(res.data=="ok"){
             alert("Request Success")
          }else if(res.data=="nouser"){
            alert("Access Denied")
          }
          else if(res.data=="noaccout"){
            alert("No account associated with your username")
          }else if(res.data=="exist"){
            alert("You have already requested, Please, wait petiantly!")
            
          } else if (res.data=="nofile"){
            alert("No Reciept attached, Please try again!")
          }
          else{
            alert("You have a truble, Please contact Admin")
          }
          handleOpen(false)
        });
      },
    });
  




const [open, setOpen] = React.useState(false); 
const handleOpen = () => setOpen(true); 
const [addfileOpen, setaddfile] = React.useState(false); 
const handleClose = () => setOpen(false); 
const handleopenaddfile = () => setaddfile(true); 
const handleCloseaddfile = () => setaddfile(false); 
const handleChange = (event, newValue) => {
 setValue(newValue);
  };


//Start Reset Pasword
const [psw, setpsw] = useState({
  
  username:currentUser.username,
  oldpas:"",
  newpas:"",
  connewpas:"",
})
function handlepswchange(e){
  e.preventDefault()
  // /alert(psw.connewpas)
  setLoading(true);
  // Simulate a delay
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };

axios.put(url+`/auth/pass/${id}`, psw,config)
  .then((res)=>{
    setOpen(false)
    setNotification({
      show: true,
      type: 'info',
      message: res.data
    
  });
  setTimeout(() => {
    setLoading(false);
  });
        // console.log(res);
        setIsRedirecting(true);
        const timer = setTimeout(() => {
          window.location.reload();

           }, 2000);
      
        return () => clearTimeout(timer);
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}}

//End Password Password

const [collect, setCollect] = useState({
  
  username:currentUser.username
})
function handleapproval(id){
//  handleCloseregdetail(true)
// alert(id)
// alert(collect.username)
axios.post(url+`/api/emptransac/collect/${id}`, collect)

  .then((res)=>{
   alert(res.data)
  })
  .catch((err)=>console.log(err))
}

//Account fetching
 useEffect(() => {
  const fetchsetPersonalBalance = async () => {
    try {
      const config = {
          headers: {
              'Authorization': `Bearer ${currentUser.accessToken}`
          }
      };
      const response = await axios.post(url + `/api/emptransac/dispPersonalBalance/${id}`, { username: currentUser.username }, config);
      setPersonalBalance(response.data);
    }catch (e) {
      if (e.toJSON().status === 401) {
          console.log('No valid token or session expired');
          logout();
      } else {
          console.error(e);
      }
  }
};


fetchsetPersonalBalance();
}, [currentUser.username, currentUser.accessToken]);

//End Account fetching


const [values, setValues] = useState({
  transactionmethod: "",
  username:currentUser.username,
  amaunt:"" 
})
function handleTransactions(e){
  e.preventDefault()
  try {
    const config = {
        headers: {
            'Authorization': `Bearer ${currentUser.accessToken}`
        }
    };
  axios.post(url+'/api/emptransac/regstaff', values,config)
  .then((res)=>{
      setOpenstaff(false);
      if(res.data=="ok"){
        // alert("")
        setNotification({
          show: true,
          type: 'success',
          message: "Request Success" 
        
      });
        // alert(res.data)
      }else if(res.data=="nouser"){
      //  alert("Access Denied")
       setNotification({
        show: true,
        type: 'error',
        message: res.data 
      
    });
     }
     else if(res.data=="noaccout"){
      //  alert("No account associated with your username")
       setNotification({
        show: true,
        type: 'info',
        message: "No account associated with your username" 
      
    });
     }else if(res.data=="exist"){
      //  alert("")
       setNotification({
        show: true,
        type: 'info',
        message: "You have already requested, Please, wait petiantly!" 
      
    });
       
     } else if (res.data=="nofile"){
      //  alert("")
       setNotification({
        show: true,
        type: 'info',
        message: "No Reciept attached, Please try again!" 
      
    });
     }
     else{
      //  alert("You have a truble, Please contact Admin")
       setNotification({
        show: true,
        type: 'error',
        message: res.data
      
    });
     }
          // console.log(res);
          setIsRedirecting(true);
          const timer = setTimeout(() => {
            window.location.reload();

             }, 2000);
        
          return () => clearTimeout(timer);
  
  })
}catch (e) {
  if (e.toJSON().status === 401) {
      console.log('No valid token or session expired');
      logout();
  } else {
      console.error(e);
  }
}
}

//Start of Personal Transaction


const debitedcol = [
  // { field: "id", headerName: "ID" },
  {
    field: "id",
    headerName: "id",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "driverid",
    headerName: "Driver ID",
    flex: 1,
    cellClassName: "name-column--cell",
    renderCell: ({ row: { driverid } }) => {
      return (
        
          
          <a href="/drivers">{driverid}</a>  
      );
    },
  },
  {
    field: "previoustotal",
    headerName: "previoustotal",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "transactionmethod",
    headerName: "T-method",
    flex: 1,
  },
  {
    field: "transactionhandler",
    headerName: "transactionhandler",
    flex: 1,
  },
  {
    field: "debitaccount",
    headerName: "debitaccount", filter:true,
    flex: 1,
  },
  {
    field: "creditaccount",
    headerName: "creditaccount",
    flex: 1,
  },
  {
    field: "amount",
    headerName: "amount",
    flex: 1,
  },
  {
    field: "vat",
    headerName: "vat",
    flex: 1,
  },
  {
    field: "newtotal",
    headerName: "newtotal",
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "createdAt",
    flex: 1,
  },

  {
    
    headerName: "Print",
    flex: 1,
    renderCell: ({ row: { id } }) => {
      return (
        <Box
          width="60%"
          m="0 auto"
          p="5px"
          display="flex"
          justifyContent="center"
          backgroundColor={
              colors.blueAccent[600]
          }
          borderRadius="4px"
        >
          <Button type="submit"               
          sx={{
                fontSize: '8px',
                padding: '6px 10px',
              }}>
            <PrintOutlinedIcon />

          </Button>
        </Box>
      );
    },
  },
   ];


//End use Effect of Transaction

const balance = [
  // { field: "id", headerName: "ID" },
  
  {
    field: "accountnumber",
    headerName: "Acccount Number ",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "accountType",
    headerName: "Account Type",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "balance",
    headerName: "Balance",
    flex: 1,
  },
  {
    field: "transfered",
    headerName: "Transfered",
    flex: 1,
  },
  
  {
    field: "bonus",
    headerName: "bonus", filter:true,
    flex: 1,
  },
  {
    field: "status",
    headerName: "status", filter:true,
    flex: 1,
  },
  
  {
    field: "createdAt",
    headerName: "createdAt",
    flex: 1,
  },
  {
    field: "updatedAt",
    headerName: "updatedAt",
    flex: 1,
  },
];

const column = [
  // { field: "id", headerName: "ID" },
  {
    field: "username",
    headerName: "ID",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "previoustotal",
    headerName: "PrivevousB ",
    flex: 1,
    cellClassName: "name-column--cell",
  },
  {
    field: "transactionmethod",
    headerName: "Tx Method",
    // type: "number",
    headerAlign: "left",
    align: "left",
  },
  {
    field: "acctype",
    headerName: "acctype",
    flex: 1,
  },
  {
    field: "creditaccount",
    headerName: "creditaccount",
    flex: 1,
  },
  {
    field: "debitaccount",
    headerName: "debitaccount",
    flex: 1,
  },
  {
    field: "status",
    headerName: "status", filter:true,
    flex: 1,
    
  },
  {
    field: "amaunt",
    headerName: "amaunt",
    flex: 1,
  },
  {
    field: "newtotal",
    headerName: "total",
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "createdAt",
    flex: 1,

  },
  {
    field: "updatedAt",
    headerName: "updatedAt",
    flex: 1,
  },
  {
    field: "Edit",
    headerName: "Detail",
    flex: 1,
    renderCell: ({ row: {status ,id,username} }) => {
      return (
        status == 'approved' && username==currentUser.username?
                <Typography
          width="60%"
          m="0 auto"
          p="5px"
        
          backgroundColor={
              colors.greenAccent[600]
          }
          borderRadius="4px"
        >
                     {/* <Button type="submit" onClick={ () => {handleapproval(`${id}`)} }>
                 eeApply
                </Button> */}
        </Typography>
        :""
      );
    },
  },
   ];
//End for table transaction 
  function handlefetch(){
  }
  return (
    <Box m="20px"
    style={{height: '85%'}}
  >
  <Box sx={{ bgcolor: 'background.paper', width: '100%', height: '100%' }} p="10px">
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
		      backgroundColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full"
		      display="flex"
        >
      
      <Tab label="Transaction" {...a11yProps(0)} />
      <Tab label="Debited " {...a11yProps(1)} />
      <Tab label="Balance" {...a11yProps(2)} />
      {/* <Tab label="Agent Transaction" {...a11yProps(4)} /> */}
      <Tab label="Personal Profile" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
        <TabPanel value={value} index={3} dir={theme.direction}>
		      <div>  
            <Box m="20px">
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="140px"
              gap="20px"
              backgroundColor={colors.grey[400]}
            >
            <Box
                gridColumn="span 8"
                // backgroundColor={colors.primary[400]}
                backgroundColor={colors.grey[400]}
                display="flex"
                gridRow="span 3" 
              >

{notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 

         <List  label="Drivers Profile">
            <Box gridColumn="span 6">
              <ListItem>
                <ListItemText primary="First Name" display="grid" gridColumn="span 2"/>
                    <ListItemText  name="firstname"size="small"primary={currentUser.firstname} sx={{m:1}}/>
                </ListItem>
              <ListItem>
              <ListItemText primary="Middle Name" />
                <ListItemText size="small"name="middlename"primary={currentUser.middlename} sx={{m:1}}/>
              </ListItem>
              <ListItem>
              <ListItemText primary="Last Name" />
                <ListItemText size="small"name="vehicletype"primary={currentUser.lastname} sx={{m:1}}/>
              </ListItem>
              <ListItem>
              <ListItemText primary="E-mail"  sx={{m:1}}/>
                <ListItemText size="small"primary={currentUser.email} sx={{m:1}} />
              </ListItem>
              <ListItem>
              <ListItemText primary="Access Level" />
               <ListItemText name="accesslevel"size="small"primary={currentUser.accesslevel} />
              </ListItem>
              
              <ListItem>
              <ListItemText primary="Status" />
              <ListItemText size="small" name="status" primary={currentUser.status}/>
              </ListItem>               
            </Box>
          </List>
        </Box>
        <Box
          gridColumn="span 4"
          // backgroundColor={colors.primary[400]}
          backgroundColor={colors.grey[400]}
          display="flex"
          gridRow="span 3" 
        >
         <List>
      <Box gridColumn={4}>
      <ListItem>
      {/* <Button aria-describedby={addfileOpen} onClick={handleopenaddfile}color="info" variant="contained" sx={{m:1}}>
        Add File</Button> */}
       <Button aria-describedby={open} onClick={handleOpen} sx={{m:1}}variant="contained" color="info"> 
					<Edit/>Edit
				</Button>
        <Button  variant="contained" color='warning'> 
					<Delete/>
				</Button>
      </ListItem>
      {/* <img src={`http://localhost:3001`+veh.vehicleimage} width={295} height={365} alt="placeholder"/>  */}
      </Box>
      </List>
</Box>

</Box>
  <Modal 
				open={addfileOpen} 
				onClose={handleCloseaddfile} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"> 
        <Box
          //gridColumn="span 6"
          backgroundColor={colors.grey[400]}
          display="flex"
          gridRow="span 3" 
          // marginLeft={20}
          marginRight={50}
          width={20}
          sx={style}
         // backgroundColor="#2979ff"
          >
       
          <Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 

    </Typography>
   </Box>
 </Modal>

<Modal 
				open={open} 
				onClose={handleClose} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
        headerAlign="modal-"
			> 
			<Box
        backgroundColor={colors.grey[400]}
        display="flex"
        gridRow="span 3"
        marginRight={50}
        width={20}
        sx={style} 
          >
        		<Typography id="modal-modal-title" 
						Password Change
            > 
					</Typography> 
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}
            > 
            {/* <form onSubmit={handleSubmit}> */}
            {/* <form className='formContainer' onSubmit={formik.handleSubmit} encType='multipart/form-data'> */}
                      <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                      >
                      <TextField
                          fullWidth
                          variant="filled"
                          type="password"
                          label="Old Password"
                          size="small"
                          // defaultValue={veh.platenumber}
                        //  onChange={(e)=> setValues({...values, platenumber: e.target.value})}
                        onChange={(e)=> setpsw({...psw, oldpas: e.target.value})}
               
                          // value={values.oldpsw}
                          //onChange={formik.handleChange}
                          name="oldpas"
                          required
                          sx={{ gridColumn: "span 1" }}
                        />
                        <TextField 
                          fullWidth
                          variant="filled"
                          type="password"
                          label="New Passwoed"
                          required
                          name="newpas"
                          onChange={(e)=> setpsw({...psw, newpas: e.target.value})}
               
                          // defaultValue={veh.vehiclemodel}
                          sx={{ gridColumn: "span 1" }}
                          >
                          </TextField>
                          <TextField
                          label="Confirm Password"
                          fullWidth
                          variant="filled"
                          type="password"
                          name="connewpas"
                          required
                          onChange={(e)=> setpsw({...psw, connewpas: e.target.value})}
                        
               ///  value={formik.values.nwps2}
                          
                          // defaultValue={veh.vehiclecolor}
                          
                          sx={{gridColumn: "span 1"}}
                          />
                        </Box>
                          
                        <Box display="flex" justifyContent="end" mt="20px">
                        <Button type="submit" color="secondary" onClick={handlepswchange}
                        disabled={loading}
                        // onClick={handleClick}
                        endIcon={loading && <CircularProgress size={24} />}
                        variant="contained">
                          
                          {loading ? 'Loading...' : 'Update'}

                        </Button> &nbsp;&nbsp;&nbsp;
                        <Button type="submit" color="error" onClick={handleClose}variant="contained">
                           Close
                        </Button> &nbsp;&nbsp;&nbsp;
                     </Box>
                    {/* </form> */}
                  </Typography> 
                </Box> 
	           	</Modal> 
            </Box>
        </div>  
      </TabPanel>

      <TabPanel value={value} index={0} dir={theme.direction}>
		      
            <Box m="20px">
             <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
        {/* Start Request E-money for staff */}

        {notification && notification.show && (
                        <Snackbar
                            open={notification.show}
                            autoHideDuration={3000} // Hide the alert after 3 seconds
                            onClose={() =>
                                setNotification({ ...notification, show: false })
                            }
                        >
                            <Alert
                                severity={notification.type}
                                onClose={() =>
                                    setNotification({ ...notification, show: false })
                                }
                            >
                                {notification.message}
                            </Alert>
                        </Snackbar>
                    )} 

        <Button type="submit" color="secondary" variant="contained"  onClick={handleOpenstaff}>     
           Request Staff E-Money
              </Button> &nbsp;&nbsp;
              <Button type="submit" color="secondary" variant="contained" >     
              Update pendig request
              </Button> &nbsp;&nbsp;
              <Button type="submit" color="secondary" variant="contained" >     
           withdraw-Bonus
              </Button> &nbsp;&nbsp;
              <Button type="submit" color="secondary" variant="contained" >     
           Transfer bonus
              </Button> &nbsp;&nbsp;
			<Modal 
      
				open={opent} 
				onClose={handleClosestaff} 
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
        
			> 
				<Box sx={style2  } 
                  //gridColumn="span 6"
                  backgroundColor={colors.grey[400]}
                 // display="flex"
                  gridRow="span 2" 
                  // marginLeft={20}
                  marginRight={50}
                  width={10}
               
        > 
					<Typography id="modal-modal-title"
						variant="h6" component="h2"> 

				  Charge balance
					</Typography> 
					<Typography id="modal-modal-description"
						sx={{ mt: 2 }}> 
						
            <form onSubmit={handleTransactions}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
             >   
             <TextField
                fullWidth
                variant="filled"
                type="number"
                label="amaunt"
                style = {{width: 200}} 
                onChange={(e)=> setValues({...values, amaunt: e.target.value})}
                required
                columns={2}
                name="amaunt"
                ></TextField>
                </Box>
               <Box display="flex" justifyContent="end" mt="20px">
               <Button type="submit" color="secondary" variant="contained">
                Apply
               </Button> &nbsp;&nbsp;
               <Button variant="secondary" onClick={handleClosestaff} position="right">
               Close
               </Button>
               </Box>
             </form>
            
					</Typography> 
         
				</Box> 
        
			</Modal>
     


        {/* End Request E-Money for Staff */}
              <DataGrid style={{height: '80%', width: '100%'}} rows={hist} columns={column}  components={{ Toolbar: GridToolbar }} rowHeight={20}/>
              
            {/* <DataGrid style={{height: '80%', width: '100%'}} rows={trans} columns={columns}  components={{ Toolbar: GridToolbar }} /> */}
          </Box>  </Box>
              
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction} >
          <Box m="20px"
          backgroundColor={colors.grey[400]}
          // backgroundColor={colors.grey[400]}
          >
           <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >

              <DataGrid style={{height: '95%', width: '100%'}} rows={debited} columns={debitedcol}  components={{ Toolbar: GridToolbar }}rowHeight={20} />
             </Box>
             </Box>
              </TabPanel>



          <TabPanel value={value} index={2} dir={theme.direction}>
		      
          <Box m="20px"
          backgroundColor={colors.grey[400]}
          >
           <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
              <DataGrid style={{height: '40%', width: '100%'}} rows={acc} columns={balance}  components={{ Toolbar: GridToolbar }}rowHeight={20} />
             </Box>
             </Box>
              </TabPanel>
{/* start agent request */}
<TabPanel value={value} index={4} dir={theme.direction}>
          <Box m="20px">
           <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
      {/* Start Request E-money for staff */}
      <Button type="submit" color="secondary" variant="contained"  onClick={handleOpenstaff}>     
         Request Agent E-Money
            </Button> &nbsp;&nbsp;
            <Button type="submit" color="secondary" variant="contained"  onClick={handleOpenstaff}>     
         withdrow bonus
            </Button> &nbsp;&nbsp;
            <Button type="submit" color="secondary" variant="contained"  onClick={handleOpenstaff}>     
         Transfer bonus
            </Button> &nbsp;&nbsp;
    <Modal 
      open={opent} 
      onClose={handleClosestaff} 
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    > 
      <Box sx={style2  } 
                //gridColumn="span 6"
                backgroundColor={colors.grey[400]}
               // display="flex"
                gridRow="span 2" 
                // marginLeft={20}
                marginRight={50}
                width={10}
      > 
        <Typography id="modal-modal-title"
          variant="h6" component="h2">
      Charge balance
        </Typography> 
        <Typography id="modal-modal-description"
          sx={{ mt: 2 }}> 
          
          <form className='formContainer' onSubmit={formics.handleSubmit} encType='multipart/form-data'>
       <ListItem>
         <ListItemText  primary="Upload Reciept" />
         <TextField
                fullWidth
                variant="filled"
                type="file"
                label="Photo"
               required
                name="Reciept"
                sx={{ gridColumn: "span 1" }}
                onChange={(e) => {
                  let reader = new FileReader();
                  reader.onload = () => {
                    if (reader.readyState === 2) {
                     formics.setFieldValue('Reciept', e.target.files[0])
                      //setValueUpdate('vehicleimage', e.target.files[0])
                      setPreview(reader.result)
                    }
                  }
                  reader.readAsDataURL(e.target.files[0]);
                }}
                ></TextField>   
        </ListItem>
        <ListItem>
         <ListItemText primary="Amaunt" />
         <TextField size="small" name="amaunt" type="number" 
            onChange={formics.handleChange}
            value={formics.values.amaunt}/>
            <TextField size="small" name="username" type="text" disabled 
            onChange={formics.handleChange}
            value={formics.values.username}/>
        </ListItem>
        <ListItem>
         <ListItemText primary="Remarks" />
         <TextField size="small" name="remark" 
            onChange={formics.handleChange}
            value={formics.values.remark}/>
        </ListItem>
        <Button type="submit" color="secondary" variant="contained">  Apply</Button>
        <Button color="error" onClick={handleClosestaff}variant="contained"> Close </Button> &nbsp;&nbsp;&nbsp;
      </form>
    
        </Typography> 
       
      </Box> 
      
    </Modal>
   


      {/* End Request E-Money for Staff */}
            <DataGrid style={{height: '80%', width: '100%'}} rows={hist} columns={column}  components={{ Toolbar: GridToolbar }} />
            
          {/* <DataGrid style={{height: '80%', width: '100%'}} rows={trans} columns={columns}  components={{ Toolbar: GridToolbar }} /> */}
        </Box>  </Box>
            
            </TabPanel>

      {/* </SwipeableViews> */}
  </Box>
    </Box>
  );

};
export default Profile;
